import { PriceBookInput } from 'utils/generated';
import * as yup from 'yup';

const PriceBookSchema: yup.SchemaOf<Omit<PriceBookInput, 'id'>> = yup
    .object({
        name: yup.string().required('A pricebook name is required'),
    })
    .required();

export default PriceBookSchema;
