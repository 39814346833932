import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { light } from '@sumup/design-tokens';

interface ContentProps {
    narrow?: boolean;
    hasNoSideBar?: boolean;
}

const contentBaseStyles = ({ narrow, hasNoSideBar }: ContentProps) => {
    const maxWidth = () => {
        if (hasNoSideBar) return '650px';
        if (narrow) return '450px';
        return '900px';
    };

    return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 1rem;
        max-width: ${maxWidth()};
        padding-right: ${hasNoSideBar ? 'auto' : '8px'};
        padding-left: ${hasNoSideBar ? 'auto' : '8px'};
        ${light.mq.untilKilo} {
            padding-top: 20px;
            padding-bottom: 0.5rem;
            padding-inline: 16px;
        }
    `;
};

const Content = styled.div<ContentProps>(contentBaseStyles);

export default Content;

Content.defaultProps = {
    hasNoSideBar: false,
};
