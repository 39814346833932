import React, { useRef } from 'react';
import { useClickOutside } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

interface Props {
    isOpen: boolean;
    popoverComponent: React.ReactNode;
    toggleComponent: React.ReactNode;
    onOutsideClick: () => void;
}

interface PopoverContainerProps {
    isOpen: boolean;
}

const Wrapper = styled('div')({
    position: 'relative',
});

const PopoverContainer = styled.div`
    position: absolute;
    left: 0;
    top: 39px;
    z-index: 9999;
    overflow: hidden;
    pointer-events: ${(props: PopoverContainerProps) => (props.isOpen ? 'auto' : 'none')};
    visibility: ${(props: PopoverContainerProps) => (props.isOpen ? 'visible' : 'hidden')};
    opacity: ${(props: PopoverContainerProps) => (props.isOpen ? '1' : '0')};
    height: ${(props: PopoverContainerProps) => (props.isOpen ? 'auto' : '0px')};
    width: ${(props: PopoverContainerProps) => (props.isOpen ? 'auto' : '0px')};
`;

function Popover({ isOpen = false, toggleComponent, popoverComponent, onOutsideClick }: Props) {
    const dateTimeEl = useRef(null);
    useClickOutside(dateTimeEl, onOutsideClick);

    return (
        <Wrapper ref={dateTimeEl}>
            <PopoverContainer isOpen={isOpen}>{popoverComponent}</PopoverContainer>
            {toggleComponent}
        </Wrapper>
    );
}

export default Popover;
