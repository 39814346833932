import React from 'react';
import { Body, Headline } from '@sumup/circuit-ui';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import SimpleGrid from 'components/layout/SimpleGrid';
import styled from '@emotion/styled';
import { css, CSSObject } from '@emotion/react';
import ImageCardLarge from 'components/image-card/ImageCardLarge';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/layout/PageHeader';

const ImageCardTitle = styled.div`
    text-align: center;
    margin: 0px 20px;
`;

type TextProps = {
    textDecoration: CSSObject['textDecoration'];
    color: CSSObject['color'];
};

const textStyles = ({ color, textDecoration }: TextProps) => css`
    text-decoration: ${textDecoration};
    color: ${color};
`;

const Text = styled.div(textStyles);

function OrderQRCodes() {
    const navigate = useNavigate();
    return (
        <ContentPane>
            <Content style={{ gap: '6px' }}>
                <PageHeader title="Order QR codes" />
                <Body style={{ margin: '0px 0px 40px' }}>
                    We currently offer two different types of QR codes for your tables - dome stickers or acrylic
                    blocks. Choose whichever fits your venue best and we ship it to you for free within 2-3 business
                    days.
                </Body>
                <SimpleGrid
                    style={{ alignSelf: 'center', minWidth: '368px', maxWidth: '900px' }}
                    columnTemplate="3fr 3fr"
                    gap="20px"
                >
                    <ImageCardLarge
                        buttonLabel="Order now"
                        image="/Sticker.png"
                        onClick={() =>
                            navigate('../confirm-delivery-information', { state: { qrType: 'VinylStickers' } })
                        }
                        title={
                            <ImageCardTitle>
                                <Headline noMargin size="three" as="h1">
                                    Dome stickers
                                </Headline>
                            </ImageCardTitle>
                        }
                        description={
                            <>
                                <Text color="black" textDecoration="line-through">
                                    £0.50 each
                                </Text>
                                <Text color="green" textDecoration="bold">
                                    Free
                                </Text>
                                <Text color="black" textDecoration="none">
                                    Perfect for small tables.
                                </Text>
                            </>
                        }
                        center
                    />
                    <ImageCardLarge
                        buttonLabel="Order now"
                        image="/Stand.png"
                        onClick={() => navigate('../confirm-delivery-information', { state: { qrType: 'Stands' } })}
                        title={
                            <ImageCardTitle>
                                <Headline noMargin size="three" as="h3">
                                    Acrylic blocks
                                </Headline>
                            </ImageCardTitle>
                        }
                        description={
                            <>
                                <Text color="black" textDecoration="line-through">
                                    £1.00 each
                                </Text>
                                <Text color="green" textDecoration="bold">
                                    Free
                                </Text>
                                <Text color="black" textDecoration="none">
                                    Stackable and easy to clean.
                                </Text>
                            </>
                        }
                        center
                    />
                </SimpleGrid>
            </Content>
        </ContentPane>
    );
}

export default OrderQRCodes;
