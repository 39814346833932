import React from 'react';
import { Card, Body, Headline, IconButton, useCollapsible } from '@sumup/circuit-ui';
import { ChevronUp, ChevronDown } from '@sumup/icons';
import styled from '@emotion/styled';

interface Props {
    title?: string;
    children: React.ReactNode;
    initialOpen?: boolean;
}

const StyledCard = styled(Card)`
    margin-bottom: 24px;
`;

const ToggleButton = styled(IconButton)`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
`;

function CloseableCard({ title, initialOpen, children }: Props) {
    const { getButtonProps, getContentProps, isOpen } = useCollapsible({
        initialOpen,
    });

    return (
        <StyledCard>
            <Body as="header" style={{ position: 'relative', marginBottom: isOpen ? '24px' : '0' }}>
                {title && (
                    <Headline noMargin as="h2" size="four">
                        {title}
                    </Headline>
                )}
                <ToggleButton
                    type="button"
                    variant="tertiary"
                    label={isOpen ? 'Hide' : 'Show'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getButtonProps()}
                >
                    {isOpen ? <ChevronUp size="16" /> : <ChevronDown size="16" />}
                </ToggleButton>
            </Body>
            <Body
                as="div"
                noMargin
                style={{
                    ...getContentProps().style,
                    padding: '0 1px',
                }}
            >
                {children}
            </Body>
        </StyledCard>
    );
}

export default CloseableCard;

CloseableCard.defaultProps = {
    title: undefined,
    initialOpen: false,
};
