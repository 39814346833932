import React from 'react';
import { useFormContext, get } from 'react-hook-form';
import { Input } from '@sumup/circuit-ui';
import { FormInputProps } from './FormInput.types';

function FormInput({ name, ...rest }: FormInputProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <Input
            validationHint={get(errors, name, undefined)?.message}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register(name)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            invalid={!!get(errors, name, undefined)}
        />
    );
}

export default FormInput;
