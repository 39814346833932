export function isNonNullObject(input: unknown): input is object {
    return input !== null && typeof input === 'object';
}
export function keyHasValue<T>(object: T | null | undefined, key: keyof T) {
    if (object === undefined || object === null) return false;
    if (isNonNullObject(object)) {
        const value = object[key];
        if (value === undefined) return false;
        if (value === null) return false;
        return true;
    }
    return false;
}

export default { isNonNullObject };
