import React from 'react';
import currencyFormater from 'currency.js';
import { FormattedNumber } from 'react-intl';
import { Currency } from 'utils/generated';

interface Props {
    amount: number;
    currency: Currency;
}

function CurrencyFormat({ amount, currency }: Props) {
    const convertCents = (value: number | string) => currencyFormater(value, { fromCents: true, precision: 2 }).value;

    return (
        <FormattedNumber
            currency={currency}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={convertCents(amount)}
        />
    );
}

export default CurrencyFormat;
