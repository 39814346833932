import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Label } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { Alert } from '@sumup/icons';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types';
import ReactPhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const InputWrapper = styled.div`
    margin: 0px 0px 16px;
`;

type Props<T> = Omit<ControllerProps<T>, 'render'> & {
    label: string;
};

function PhoneInput<T>(props: Props<Omit<T, 'render'>>) {
    const { name, label, control } = props;
    const [countryData, setCountryData] = useState<undefined | CountryData>();

    const phoneNumberValidation = () => {
        if (!countryData) return 0;
        // This returns the length of the template supplied by the library for the selected country.
        return (countryData as CountryData).format.split('.').length;
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: { value: true, message: 'Phone number is required' },
                minLength: { value: phoneNumberValidation(), message: 'Phone number invalid' },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <InputWrapper>
                    {label && name && (
                        <Label
                            style={{ width: '100%', marginBottom: '4px' }}
                            htmlFor={name}
                            about={`Label for ${label}`}
                        >
                            {label}
                        </Label>
                    )}
                    <ReactPhoneInput
                        defaultErrorMessage={error?.message}
                        jumpCursorToEnd
                        isValid={!invalid}
                        inputProps={{ id: name }}
                        specialLabel=""
                        containerStyle={{
                            borderColor: `${invalid ? light.colors.danger : 'transparent'}`,
                            minWidth: '100%',
                            minHeight: '50px',
                        }}
                        inputStyle={{
                            minWidth: '100%',
                            border: `1.25px solid ${invalid ? light.colors.danger : '#999'}`,
                            borderRadius: '8px',
                            maxHeight: '50px',
                            minHeight: '50px',
                            fontSize: '16px',
                            lineHeight: '24px',
                            padding: '12px 16px 12px 58px',
                        }}
                        onChange={(newNumber) => onChange(`+${newNumber}`)}
                        onBlur={(_, country) => {
                            setCountryData(country as CountryData);
                        }}
                        value={(value as string)?.replace('+', '')}
                        defaultMask="gb"
                        country="gb"
                    />
                    {name && invalid && (
                        <Label
                            style={{
                                color: light.colors.danger,
                                width: '100%',
                                margin: '4px 0px 0px',
                            }}
                            htmlFor={name}
                            about={`Error for ${label}`}
                        >
                            <span style={{ lineHeight: '20px' }}>
                                <Alert
                                    style={{
                                        display: 'inline-block',
                                        width: `${light.iconSizes.kilo}`,
                                        height: `${light.iconSizes.kilo}`,
                                        verticalAlign: 'text-top',
                                        margin: `0px ${light.spacings.bit} 0px 0px`,
                                        color: `${light.colors.danger}`,
                                        lineHeight: '20px',
                                    }}
                                />
                                {error?.message}
                            </span>
                        </Label>
                    )}
                </InputWrapper>
            )}
        />
    );
}

export default PhoneInput;
