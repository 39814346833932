import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline, IconButton } from '@sumup/circuit-ui';
import { ChevronLeft } from '@sumup/icons';
import { light } from '@sumup/design-tokens';

interface Props {
    title?: string;
    showBack?: boolean;
    cta?: React.ReactNode;
}

const headingWithCtsStyles = () => css`
    width: 100%;
    max-width: 900px;
    min-height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: ${light.spacings.tera};
    ${light.mq.untilKilo} {
        margin-bottom: ${light.spacings.byte};
    }
`;

const HeadingWithCta = styled.div(headingWithCtsStyles);

const StyledIconButton = styled(IconButton)`
    border: none;
    margin-right: 2px;
    padding: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
    transform: translateY(4px);
`;

function PageHeader({ title, showBack, cta }: Props) {
    const navigate = useNavigate();

    return (
        <HeadingWithCta>
            {title && (
                <Headline noMargin as="h1" size="two">
                    {showBack && (
                        <StyledIconButton label="Back" variant="secondary" onClick={() => navigate(-1)}>
                            <ChevronLeft />
                        </StyledIconButton>
                    )}
                    {title}
                </Headline>
            )}
            {cta && cta}
        </HeadingWithCta>
    );
}

export default PageHeader;

PageHeader.defaultProps = {
    cta: undefined,
    showBack: false,
};
