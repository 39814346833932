import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ChevronDown, Time } from '@sumup/icons';
import { Card, RangePickerController, Tag, Button, ButtonGroup } from '@sumup/circuit-ui';
import TimeRange from 'components/ui/forms/TimeRange';
import Popover from 'components/ui/components/Popover';
import CloseableTag from 'components/ui/components/CloseableTag';
import { DateRange, DateTimeRange } from 'typings/Calendar';
import moment from 'moment';
import formatDateRange from 'helpers/dateTimeHelper';
import DateRangePreset, { PRESETS } from './DateRangePreset';

interface Props {
    submit: ({
        createdAtStart,
        createdAtEnd,
    }: {
        createdAtStart: string | undefined;
        createdAtEnd: string | undefined;
    }) => void;
}

const presets = Object.keys(PRESETS) as Array<keyof typeof PRESETS>;

function DateTimeFilter({ submit }: Props) {
    const intl = useIntl();
    const [preset, setPreset] = useState<PRESETS | undefined>(undefined);

    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState<DateRange | null>();
    const [timeRange, setTimeRange] = useState<DateTimeRange | null>(null);

    const [focusedInput, setFocusedInput] = useState<string | null>(null);
    const [showTimeRange, setShowTimeRange] = useState(false);

    const confirm = useCallback(() => {
        setIsOpen(false);
        const { start, end } = formatDateRange(range, timeRange, true);
        submit({
            createdAtStart: preset === PRESETS.CUSTOM ? end : start,
            createdAtEnd: preset === PRESETS.CUSTOM ? start : end,
        });
    }, [preset, range, submit, timeRange]);

    const updateTime = (update: Partial<DateTimeRange>) => {
        setTimeRange((current) => ({ ...current, ...update }));
    };

    const selectPreset = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const rangeTodayMinus = (amount: number, unit: 'd' | 'w' | 'm') => {
            const now = moment(new Date());
            return {
                startDate: now.clone().startOf('day'),
                endDate: now.clone().subtract(amount, unit).endOf('day'),
            };
        };

        const newPreset = PRESETS[event.currentTarget.value as keyof typeof PRESETS];

        if (newPreset === PRESETS.TODAY) {
            const today = moment(new Date());
            setRange({
                startDate: today.clone().startOf('day'),
                endDate: today.clone().endOf('day'),
            });
        }
        if (newPreset === PRESETS.YESTERDAY) {
            const yesterday = moment(new Date()).subtract(1, 'day');
            setRange({
                startDate: yesterday.clone().startOf('day'),
                endDate: yesterday.clone().endOf('day'),
            });
        }
        if (newPreset === PRESETS.LASTWEEK) {
            setRange(rangeTodayMinus(7, 'd'));
        }
        if (newPreset === PRESETS.LASTTHIRTY) {
            setRange(rangeTodayMinus(30, 'd'));
        }
        if (newPreset === PRESETS.CUSTOM) {
            setRange({ endDate: undefined, startDate: undefined });
            setTimeRange({ endTime: undefined, startTime: undefined });
        }
        setPreset(newPreset);
    }, []);

    const formatLabelDate = (date?: string) =>
        intl.formatDate(date, {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            localeMatcher: 'best fit',
        });

    const label =
        focusedInput !== null
            ? `
                ${formatLabelDate(formatDateRange(range, timeRange)?.start)}
                -
                ${formatLabelDate(formatDateRange(range, timeRange)?.end)}
                `
            : 'Select dates';

    const handleFocusChange = () => setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');

    const openFilter = () => {
        setIsOpen(true);
        setFocusedInput('startDate');
    };

    const clearDates = useCallback(() => {
        setIsOpen(false);
        setPreset(undefined);
        setShowTimeRange(false);
        setFocusedInput(null);
        submit({ createdAtEnd: undefined, createdAtStart: undefined });
        setRange({ startDate: undefined, endDate: undefined });
    }, [submit]);

    const toggleTimePicker = useCallback(() => {
        if (showTimeRange) {
            setTimeRange({ startTime: undefined, endTime: undefined });
        }
        setShowTimeRange(!showTimeRange);
    }, [showTimeRange]);

    return (
        <Popover
            isOpen={isOpen}
            onOutsideClick={() => setIsOpen(false)}
            popoverComponent={
                <Card
                    style={{
                        minWidth: '384px',
                        minHeight: `${preset !== PRESETS.CUSTOM ? '400px' : '550px'}`,
                        alignItems: 'center',
                    }}
                >
                    {preset === PRESETS.CUSTOM && (
                        <Button variant="tertiary" size="kilo" onClick={() => setPreset(undefined)}>
                            Use preset
                        </Button>
                    )}
                    {preset !== PRESETS.CUSTOM &&
                        presets.map((item) => (
                            <DateRangePreset
                                onClick={selectPreset}
                                thisPreset={item}
                                selected={preset ? preset.valueOf() === PRESETS[item].valueOf() : false}
                            />
                        ))}
                    {preset === PRESETS.CUSTOM && (
                        <RangePickerController
                            startDate={range?.startDate}
                            endDate={range?.endDate}
                            onDatesChange={setRange}
                            focusedInput={focusedInput}
                            onFocusChange={handleFocusChange}
                            noBorder
                            noMargin
                            minimumNight={1}
                        />
                    )}
                    {showTimeRange && <TimeRange dateTimeRange={timeRange} setTime={updateTime} />}
                    {range?.startDate && range.endDate && (
                        <Button variant="tertiary" stretch onClick={toggleTimePicker}>
                            {!showTimeRange && <Time />} &nbsp; {!showTimeRange ? 'Set a time' : 'Cancel'}
                        </Button>
                    )}

                    <ButtonGroup>
                        <Button size="kilo" variant="secondary" onClick={clearDates}>
                            Clear
                        </Button>
                        <Button
                            size="kilo"
                            disabled={!range?.startDate || !range.endDate}
                            variant="primary"
                            onClick={confirm}
                        >
                            Submit
                        </Button>
                    </ButtonGroup>
                </Card>
            }
            toggleComponent={
                <CloseableTag showClose={focusedInput !== null}>
                    <Tag
                        selected={range?.startDate !== undefined && range?.endDate !== undefined}
                        onClick={openFilter}
                        onRemove={clearDates}
                        removeButtonLabel="Clear dates"
                        suffix={focusedInput === null ? ChevronDown : undefined}
                    >
                        {label}
                    </Tag>
                </CloseableTag>
            }
        />
    );
}

export default DateTimeFilter;
