import styled from '@emotion/styled';

const FilterBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    margin: 12px;
`;

export default FilterBar;
