import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { usePutQrCodeMutation } from 'utils/generated';
import Loading from 'components/layout/Loading';
import FormError from 'components/FormError/FormError';
import PageHeader from 'components/layout/PageHeader';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import SelectTableList from './SelectTableList';

type Params = {
    qrCode: string;
    locationId: string;
};

function LinkQrSelectTable() {
    const { qrCode, locationId } = useParams<Params>();

    const { mutate, error, isLoading, isSuccess } = usePutQrCodeMutation();

    if (isSuccess) return <Navigate to="success" />;

    const handleLinkQrCode = (posTableReference: string, name: string) => {
        mutate({ id: qrCode!, posTableReference, tableName: name, locationId: locationId! });
    };

    return (
        <ContentPane>
            <Content>
                <PageHeader title="Select table" showBack />

                <FormError errors={error} />
                {isLoading && <Loading />}
                {!isLoading && (
                    <SelectTableList
                        locationId={locationId!}
                        didSelect={(posReference, tableName) => handleLinkQrCode(posReference, tableName)}
                    />
                )}
            </Content>
        </ContentPane>
    );
}

export default LinkQrSelectTable;
