import styled from '@emotion/styled';
import { Theme } from '@sumup/design-tokens';
import { Spacings } from '@sumup/design-tokens/dist/es/types';

interface SpacerProps {
    theme?: Theme;
    space: keyof Spacings;
}

const Spacer = styled.div<SpacerProps>`
    width: 100%;
    height: ${(props: { theme: Theme; space: keyof Spacings }) => props.theme.spacings[props.space]};
`;

export default Spacer;
