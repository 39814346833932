import React from 'react';
import styled from '@emotion/styled';
import { Button, Input } from '@sumup/circuit-ui';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import { light } from '@sumup/design-tokens';
import { Delete } from '@sumup/icons';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import LabelWithToolTip from 'components/ui/components/LabelWithToolTip';

interface Props<T> {
    path: Path<T>;
    index: number;
    remove: (e: ClickEvent<Element>, index: number) => void;
    midErrors:
        | {
              mid?: FieldError | undefined;
          }[]
        | undefined;
}

const SumUpMerchantIdInputWrapper = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr;
    gap: 16px;
    align-items: center;
`;

function SumUpMerchantIdInput<T>({ path, index, remove, midErrors }: Props<T>) {
    const name = `${path}.${index}.mid` as Path<T>;
    const { register } = useFormContext();

    return (
        <SumUpMerchantIdInputWrapper>
            <Button
                style={{ marginBottom: midErrors && midErrors[index] ? '24px' : undefined }}
                variant="tertiary"
                onClick={(e) => remove(e, index)}
            >
                <Delete color={light.colors.r500} />
            </Button>
            <Input
                autoComplete="off"
                validationHint={midErrors?.[index] && midErrors?.[index]?.mid?.message}
                invalid={!!midErrors?.[index]}
                {...register(name, {
                    pattern: {
                        value: /^([A-Z\d]+)$/,
                        message: 'Merchant IDs should be uppercase and only contain number and letters',
                    },
                })}
                label={
                    <LabelWithToolTip
                        tipText="You can find your ID in the me.sumup.com portal"
                        labelText={`SumUp merchant ID ${index + 1}`}
                    />
                }
            />
        </SumUpMerchantIdInputWrapper>
    );
}

export default SumUpMerchantIdInput;
