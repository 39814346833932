import moment from 'moment';

export interface TimeLineItem<T> {
    groupName: string;
    listItems: T[];
}

export function groupTimeLine<T>(groupKey: string, array: T[] | null | undefined) {
    if (!array) return undefined;
    moment.updateLocale('gb', {
        calendar: {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: '[Next] dddd',
            sameElse: 'DD/MM/YYYY',
        },
    });

    return array.reduce((accumulation, item) => {
        const groupName = moment(item[groupKey as keyof T]).calendar();

        const group = accumulation.find((current) => current.groupName === String(groupName));

        if (!group) {
            const newItem: TimeLineItem<T> = {
                groupName: String(groupName),
                listItems: [{ ...item }],
            };
            accumulation.push(newItem);
        }
        if (group) {
            group.listItems.push(item);
        }

        return accumulation;
    }, [] as TimeLineItem<T>[]);
}
