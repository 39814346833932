/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, ButtonGroup, CardHeader } from '@sumup/circuit-ui';
import { PriceBookInput, PutPriceBookMutation, usePutPriceBookMutation } from 'utils/generated';
import SimpleGrid from 'components/layout/SimpleGrid';
import styled from '@emotion/styled';
import FormWithSchema from 'components/FormWithSchema/FormWithSchema';
import FormInput from 'components/FormWithSchema/fields/FormInput/FormInput';
import FormError from 'components/FormError/FormError';
import PriceBookSchema from './PriceBookModal.schema';
import { PricebookModalProps } from './PriceBookModal.types';

const ModalBody = styled.div`
    margin: 8px;
    padding: 0px;
    display: flex;
    flex-grow: 2;
    gap: 20px;
    flex-direction: column;
    overflow: visible;
`;

function PricebookModal({ title, submitButtonName, priceBook, closeCallback }: PricebookModalProps) {
    const { mutateAsync, isLoading, error: mutationError, isSuccess } = usePutPriceBookMutation();

    useEffect(() => {
        if (isSuccess) {
            closeCallback();
        }
    }, [isSuccess, closeCallback]);

    return (
        <ModalBody>
            <CardHeader closeButtonLabel="Close" onClose={closeCallback}>
                {title}
            </CardHeader>
            <FormError errors={mutationError} />

            <FormWithSchema<Omit<PriceBookInput, 'id'>, PutPriceBookMutation>
                schema={PriceBookSchema}
                onSubmit={(formData) => mutateAsync({ priceBook: { id: priceBook!.id!, name: formData.name } })}
                initialValues={priceBook}
            >
                <SimpleGrid columnTemplate="1fr">
                    <FormInput name="name" label="Name" />
                </SimpleGrid>
                <ButtonGroup align="right">
                    <Button disabled={isLoading} variant="primary" type="submit">
                        {submitButtonName}
                    </Button>
                    <Button variant="secondary" onClick={() => closeCallback()} type="button">
                        Cancel
                    </Button>
                </ButtonGroup>
            </FormWithSchema>
        </ModalBody>
    );
}

export default PricebookModal;
