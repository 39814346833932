import React from 'react';
import { Button, ButtonGroup, SubHeadline } from '@sumup/circuit-ui';
import Form from 'components/Form';
import 'react-loading-skeleton/dist/skeleton.css';
import SimpleGrid from 'components/layout/SimpleGrid';
import Skeleton from 'react-loading-skeleton';
import ToggleLoading from 'components/ui/loading/ToggleLoading';
import TextInputLoading from 'components/ui/loading/TextInputLoading';
import ColorInputLoading from 'components/ui/loading/ColorInputLoading';
import PinInputLoading from 'components/ui/loading/PinInputLoading';
import FormSection from './components/FormSection';

function TerminalConfigurationFormLoading() {
    return (
        <Form onSubmit={() => null}>
            <FormSection title="Details">
                <TextInputLoading />
            </FormSection>
            <FormSection title="Accessibility">
                <ToggleLoading />
            </FormSection>
            <FormSection title="Security">
                <PinInputLoading />
            </FormSection>
            <FormSection title="Price books">
                <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />
            </FormSection>
            <FormSection title="Appearance">
                <SimpleGrid columnTemplate="1fr 56px" isSingleColumnMobile={false}>
                    <TextInputLoading />
                    <Skeleton
                        enableAnimation
                        count={1}
                        style={{ margin: '28px 0px 0px 0px' }}
                        borderRadius="16px"
                        height="48px"
                        width="48px"
                    />
                </SimpleGrid>
                <SubHeadline as="h3">Screensavers</SubHeadline>
                <SimpleGrid columnTemplate="24px 1fr 56px" isSingleColumnMobile={false}>
                    <Skeleton
                        enableAnimation
                        count={1}
                        style={{ margin: '36px 0px 0px 0px' }}
                        borderRadius="16px"
                        height="24px"
                        width="24px"
                    />
                    <TextInputLoading />
                    <Skeleton
                        enableAnimation
                        count={1}
                        style={{ margin: '28px 0px 0px 0px' }}
                        borderRadius="16px"
                        height="48px"
                        width="48px"
                    />
                </SimpleGrid>
                <ButtonGroup align="right">
                    <Button disabled stretch={false} onClick={() => null}>
                        Add screensaver
                    </Button>
                </ButtonGroup>
                <SubHeadline as="h3">Colours</SubHeadline>
                <SimpleGrid columnTemplate="auto auto" isSingleColumnMobile={false}>
                    <ColorInputLoading />
                    <ColorInputLoading />
                    <ColorInputLoading />
                    <ColorInputLoading />
                    <ColorInputLoading />
                    <ColorInputLoading />
                </SimpleGrid>
            </FormSection>

            <FormSection title="Localisation">
                <SimpleGrid columnTemplate="1fr 1fr">
                    <TextInputLoading />
                    <TextInputLoading />
                </SimpleGrid>
            </FormSection>
            <FormSection title="Customer Messages">
                <TextInputLoading />
                <TextInputLoading />
            </FormSection>
            <FormSection title="Receipt">
                <ToggleLoading />
                <TextInputLoading />
            </FormSection>
            <FormSection title="KDS Connection">
                <SimpleGrid columnTemplate="1fr 1fr">
                    <TextInputLoading />
                    <TextInputLoading />
                </SimpleGrid>
            </FormSection>
        </Form>
    );
}

export default TerminalConfigurationFormLoading;
