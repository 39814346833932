import React from 'react';
import { TagProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

interface Props {
    children: TagProps;
    showClose: boolean;
}

const TagWrapper = styled('div')`
    display: inline-block;
    & > div button:first-of-type {
        padding-right: ${(props: Props) => (props.showClose ? '32px' : '12px')};
    }

    & > div button:last-child {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        transform: none;
        background: none;
        display: ${(props: Props) => (props.showClose ? 'block' : 'none')};
    }
`;

function CloseableTag({ showClose = false, children }: Props) {
    return <TagWrapper showClose={showClose}>{children}</TagWrapper>;
}

export default CloseableTag;
