import React, { useState, useEffect, useMemo } from 'react';
import { ImageInput } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { StyleProps } from 'typings/theme';

const MenuFileWrapper = styled.div`
    ${({ theme }: StyleProps) => `
        display: inline-block;
        margin-bottom: ${theme?.spacings.kilo};
    `}
`;

const StyledImage = styled.img`
    ${({ theme }: StyleProps) => `
        display: block;
        background-color: ${theme?.colors.n300};
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px;
        object-fit: cover;
        object-position: center center;
        border-radius: ${theme?.borderRadius.kilo};
        height: 96px;
        width: 96px;
    `}
`;

function MenuFile(): JSX.Element {
    const [file, setFile] = useState<File>();
    const [fileValidationError, setFileValidationError] = useState('');

    useEffect(() => {
        document.getElementById('menu_upload')?.setAttribute('accept', 'application/pdf');
    }, []);

    // TO DO: store menu
    const handleFileChange = (data: File) => {
        if (data.type !== 'application/pdf') {
            setFileValidationError('Please upload a valid menu in PDF format.');
            return;
        }
        if (data.size > 5000000) {
            setFileValidationError(
                'The uploaded file exceeds the maximum allowed size. Please select a PDF with a size below 5mb.'
            );
            return;
        }
        setFileValidationError('');
        setFile(data);
    };

    const handleRemoveFile = () => {
        setFileValidationError('');
        setFile(undefined);
    };

    const FilePlaceholder = useMemo(
        () => <StyledImage src={`/file-icon${file ? '-edit' : ''}.svg`} alt="file upload" />,
        [file]
    );

    return (
        <MenuFileWrapper>
            <ImageInput
                id="menu_upload"
                label="Upload a menu"
                clearButtonLabel="Clear"
                onChange={handleFileChange}
                onClear={handleRemoveFile}
                loadingLabel="Please wait"
                src={file?.name}
                component={() => FilePlaceholder}
                invalid={!!fileValidationError}
                validationHint={fileValidationError}
            />
        </MenuFileWrapper>
    );
}

export default MenuFile;
