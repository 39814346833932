import { PopoverProps, SecondaryLinkProps } from '@sumup/circuit-ui';
import { Help, IconProps, QrCode } from '@sumup/icons';
import { useFeatureFlags } from 'contexts/FeatureFlagContext';
import React, { useMemo } from 'react';
import { matchPath, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

function useNavLinks({ hasCompletedSetup }: { hasCompletedSetup?: boolean }) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = useResolvedPath(location.pathname);
    const isCurrent = (path: string) => !!matchPath({ path, end: false }, currentPath.pathname);

    const { kioskConfigEnabled } = useFeatureFlags();

    const primaryLinks = useMemo(() => {
        const createSecondaryLink = (title: string, path: string, otherPaths?: string[]): SecondaryLinkProps => ({
            label: title,
            href: '#',
            onClick: (e) => {
                e.preventDefault();
                navigate(path);
            },
            // eslint-disable-next-line react-hooks/rules-of-hooks
            isActive: otherPaths ? [path, ...otherPaths].some((otherPath) => isCurrent(otherPath)) : isCurrent(path),
        });

        const kioskLinks = [
            { label: 'KDS Connections', url: 'kds-connections' },
            { label: 'Terminal Templates', url: 'terminal-templates' },
            { label: 'Price Books', url: 'price-books' },
        ];

        const kioskConfig = kioskConfigEnabled
            ? kioskLinks.map((item) => createSecondaryLink(item.label, item.url))
            : [];

        return [
            {
                icon: QrCode as React.FC<IconProps<'16' | '24' | '32'>>,
                label: 'Order & Pay',
                href: '#',
                onClick: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
                    e.preventDefault();
                    navigate('dashboard');
                },
                badge: false,
                isActive: true,
                secondaryGroups: [
                    {
                        secondaryLinks: [
                            createSecondaryLink('Overview', 'dashboard'),
                            createSecondaryLink('Payments', 'payments'),
                            createSecondaryLink('Payouts', 'payouts'),
                        ],
                    },
                    {
                        label: 'Setup',
                        secondaryLinks: [
                            createSecondaryLink('Locations', 'locations'),
                            createSecondaryLink('POS Connections', 'pos-connections'),
                            ...kioskConfig,
                        ],
                    },
                    {
                        label: 'Shop',
                        secondaryLinks: [
                            createSecondaryLink('Order QR Codes', 'order-qr-code', ['confirm-delivery-information']),
                        ],
                    },
                ],
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, kioskConfigEnabled]);

    const profileActions = useMemo(() => {
        return [
            {
                onClick: () => navigate('/merchants'),
                children: 'Merchants',
                destructive: false,
            },
            {
                onClick: () => navigate('/user-settings/payout-settings/view'),
                children: 'Payout and bank information',
                destructive: false,
                disabled: !hasCompletedSetup,
            },
            {
                type: 'divider',
            },
            {
                onClick: () => navigate('/logout'),
                children: 'Logout',
                destructive: true,
            },
        ] as PopoverProps['actions'];
    }, [hasCompletedSetup, navigate]);

    const activePrimaryLink = primaryLinks.find((link) => link.isActive);
    const activeSecondaryLink = primaryLinks[0].secondaryGroups[0].secondaryLinks.find((item) => item.isActive);

    const utilityLinks = [
        {
            icon: Help as React.FC<IconProps<'16' | '24' | '32'>>,
            label: 'Support',
            href: `https://support.thegoodtill.com/`,
            isActive: false,
        },
    ];

    return { utilityLinks, activePrimaryLink, profileActions, primaryLinks, activeSecondaryLink };
}

export default useNavLinks;
