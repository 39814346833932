import React from 'react';
import styled from '@emotion/styled';
import { ButtonGroup, Button } from '@sumup/circuit-ui';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import ImageCardContainer from './ImageCardContainer';
import ImageContainer from './ImageContainer';
import CardBody from './ImageCardBody';

const ImageWrapper = styled.img`
    max-width: 100%;
    max-height: 150px;
    min-height: 150px;
    height: auto;
    object-fit: cover;
`;

const ImageCardFooter = styled.div`
    padding: 20px 20px 20px;
`;

function ImageCard({
    title,
    image,
    buttonLabel,
    description,
    teaserUrl,
    onClick,
    notAvailable,
    center,
    imageBackgroundColor,
}: {
    title: React.ReactNode;
    image: string;
    buttonLabel: string;
    description?: React.ReactNode;
    teaserUrl?: string;
    onClick?: (event: ClickEvent<Element>) => void;
    notAvailable?: boolean;
    center?: boolean;
    imageBackgroundColor?: string;
}) {
    return (
        <ImageCardContainer gridTemplateRows={description ? '150px 0.75fr 1fr 1fr' : '140px 0.75fr 1fr'}>
            <ImageContainer backgroundColor={imageBackgroundColor}>
                <ImageWrapper src={image} alt={`${title} image`} />
            </ImageContainer>
            {title}
            {description && <CardBody center={center}>{description}</CardBody>}
            <ImageCardFooter>
                <ButtonGroup align={center ? 'center' : 'left'}>
                    <Button size="giga" disabled={notAvailable} variant="primary" onClick={onClick} href={teaserUrl}>
                        {buttonLabel}
                    </Button>
                </ButtonGroup>
            </ImageCardFooter>
        </ImageCardContainer>
    );
}

export default ImageCard;

ImageCard.defaultProps = {
    center: false,
};
