export function upperFirstChar(x: string | null) {
    if (!x) return undefined;
    return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
}

// If table name is just a nunber a prefix of Table is added.
export function tableNumberFormatter(tableName: string | null | undefined) {
    return tableName?.match('^[0-9]*$') ? `Table ${tableName}` : tableName;
}

export default { upperFirstChar };
