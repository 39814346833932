import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/layout/PageHeader';
import { PutLocationMutationVariables, usePutLocationMutation } from 'utils/generated';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import FormError from 'components/FormError/FormError';
import Loading from 'components/layout/Loading';
import LocationForm from './LocationForm';

function LocationCreate() {
    const navigate = useNavigate();
    const { mutateAsync, error, isLoading } = usePutLocationMutation();

    const onSubmit = async (values: PutLocationMutationVariables) => {
        return mutateAsync(values).then((x) => navigate(`../${x.putLocation.id}`));
    };

    const formDefaultValues = {
        enabled: false,
        percentageLow: 10.0,
        percentageMedium: 12.5,
        percentageHigh: 15.0,
    };

    return (
        <ContentPane>
            <Content>
                <PageHeader title="Create location" showBack />
                <FormError errors={error} />
                {isLoading && <Loading />}
                {!isLoading && (
                    <LocationForm defaultValues={formDefaultValues} onSubmit={onSubmit} isSavingLocation={isLoading} />
                )}
            </Content>
        </ContentPane>
    );
}

export default LocationCreate;
