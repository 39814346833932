import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Body, Button, ButtonGroup, Headline, Input } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import Form from 'components/Form';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import PageHeader from 'components/layout/PageHeader';
import SimpleGrid from 'components/layout/SimpleGrid';
import Select from 'components/ui/components/Select';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateQrCodeOrderMutation, QrCodesType } from 'utils/generated';

import { iso31661 } from 'iso-3166';
import PhoneInput from 'components/ui/components/PhoneInput';
import FormError from 'components/FormError/FormError';

type DeliveryInfoForm = {
    contactName: string;
    businessName: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    postCode: string;
    country: string;
    phoneNumber: string;
    qrType: keyof typeof QrCodesType;
};

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: ${light.spacings.giga};
    padding-right: ${light.spacings.giga};
    justify-items: center;
`;

interface Props {
    hasNoSideBar?: boolean;
}

function QRDeliveryInformation({ hasNoSideBar }: Props) {
    const location = useLocation();
    const { qrType } = (location.state as { qrType: keyof typeof QrCodesType | undefined }) ?? {
        qrType: undefined,
    };
    const navigate = useNavigate();

    const qrTypeDictionary = {
        VinylStickers: 'dome stickers',
        Stands: 'acrylic blocks',
    };

    const {
        reset,
        register,
        handleSubmit,
        control,
        formState: { isSubmitting, errors, isDirty },
    } = useForm<DeliveryInfoForm>();

    const { mutateAsync, error } = useCreateQrCodeOrderMutation();

    const beforeSubmit = async (deliveryForm: DeliveryInfoForm) => {
        const { addressOne, addressTwo, city, postCode, phoneNumber, country, contactName, businessName } =
            deliveryForm;

        const address = [addressOne, addressTwo, city, postCode, country].filter((x) => x && x.length > 0).join(', ');

        if (qrType && address.length > 1) {
            const response = await mutateAsync({
                businessName,
                contactDetails: {
                    name: contactName,
                    phoneNumber,
                    address,
                },
                type: QrCodesType[qrType],
            }).catch((err) => err);
            if (response?.createQrCodesOrder?.type) {
                reset(deliveryForm);
                navigate('/dashboard');
            }
        }
    };

    useEffect(() => {
        if (!qrType) navigate(location.pathname.replace('confirm-delivery-information', 'order-qr-code'));
    }, [location.pathname, navigate, qrType]);

    const countries = iso31661.map((entry) => {
        if (entry.alpha2 === 'GB') {
            return {
                label: 'United Kingdom',
                value: entry.alpha2,
            };
        }
        return {
            label: entry.name,
            value: entry.alpha2,
        };
    });

    return (
        <ContentPane>
            <Content hasNoSideBar={hasNoSideBar}>
                <PageHeader title="Confirm delivery information" showBack />
                <FormError errors={error} />
                <FormContainer>
                    <Headline as="h3" size="three">
                        Shipping address
                    </Headline>
                    <Body>Your {qrTypeDictionary[qrType!]} will be delivered in 2-3 business days for free.</Body>
                    <Form style={{ width: '100%' }} onSubmit={handleSubmit(beforeSubmit)}>
                        <SimpleGrid>
                            <Input
                                label="Contact name *"
                                noMargin
                                invalid={!!errors.contactName}
                                validationHint={errors.contactName && 'Contact name is required'}
                                {...register('contactName', { required: true })}
                                placeholder="Jane"
                            />
                            <PhoneInput<DeliveryInfoForm> name="phoneNumber" label="Phone number *" control={control} />
                        </SimpleGrid>
                        <Input
                            style={{ width: '100%' }}
                            label="Business name *"
                            validationHint={errors.businessName && 'Business name is required'}
                            invalid={!!errors.businessName}
                            {...register('businessName', { required: true })}
                            placeholder="Janes Noodle Bar"
                        />
                        <Input
                            label="Address 1 *"
                            validationHint={errors.addressOne && 'Address is required'}
                            invalid={!!errors.addressOne}
                            {...register('addressOne', { required: true })}
                            placeholder="123 Park rd"
                        />
                        <Input label="Address 2" {...register('addressTwo')} />
                        <SimpleGrid style={{ width: '100%' }} columnTemplate="2fr 2fr">
                            <Input
                                label="City *"
                                noMargin
                                invalid={!!errors.city}
                                validationHint={errors.city && 'City is required'}
                                {...register('city', { required: true })}
                                placeholder="London"
                            />
                            <Input
                                label="Postcode *"
                                noMargin
                                invalid={!!errors.postCode}
                                validationHint={errors.postCode && 'Postcode is required'}
                                {...register('postCode', { required: true })}
                                placeholder="WC2H 9AX"
                            />
                        </SimpleGrid>
                        <Controller
                            name="country"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange } }) => (
                                <Select<{
                                    label: string;
                                    value: string;
                                }>
                                    options={countries}
                                    id="Country"
                                    label="Country *"
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    placeholder="Country"
                                    onChange={(x) => onChange(x?.value)}
                                    formError={!!errors?.country}
                                    errorMessage="Country is required"
                                />
                            )}
                        />
                        <ButtonGroup align="left">
                            <Button
                                type="submit"
                                variant="primary"
                                isLoading={isSubmitting}
                                loadingLabel="Processing..."
                                disabled={!isDirty}
                            >
                                Place your order
                            </Button>
                        </ButtonGroup>
                    </Form>
                </FormContainer>
            </Content>
        </ContentPane>
    );
}

export default QRDeliveryInformation;
