import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline, Spinner } from '@sumup/circuit-ui';

interface LoadingStyleProps {
    hasBorder?: boolean;
    message?: string;
}

const noDataStyles = ({ hasBorder }: LoadingStyleProps) => css`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-height: 30vh;
    border: ${hasBorder ? '2px solid #e6e6e6' : ''};
    border-radius: ${hasBorder ? '16px' : ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const LoadingWrapper = styled.div<LoadingStyleProps>(noDataStyles);

function Loading({ hasBorder, message }: LoadingStyleProps) {
    return (
        <LoadingWrapper hasBorder={hasBorder}>
            {message && (
                <Headline size="three" style={{ width: '100%', textAlign: 'center' }} as="h4">
                    {message}
                </Headline>
            )}
            <Spinner />
        </LoadingWrapper>
    );
}

export default Loading;

Loading.defaultProps = {
    hasBorder: false,
    message: undefined,
};
