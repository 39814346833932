import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { light } from '@sumup/design-tokens';

interface SimpleGridProps {
    columnTemplate?: string;
    isSingleColumnMobile?: boolean;
    gap?: string;
    children: React.ReactNode;
}

const simpleGridStyles = ({ columnTemplate, isSingleColumnMobile, gap }: Omit<SimpleGridProps, 'children'>) => css`
    display: grid;
    grid-template-columns: ${columnTemplate};
    grid-template-rows: auto;
    gap: ${gap};
    margin-bottom: 20px;
    ${light.mq.untilKilo} {
        grid-template-columns: ${isSingleColumnMobile ? '1fr' : columnTemplate};
    }
`;

const SimpleGrid = styled.div<Omit<SimpleGridProps, 'children'>>(({ columnTemplate, isSingleColumnMobile, gap }) =>
    simpleGridStyles({ columnTemplate, isSingleColumnMobile, gap })
);

export default SimpleGrid;

SimpleGrid.defaultProps = {
    isSingleColumnMobile: true,
    columnTemplate: '1fr 1fr',
    gap: '20px',
};
