import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import { cache } from '@emotion/css';
import { light } from '@sumup/design-tokens';
import { BaseStyles, ModalProvider, ToastProvider } from '@sumup/circuit-ui';
import Router from 'router/Router';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'components/layout/Loading';

const queryClient = new QueryClient();

function App() {
    useEffect(() => {
        window.addEventListener('storage', () => queryClient.invalidateQueries());
        return () => window.removeEventListener('storage', () => queryClient.invalidateQueries());
    }, []);

    return (
        <BrowserRouter>
            <CacheProvider value={cache}>
                <ThemeProvider theme={light}>
                    <QueryClientProvider client={queryClient}>
                        <IntlProvider locale="en-GB" defaultLocale="en-GB">
                            <BaseStyles />
                            <ModalProvider>
                                <ToastProvider>
                                    <React.Suspense fallback={<Loading />}>
                                        <Router />
                                    </React.Suspense>
                                </ToastProvider>
                            </ModalProvider>
                        </IntlProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </ThemeProvider>
            </CacheProvider>
        </BrowserRouter>
    );
}

export default App;
