import React from 'react';
import { Card, CardHeader, Headline, Body } from '@sumup/circuit-ui';
import Loading from 'components/layout/Loading';
import { useGetMerchantPayoutAccountDetailsQuery, usePutPayoutBankAccountMutation } from 'utils/generated';
import { useParams } from 'react-router-dom';
import FormError from 'components/FormError/FormError';
import PayoutSettingsEdit from './PayoutSettingsEdit';
import PayoutSettingsView from './PayoutSettingsView';

function PayoutBankAccount() {
    const { sectionName } = useParams();
    const { data, isLoading, error } = useGetMerchantPayoutAccountDetailsQuery();

    const mutation = usePutPayoutBankAccountMutation();

    const { error: mutationError } = mutation;

    const defaultValues = data?.getMerchant?.paymentConfiguration?.payoutBankAccount ?? undefined;

    const paymentsEnabled = data?.getMerchant?.paymentConfiguration?.enabled;

    const Form =
        sectionName === 'bank-account' ? (
            <PayoutSettingsEdit
                paymentsEnabled={paymentsEnabled}
                defaultValues={{ name: defaultValues?.name }}
                mutation={mutation}
            />
        ) : (
            <PayoutSettingsView paymentsEnabled={paymentsEnabled} defaultValues={defaultValues} />
        );

    return (
        <>
            <FormError errors={error} />
            <FormError errors={mutationError} />
            <Card style={{ width: '100%', gap: '2px' }}>
                <CardHeader>
                    <Headline as="h3" size="three" noMargin>
                        Payout and bank details
                    </Headline>
                </CardHeader>
                <Body>All payouts will be deposited in this bank account as soon as they become available.</Body>
                <Body>
                    If you need to update your bank account information, click &ldquo;Change bank account&ldquo; below.
                </Body>
                {isLoading ? <Loading /> : Form}
            </Card>
        </>
    );
}

export default PayoutBankAccount;
