import React from 'react';
import { NotificationInline } from '@sumup/circuit-ui';

type Params = {
    message: string;
};

function Error({ message }: Params) {
    return <NotificationInline style={{ marginBottom: '3px' }} body={message} variant="alert" />;
}

export default Error;
