import { Button, ButtonGroup } from '@sumup/circuit-ui';
import Form from 'components/Form';
import DisplayOnlyInput from 'components/ui/forms/DisplayOnlyInput';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PaymentConfiguration } from 'utils/generated';

interface Props {
    defaultValues?: Pick<PaymentConfiguration, 'statementDescriptor'>;
    paymentsEnabled?: boolean;
}

function PayoutSettingsDetail({ defaultValues, paymentsEnabled }: Props) {
    const navigate = useNavigate();
    const { register } = useForm<Pick<PaymentConfiguration, 'statementDescriptor'>>({ defaultValues });

    return (
        <Form>
            <DisplayOnlyInput {...register('statementDescriptor')} label="Description of your business" readOnly />
            <ButtonGroup align="left">
                <Button disabled={!paymentsEnabled} variant="primary" onClick={() => navigate('../edit/descriptor')}>
                    Change descriptor
                </Button>
            </ButtonGroup>
        </Form>
    );
}

export default PayoutSettingsDetail;
