import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { light, Theme } from '@sumup/design-tokens';
import { Headline, IconButton } from '@sumup/circuit-ui';
import { ArrowLeft, Close } from '@sumup/icons';

interface Props {
    title: string;
    showBack?: boolean;
    onClose?: () => void;
}

const detailsResponsiveStyles = () => {
    return css`
        width: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        margin-top: 0;
        border-bottom: 2px solid #e6e6e6;
        grid-template-columns: 48px 1fr 48px;
        height: 56px;
        ${light.mq.untilKilo} {
            grid-template-columns: 42px 1fr 42px;
            border-bottom: 2px solid #e6e6e6;
            padding-right: 8px;
            padding-left: 8px;
        }
        ${light.mq.tera} {
            border: none;
        }
    `;
};

const Wrapper = styled.div(detailsResponsiveStyles);

function SidePaneHeader({ title, showBack, onClose }: Props) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Wrapper theme={theme as Theme}>
            {showBack && (
                <IconButton
                    style={{ width: '16px', alignSelf: 'end', gridColumn: 1 }}
                    label="Back"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                >
                    <ArrowLeft />
                </IconButton>
            )}
            <Headline
                noMargin
                as="h1"
                size="four"
                style={{
                    gridColumn: 2,
                    textAlign: 'center',
                }}
            >
                {title}
            </Headline>
            {onClose && (
                <IconButton
                    label="Close"
                    variant="secondary"
                    style={{
                        gridColumn: 3,
                        width: '32px',
                        height: '32px',
                        border: 'none',
                        alignSelf: 'end',
                        padding: '4px',
                        margin: '8px',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            )}
        </Wrapper>
    );
}

export default SidePaneHeader;

SidePaneHeader.defaultProps = {
    onClose: undefined,
    showBack: false,
};
