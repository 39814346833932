import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import FeatureFlagProvider from './FeatureFlagContext';
import UserInfoProvider from './UserInfoContext';

function CombinedContexts() {
    const { signOut } = useOutletContext<{ signOut: () => void }>();
    return (
        <UserInfoProvider>
            <FeatureFlagProvider>
                <Outlet context={{ signOut }} />
            </FeatureFlagProvider>
        </UserInfoProvider>
    );
}

export default CombinedContexts;
