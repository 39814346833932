import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { oapFetcher } from './oapFetcher';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type BankAccountInput = {
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export enum CalorieUnit {
  Kcals = 'KCALS'
}

export type Calories = {
  __typename?: 'Calories';
  amount: Scalars['Float'];
  unit: CalorieUnit;
};

export type ChildItem = {
  __typename?: 'ChildItem';
  amount: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ChildItemInput = {
  amount: Scalars['Int'];
  name: Scalars['String'];
  product: LineItemProduct;
  quantity: Scalars['Int'];
};

export type ContactDetails = {
  __typename?: 'ContactDetails';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ContactDetailsInput = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DummyPosConfig = {
  __typename?: 'DummyPosConfig';
  storeId?: Maybe<Scalars['String']>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  requestHeaders: Array<KeyValue>;
  url: Scalars['String'];
};

export type GoodtillPosConfig = {
  __typename?: 'GoodtillPosConfig';
  clientId?: Maybe<Scalars['String']>;
  outletId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type KdsConnection = {
  __typename?: 'KdsConnection';
  config?: Maybe<KdsConnectionConfig>;
  id: Scalars['String'];
  kdsSystem: KdsSystem;
  name: Scalars['String'];
};

export type KdsConnectionConfig = TickncookKdsConfig;

export type KdsConnectionConfigInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
};

export type KdsConnectionInput = {
  config?: InputMaybe<KdsConnectionConfigInput>;
  id?: InputMaybe<Scalars['String']>;
  kdsSystem: KdsSystem;
};

export enum KdsSystem {
  Tickncook = 'TICKNCOOK'
}

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  amount: Scalars['Int'];
  childItems: Array<ChildItem>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  posId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  taxItems: Array<TaxItem>;
};

export type LineItemInput = {
  amount: Scalars['Int'];
  childItems: Array<ChildItemInput>;
  createdAt: Scalars['String'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  product: LineItemProduct;
  quantity: Scalars['Int'];
  taxItems: Array<TaxItemInput>;
};

export type LineItemProduct = {
  id?: InputMaybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  menuUrl?: Maybe<Scalars['String']>;
  merchant: Merchant;
  name: Scalars['String'];
  posConnection?: Maybe<PosConnection>;
  qrCodes: Array<QrCode>;
  serviceChargeConfiguration: ServiceChargeConfiguration;
  terminals: Array<Terminal>;
};

export type LocationInput = {
  id?: InputMaybe<Scalars['String']>;
  menuUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  posConnectionId?: InputMaybe<Scalars['String']>;
  serviceChargeConfiguration: ServiceChargeConfigurationInput;
};

export type Merchant = {
  __typename?: 'Merchant';
  businessName: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  createdAt: Scalars['String'];
  currency: Currency;
  id: Scalars['String'];
  kdsConnections: Array<KdsConnection>;
  kycSubmittedAt?: Maybe<Scalars['String']>;
  locations: Array<Location>;
  paymentConfiguration?: Maybe<PaymentConfiguration>;
  payments?: Maybe<PaymentList>;
  posConnections: Array<PosConnection>;
  qrCodesOrders?: Maybe<QrCodesOrderList>;
  servicePaymentProcessors: ServicePaymentProcessors;
  status: MerchantStatus;
  terminalConfigurations: Array<TerminalConfiguration>;
};


export type MerchantPaymentsArgs = {
  listQuery?: InputMaybe<PaymentListQuery>;
};


export type MerchantQrCodesOrdersArgs = {
  listQuery?: InputMaybe<QrCodesOrderListQuery>;
};

export type MerchantInput = {
  businessName: Scalars['String'];
  countryCode: Scalars['String'];
  currency: Currency;
};

export type MerchantList = {
  __typename?: 'MerchantList';
  items?: Maybe<Array<Merchant>>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MerchantListQuery = {
  businessName?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type MerchantStatus = {
  __typename?: 'MerchantStatus';
  paymentsEnabled: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createMerchant: Merchant;
  createOrder: Order;
  createPayment: Payment;
  createPaymentRefund: Payment;
  createQrCodesOrder: QrCodesOrder;
  deleteQrCode?: Maybe<Scalars['Boolean']>;
  generateLocationQrCodes: Array<QrCode>;
  getLocationMenuUploadUrl: FileUpload;
  putKdsConnection: KdsConnection;
  putKyc: StripeKyc;
  putLocation: Location;
  putPaymentSetup: PaymentSetup;
  putPayoutBankAccount?: Maybe<BankAccount>;
  putPosConnection: PosConnection;
  putPriceBook: PriceBook;
  putQrCode: QrCode;
  putScreenLayout: ScreenLayout;
  putStatementDescriptor: Scalars['String'];
  putTerminal: Terminal;
  putTerminalConfiguration: TerminalConfiguration;
  setActiveMerchant: Scalars['Boolean'];
  submitKyc?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateMerchantArgs = {
  merchant: MerchantInput;
};


export type MutationCreateOrderArgs = {
  order: OrderInput;
};


export type MutationCreatePaymentArgs = {
  merchantId: Scalars['String'];
  orderId: Scalars['String'];
  payment: PaymentInput;
  paymentProcessorData: PaymentProcessorInput;
};


export type MutationCreatePaymentRefundArgs = {
  refund: RefundInput;
};


export type MutationCreateQrCodesOrderArgs = {
  businessName?: InputMaybe<Scalars['String']>;
  contactDetails: ContactDetailsInput;
  type: QrCodesType;
};


export type MutationDeleteQrCodeArgs = {
  locationId: Scalars['String'];
  qrCodeId: Scalars['String'];
};


export type MutationGenerateLocationQrCodesArgs = {
  locationId: Scalars['String'];
  merchantId: Scalars['String'];
};


export type MutationGetLocationMenuUploadUrlArgs = {
  locationId: Scalars['String'];
};


export type MutationPutKdsConnectionArgs = {
  kdsConnection: KdsConnectionInput;
};


export type MutationPutKycArgs = {
  data: StripeKycInput;
};


export type MutationPutLocationArgs = {
  location: LocationInput;
};


export type MutationPutPaymentSetupArgs = {
  merchantId: Scalars['String'];
  orderId: Scalars['String'];
  payment: PaymentInput;
  paymentProcessorData?: InputMaybe<PaymentProcessorInput>;
};


export type MutationPutPayoutBankAccountArgs = {
  bankAccount: BankAccountInput;
};


export type MutationPutPosConnectionArgs = {
  posConnection: PosConnectionInput;
};


export type MutationPutPriceBookArgs = {
  priceBook: PriceBookInput;
};


export type MutationPutQrCodeArgs = {
  locationId: Scalars['String'];
  qrCode: QrCodeInput;
};


export type MutationPutScreenLayoutArgs = {
  screenLayout: ScreenLayoutInput;
};


export type MutationPutStatementDescriptorArgs = {
  statementDescriptor: Scalars['String'];
};


export type MutationPutTerminalArgs = {
  locationId: Scalars['String'];
  terminal: TerminalInput;
};


export type MutationPutTerminalConfigurationArgs = {
  terminalConfiguration: TerminalConfigurationInput;
};


export type MutationSetActiveMerchantArgs = {
  merchantId: Scalars['String'];
};

export type OapError = {
  __typename?: 'OapError';
  errorInfo?: Maybe<Array<Maybe<ValidationErrorInfo>>>;
  errorType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Int'];
  completed: Scalars['Boolean'];
  covers?: Maybe<Scalars['Int']>;
  currency: Currency;
  hasDiscounts?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastRetrievedAt?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
  notes?: Maybe<Scalars['String']>;
  openedAt: Scalars['String'];
  orderPaymentIntention?: Maybe<OrderPaymentIntention>;
  payments: Array<Payment>;
  posId?: Maybe<Scalars['String']>;
  posPayments?: Maybe<Array<PosPayment>>;
  posReference?: Maybe<Scalars['String']>;
  posTableName?: Maybe<Scalars['String']>;
  serviceCharge?: Maybe<ServiceCharge>;
  staff?: Maybe<Staff>;
  state: OrderState;
  tax: Scalars['Int'];
};

export type OrderHeader = {
  __typename?: 'OrderHeader';
  id?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  merchantId: Scalars['String'];
};

export type OrderInput = {
  amount: Scalars['Int'];
  completed?: InputMaybe<Scalars['Boolean']>;
  covers?: InputMaybe<Scalars['Int']>;
  currency: Currency;
  id: Scalars['String'];
  lineItems: Array<LineItemInput>;
  locationId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  openedAt: Scalars['String'];
  payments: Array<PaymentCompleteInput>;
  posTableName?: InputMaybe<Scalars['String']>;
  reference: Scalars['String'];
  state?: InputMaybe<OrderState>;
  tax: Scalars['Int'];
  terminalId?: InputMaybe<Scalars['String']>;
};

export type OrderPaymentIntention = {
  __typename?: 'OrderPaymentIntention';
  orderPaymentType: OrderPaymentType;
  partySize?: Maybe<Scalars['Int']>;
};

export type OrderPaymentIntentionInput = {
  orderPaymentType: OrderPaymentType;
  partySize?: InputMaybe<Scalars['Int']>;
};

export enum OrderPaymentType {
  Full = 'FULL',
  Item = 'ITEM',
  Party = 'PARTY'
}

export enum OrderState {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN',
  Unknown = 'UNKNOWN'
}

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Int'];
  cardDetails?: Maybe<PaymentCardDetails>;
  consumerName?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  currency: Currency;
  id: Scalars['String'];
  lineItems?: Maybe<Array<PaymentLineItem>>;
  location?: Maybe<Location>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  parties?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
  processorMetadata?: Maybe<PaymentProcessorMetadata>;
  processorReference?: Maybe<Scalars['String']>;
  refunds: Array<Payment>;
  servicePayment?: Maybe<ServicePayment>;
  source: Source;
};

export type PaymentCardDetails = {
  __typename?: 'PaymentCardDetails';
  brand: Scalars['String'];
  last4: Scalars['String'];
  wallet?: Maybe<PaymentCardWallet>;
};

export type PaymentCardDetailsInput = {
  brand: Scalars['String'];
  last4: Scalars['String'];
  wallet?: InputMaybe<PaymentCardWallet>;
};

export enum PaymentCardWallet {
  AmexExpressCheckout = 'AMEX_EXPRESS_CHECKOUT',
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  Masterpass = 'MASTERPASS',
  SamsungPay = 'SAMSUNG_PAY',
  VisaCheckout = 'VISA_CHECKOUT'
}

export type PaymentCompleteInput = {
  amount: Scalars['Int'];
  cardDetails?: InputMaybe<PaymentCardDetailsInput>;
  consumerName?: InputMaybe<Scalars['String']>;
  currency: Currency;
  lineItems?: InputMaybe<Array<PaymentLineItemInput>>;
  orderPaymentIntention: OrderPaymentIntentionInput;
  parties?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  processor?: InputMaybe<PaymentProcessor>;
  processorMetadata?: InputMaybe<PaymentProcessorMetadataInput>;
  processorReference?: InputMaybe<Scalars['String']>;
  servicePayment?: InputMaybe<ServicePaymentInput>;
};

export type PaymentConfiguration = {
  __typename?: 'PaymentConfiguration';
  accountId: Scalars['String'];
  enabled: Scalars['Boolean'];
  paymentProcessor: PaymentProcessor;
  payoutBankAccount?: Maybe<BankAccount>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  amount: Scalars['Int'];
  consumerName?: InputMaybe<Scalars['String']>;
  currency: Currency;
  lineItems?: InputMaybe<Array<PaymentLineItemInput>>;
  orderPaymentIntention: OrderPaymentIntentionInput;
  parties?: InputMaybe<Scalars['Int']>;
  servicePayment?: InputMaybe<ServicePaymentInput>;
};

export type PaymentLineItem = {
  __typename?: 'PaymentLineItem';
  amount: Scalars['Int'];
  name: Scalars['String'];
  posId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PaymentLineItemInput = {
  amount: Scalars['Int'];
  posId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PaymentList = {
  __typename?: 'PaymentList';
  items?: Maybe<Array<Payment>>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentListQuery = {
  createdAtEnd?: InputMaybe<Scalars['String']>;
  createdAtStart?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum PaymentMethod {
  Card = 'CARD'
}

export enum PaymentProcessor {
  Stripe = 'STRIPE',
  Sumup = 'SUMUP'
}

export type PaymentProcessorInput = {
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
};

export type PaymentProcessorMetadata = {
  __typename?: 'PaymentProcessorMetadata';
  foreignTransactionId?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type PaymentProcessorMetadataInput = {
  foreignTransactionId?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
};

export type PaymentSetup = {
  __typename?: 'PaymentSetup';
  stripePaymentIntent: StripePaymentIntent;
};

export type Payout = {
  __typename?: 'Payout';
  amount: Scalars['Int'];
  arrivalAt: Scalars['String'];
  createdAt: Scalars['String'];
  currency: Currency;
  descriptor?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: PayoutStatus;
  transactions: TransactionList;
  type: Scalars['String'];
};


export type PayoutTransactionsArgs = {
  listQuery?: InputMaybe<TransactionListQuery>;
};

export type PayoutList = {
  __typename?: 'PayoutList';
  items?: Maybe<Array<Payout>>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PayoutListQuery = {
  arrivalAtFrom?: InputMaybe<Scalars['String']>;
  arrivalAtTo?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['String']>;
  createdAtTo?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export enum PayoutStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  InTransit = 'IN_TRANSIT',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type PosConnection = {
  __typename?: 'PosConnection';
  config: PosConnectionConfig;
  id: Scalars['String'];
  name: Scalars['String'];
  posSystem: PosSystem;
  refreshedAt?: Maybe<Scalars['String']>;
  status: PosConnectionStatus;
};

export type PosConnectionConfig = DummyPosConfig | GoodtillPosConfig | SumUpPosConfig;

export type PosConnectionConfigInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type PosConnectionInput = {
  config: PosConnectionConfigInput;
  id?: InputMaybe<Scalars['String']>;
  posSystem: PosSystem;
};

export enum PosConnectionStatus {
  Active = 'ACTIVE',
  RequiresReconnect = 'REQUIRES_RECONNECT'
}

export type PosLocation = {
  __typename?: 'PosLocation';
  name: Scalars['String'];
  posReference: Scalars['String'];
};

export type PosOrderList = {
  __typename?: 'PosOrderList';
  location: Location;
  orders: Array<OrderHeader>;
  qrCode?: Maybe<QrCode>;
};

export type PosPayment = {
  __typename?: 'PosPayment';
  amount: Scalars['Int'];
  external: Scalars['Boolean'];
  paymentMethod: Scalars['String'];
};

export enum PosSystem {
  Dummy = 'DUMMY',
  Goodtill = 'GOODTILL',
  Sumup = 'SUMUP'
}

export type PosTable = {
  __typename?: 'PosTable';
  area?: Maybe<Scalars['String']>;
  covers?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  posReference: Scalars['String'];
};

export type PriceBook = {
  __typename?: 'PriceBook';
  default: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PriceBookInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PriceBookLine = {
  __typename?: 'PriceBookLine';
  amount: Scalars['Int'];
  priceBook: PriceBook;
  taxRate: TaxRate;
};

export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<ProductImage>;
  name: Scalars['String'];
  variants: Array<ProductVariant>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  products: Array<Product>;
  selectedProducts?: Maybe<Array<ProductCategoryProduct>>;
};

export type ProductCategoryProduct = {
  __typename?: 'ProductCategoryProduct';
  name: Scalars['String'];
  productId: Scalars['String'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  url: Scalars['String'];
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  inventoryOnHand: Scalars['Int'];
  productId: Scalars['String'];
};

export type ProductModifier = {
  __typename?: 'ProductModifier';
  id: Scalars['String'];
  maxQuantity?: Maybe<Scalars['Int']>;
  minQuantity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  variants: Array<ProductVariantWithoutModifiers>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  barcode?: Maybe<Scalars['String']>;
  calories?: Maybe<Calories>;
  id: Scalars['String'];
  images: Array<ProductImage>;
  modifiers: Array<ProductModifier>;
  name: Scalars['String'];
  priceBookLines: Array<PriceBookLine>;
};

export type ProductVariantWithoutModifiers = {
  __typename?: 'ProductVariantWithoutModifiers';
  calories?: Maybe<Calories>;
  id: Scalars['String'];
  images: Array<ProductImage>;
  name: Scalars['String'];
  priceBookLines: Array<PriceBookLine>;
};

export type QrCode = {
  __typename?: 'QrCode';
  id: Scalars['String'];
  posTableReference: Scalars['String'];
  tableName: Scalars['String'];
};

export type QrCodeInput = {
  id: Scalars['String'];
  posTableReference: Scalars['String'];
  tableName: Scalars['String'];
};

export type QrCodesOrder = {
  __typename?: 'QrCodesOrder';
  contactDetails?: Maybe<ContactDetails>;
  merchantId: Scalars['String'];
  type: QrCodesType;
};

export type QrCodesOrderList = {
  __typename?: 'QrCodesOrderList';
  items?: Maybe<Array<QrCodesOrder>>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type QrCodesOrderListQuery = {
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export enum QrCodesType {
  Stands = 'STANDS',
  VinylStickers = 'VINYL_STICKERS'
}

export type Query = {
  __typename?: 'Query';
  getKdsConnection: KdsConnection;
  getKdsConnections: Array<KdsConnection>;
  getKyc?: Maybe<StripeKyc>;
  getLocation: Location;
  getLocationPublic: Location;
  getMerchant?: Maybe<Merchant>;
  getMerchantPayments: PaymentList;
  getMerchantPublic?: Maybe<Merchant>;
  getMerchantQrCodesOrders: QrCodesOrderList;
  getMerchants?: Maybe<MerchantList>;
  getOrder?: Maybe<Order>;
  getPayment?: Maybe<Payment>;
  getPayout: Payout;
  getPayoutTransactions: TransactionList;
  getPayouts: PayoutList;
  getPosConnection: PosConnection;
  getPosLocations: Array<PosLocation>;
  getPosOrderHeader: OrderHeader;
  getPosTableOrders?: Maybe<PosOrderList>;
  getPosTables: Array<PosTable>;
  getPreSignedUrl: Scalars['String'];
  getPriceBook: PriceBook;
  getPriceBooks: Array<PriceBook>;
  getProductCategories: Array<ProductCategory>;
  getProductInventory: Array<ProductInventory>;
  getProductPriceBooks: Array<PriceBook>;
  getQrCode: QrCode;
  getRecentPaymentsPos: Array<Payment>;
  getScreenLayout: ScreenLayout;
  getScreenLayouts: Array<ScreenLayout>;
  getTerminal: Terminal;
  getTerminalConfiguration: TerminalConfiguration;
  getTerminalConfigurations: Array<TerminalConfiguration>;
  getTerminals: Array<Terminal>;
};


export type QueryGetKdsConnectionArgs = {
  kdsConnectionId: Scalars['String'];
};


export type QueryGetLocationArgs = {
  locationId: Scalars['String'];
};


export type QueryGetLocationPublicArgs = {
  locationId: Scalars['String'];
  merchantId: Scalars['String'];
};


export type QueryGetMerchantPaymentsArgs = {
  listQuery?: InputMaybe<PaymentListQuery>;
};


export type QueryGetMerchantPublicArgs = {
  merchantId: Scalars['String'];
};


export type QueryGetMerchantQrCodesOrdersArgs = {
  listQuery?: InputMaybe<QrCodesOrderListQuery>;
};


export type QueryGetMerchantsArgs = {
  listQuery?: InputMaybe<MerchantListQuery>;
};


export type QueryGetOrderArgs = {
  merchantId: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryGetPaymentArgs = {
  id: Scalars['String'];
};


export type QueryGetPayoutArgs = {
  payoutId: Scalars['String'];
};


export type QueryGetPayoutTransactionsArgs = {
  listQuery?: InputMaybe<TransactionListQuery>;
  payoutId: Scalars['String'];
};


export type QueryGetPayoutsArgs = {
  listQuery?: InputMaybe<PayoutListQuery>;
};


export type QueryGetPosConnectionArgs = {
  posConnectionId: Scalars['String'];
};


export type QueryGetPosLocationsArgs = {
  posConnectionId: Scalars['String'];
};


export type QueryGetPosOrderHeaderArgs = {
  posLocationId: Scalars['String'];
  posOrderId: Scalars['String'];
  posSystem: PosSystem;
};


export type QueryGetPosTableOrdersArgs = {
  qr: Scalars['String'];
};


export type QueryGetPosTablesArgs = {
  locationId: Scalars['String'];
};


export type QueryGetPreSignedUrlArgs = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  mimeType: Scalars['String'];
};


export type QueryGetPriceBookArgs = {
  priceBookId: Scalars['String'];
};


export type QueryGetProductCategoriesArgs = {
  locationId: Scalars['String'];
};


export type QueryGetProductInventoryArgs = {
  locationId: Scalars['String'];
};


export type QueryGetProductPriceBooksArgs = {
  locationId: Scalars['String'];
};


export type QueryGetQrCodeArgs = {
  locationId: Scalars['String'];
  qrCodeId: Scalars['String'];
};


export type QueryGetRecentPaymentsPosArgs = {
  posLocationId: Scalars['String'];
  posSystem: PosSystem;
};


export type QueryGetScreenLayoutArgs = {
  screenLayoutId: Scalars['String'];
};


export type QueryGetTerminalArgs = {
  terminalId: Scalars['String'];
};


export type QueryGetTerminalConfigurationArgs = {
  terminalConfigurationId: Scalars['String'];
};


export type QueryGetTerminalsArgs = {
  locationId: Scalars['String'];
};

export type RefundInput = {
  amount?: InputMaybe<Scalars['Int']>;
  notes: Scalars['String'];
  paymentId: Scalars['String'];
};

export type ScreenLayout = {
  __typename?: 'ScreenLayout';
  id: Scalars['String'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  screens: Array<ProductCategory>;
};

export type ScreenLayoutCategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  selectedProducts: Array<ScreenLayoutProductInput>;
};

export type ScreenLayoutInput = {
  id?: InputMaybe<Scalars['String']>;
  locationId: Scalars['String'];
  name: Scalars['String'];
  screens: Array<ScreenLayoutCategoryInput>;
};

export type ScreenLayoutProductInput = {
  name: Scalars['String'];
  productId: Scalars['String'];
};

export type ServiceCharge = {
  __typename?: 'ServiceCharge';
  amount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  taxItems: Array<TaxItem>;
};

export type ServiceChargeConfiguration = {
  __typename?: 'ServiceChargeConfiguration';
  enabled: Scalars['Boolean'];
  percentageHigh?: Maybe<Scalars['Float']>;
  percentageLow?: Maybe<Scalars['Float']>;
  percentageMedium?: Maybe<Scalars['Float']>;
};

export type ServiceChargeConfigurationInput = {
  enabled: Scalars['Boolean'];
  percentageHigh?: InputMaybe<Scalars['Float']>;
  percentageLow?: InputMaybe<Scalars['Float']>;
  percentageMedium?: InputMaybe<Scalars['Float']>;
};

export type ServicePayment = {
  __typename?: 'ServicePayment';
  amount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  type: ServicePaymentType;
};

export type ServicePaymentInput = {
  amount: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  type: ServicePaymentType;
};

export type ServicePaymentProcessor = {
  __typename?: 'ServicePaymentProcessor';
  processor: PaymentProcessor;
};

export type ServicePaymentProcessors = {
  __typename?: 'ServicePaymentProcessors';
  tablePay: ServicePaymentProcessor;
};

export enum ServicePaymentType {
  ServiceCharge = 'SERVICE_CHARGE',
  Tip = 'TIP'
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum Source {
  Kiosk = 'KIOSK',
  TablePay = 'TABLE_PAY'
}

export type Staff = {
  __typename?: 'Staff';
  name: Scalars['String'];
  posId: Scalars['String'];
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
};

export type StripeAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
};

export type StripeBankAccount = {
  __typename?: 'StripeBankAccount';
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type StripeBankAccountInput = {
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type StripeBusinessProfile = {
  __typename?: 'StripeBusinessProfile';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type StripeBusinessProfileInput = {
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum StripeBusinessType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type StripeCompany = {
  __typename?: 'StripeCompany';
  address?: Maybe<StripeAddress>;
  name?: Maybe<Scalars['String']>;
  people: Array<StripePerson>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type StripeCompanyInput = {
  address?: InputMaybe<StripeAddressInput>;
  name?: InputMaybe<Scalars['String']>;
  people: Array<StripePersonInput>;
  phone?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type StripeKyc = {
  __typename?: 'StripeKyc';
  businessProfile?: Maybe<StripeBusinessProfile>;
  businessType?: Maybe<StripeBusinessType>;
  company?: Maybe<StripeCompany>;
  individual?: Maybe<StripePerson>;
  payoutBankAccount?: Maybe<StripeBankAccount>;
};

export type StripeKycInput = {
  businessProfile?: InputMaybe<StripeBusinessProfileInput>;
  businessType?: InputMaybe<StripeBusinessType>;
  company?: InputMaybe<StripeCompanyInput>;
  individual?: InputMaybe<StripePersonInput>;
  payoutBankAccount?: InputMaybe<StripeBankAccountInput>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  clientSecret: Scalars['String'];
  id: Scalars['String'];
};

export type StripePaymentIntentInput = {
  amount: Scalars['Int'];
  currency: Currency;
  id?: InputMaybe<Scalars['String']>;
  locationId: Scalars['String'];
  merchantId: Scalars['String'];
  orderId: Scalars['String'];
};

export type StripePerson = {
  __typename?: 'StripePerson';
  address: StripeAddress;
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  relationship?: Maybe<StripePersonRelationship>;
};

export type StripePersonInput = {
  address: StripeAddressInput;
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  relationship?: InputMaybe<StripePersonRelationshipInput>;
};

export type StripePersonRelationship = {
  __typename?: 'StripePersonRelationship';
  director: Scalars['Boolean'];
  executive: Scalars['Boolean'];
  owner: Scalars['Boolean'];
  representative: Scalars['Boolean'];
  title: Scalars['String'];
};

export type StripePersonRelationshipInput = {
  director: Scalars['Boolean'];
  executive: Scalars['Boolean'];
  owner: Scalars['Boolean'];
  representative: Scalars['Boolean'];
  title: Scalars['String'];
};

export type SumUpPosConfig = {
  __typename?: 'SumUpPosConfig';
  merchantCode?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
};

export type TaxItem = {
  __typename?: 'TaxItem';
  amount: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type TaxItemInput = {
  amount: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type TaxRate = {
  __typename?: 'TaxRate';
  id: Scalars['String'];
  name: Scalars['String'];
  rate: Scalars['Float'];
};

export type Terminal = {
  __typename?: 'Terminal';
  configuration: TerminalConfiguration;
  id: Scalars['String'];
  layouts: Array<TerminalLayout>;
  location: Location;
  name: Scalars['String'];
};

export type TerminalAppearance = {
  __typename?: 'TerminalAppearance';
  colors: TerminalAppearanceColors;
  images: TerminalAppearanceImages;
};

export type TerminalAppearanceColors = {
  __typename?: 'TerminalAppearanceColors';
  accent: Scalars['String'];
  background: Scalars['String'];
  primary: Scalars['String'];
  primaryText: Scalars['String'];
  text: Scalars['String'];
  warningText: Scalars['String'];
};

export type TerminalAppearanceColorsInput = {
  accent: Scalars['String'];
  background: Scalars['String'];
  primary: Scalars['String'];
  primaryText: Scalars['String'];
  text: Scalars['String'];
  warningText: Scalars['String'];
};

export type TerminalAppearanceImages = {
  __typename?: 'TerminalAppearanceImages';
  checkoutBannerUrl?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  screenSaverImageUrls: Array<Scalars['String']>;
};

export type TerminalAppearanceImagesInput = {
  checkoutBannerUrl?: InputMaybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  screenSaverImageUrls: Array<Scalars['String']>;
};

export type TerminalAppearanceInput = {
  colors: TerminalAppearanceColorsInput;
  images: TerminalAppearanceImagesInput;
};

export type TerminalConfiguration = {
  __typename?: 'TerminalConfiguration';
  allowedSumupMerchantIds: Array<Scalars['String']>;
  appearance: TerminalAppearance;
  id: Scalars['String'];
  kdsConfig?: Maybe<TerminalKdsConfig>;
  localisation?: Maybe<TerminalLocalisationConfiguration>;
  name: Scalars['String'];
  operatorMenuPin: Scalars['String'];
  priceBooks?: Maybe<Array<TerminalPriceBook>>;
  receipt?: Maybe<TerminalReceiptConfiguration>;
  showAccessibilityOption: Scalars['Boolean'];
  strings: TerminalStrings;
  upsellPriceBook?: Maybe<TerminalUpsellPriceBook>;
};

export type TerminalConfigurationInput = {
  allowedSumupMerchantIds: Array<Scalars['String']>;
  appearance: TerminalAppearanceInput;
  id?: InputMaybe<Scalars['String']>;
  kdsConfig?: InputMaybe<TerminalKdsConfigInput>;
  localisation?: InputMaybe<TerminalLocalisationConfigurationInput>;
  name: Scalars['String'];
  operatorMenuPin: Scalars['String'];
  priceBooks?: InputMaybe<Array<TerminalPriceBookInput>>;
  receipt?: InputMaybe<TerminalReceiptConfigurationInput>;
  showAccessibilityOption: Scalars['Boolean'];
  strings: TerminalStringsInput;
  upsellPriceBook?: InputMaybe<TerminalUpsellPriceBookInput>;
};

export type TerminalInput = {
  configurationId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TerminalKdsConfig = {
  __typename?: 'TerminalKdsConfig';
  connection: KdsConnection;
  locationId: Scalars['String'];
};

export type TerminalKdsConfigInput = {
  connectionId: Scalars['String'];
  locationId: Scalars['String'];
};

export type TerminalLayout = {
  __typename?: 'TerminalLayout';
  id: Scalars['String'];
  name: Scalars['String'];
  screens: Array<ProductCategory>;
};

export type TerminalLocalisationConfiguration = {
  __typename?: 'TerminalLocalisationConfiguration';
  currency: Currency;
  defaultLocale: Scalars['String'];
};

export type TerminalLocalisationConfigurationInput = {
  currency: Currency;
  defaultLocale: Scalars['String'];
};

export type TerminalPriceBook = {
  __typename?: 'TerminalPriceBook';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TerminalPriceBookInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TerminalReceiptConfiguration = {
  __typename?: 'TerminalReceiptConfiguration';
  additionalReceiptText?: Maybe<Scalars['String']>;
  print: Scalars['Boolean'];
  receiptHeaderText?: Maybe<Scalars['String']>;
  showPrices: Scalars['Boolean'];
};

export type TerminalReceiptConfigurationInput = {
  additionalReceiptText?: InputMaybe<Scalars['String']>;
  print: Scalars['Boolean'];
  receiptHeaderText?: InputMaybe<Scalars['String']>;
  showPrices: Scalars['Boolean'];
};

export type TerminalStrings = {
  __typename?: 'TerminalStrings';
  globalMessageText?: Maybe<Scalars['String']>;
  orderConfirmationText?: Maybe<Scalars['String']>;
};

export type TerminalStringsInput = {
  globalMessageText?: InputMaybe<Scalars['String']>;
  orderConfirmationText?: InputMaybe<Scalars['String']>;
};

export type TerminalUpsellPriceBook = {
  __typename?: 'TerminalUpsellPriceBook';
  modalText?: Maybe<Scalars['String']>;
  priceBookId: Scalars['String'];
};

export type TerminalUpsellPriceBookInput = {
  modalText?: InputMaybe<Scalars['String']>;
  priceBookId: Scalars['String'];
};

export type TickncookKdsConfig = {
  __typename?: 'TickncookKdsConfig';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  availableOn: Scalars['String'];
  createdAt: Scalars['String'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  net?: Maybe<Scalars['Int']>;
  reportingCategory: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
};

export type TransactionList = {
  __typename?: 'TransactionList';
  items?: Maybe<Array<Transaction>>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type TransactionListQuery = {
  availableOnFrom?: InputMaybe<Scalars['String']>;
  availableOnTo?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['String']>;
  createdAtTo?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currency>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ValidationErrorInfo = {
  __typename?: 'ValidationErrorInfo';
  field?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GetPreSignedUrlQueryVariables = Exact<{
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  mimeType: Scalars['String'];
}>;


export type GetPreSignedUrlQuery = { __typename?: 'Query', getPreSignedUrl: string };

export type GetMerchantStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantStatusQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', paymentConfiguration?: { __typename?: 'PaymentConfiguration', enabled: boolean } | undefined } | undefined };

export type GetKdsConnectionListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKdsConnectionListQuery = { __typename?: 'Query', getKdsConnections: Array<{ __typename?: 'KdsConnection', id: string, kdsSystem: KdsSystem, name: string }> };

export type PutKdsConnectionMutationVariables = Exact<{
  kdsConnection: KdsConnectionInput;
}>;


export type PutKdsConnectionMutation = { __typename?: 'Mutation', putKdsConnection: { __typename?: 'KdsConnection', id: string } };

export type PutQrCodeMutationVariables = Exact<{
  id: Scalars['String'];
  posTableReference: Scalars['String'];
  tableName: Scalars['String'];
  locationId: Scalars['String'];
}>;


export type PutQrCodeMutation = { __typename?: 'Mutation', putQrCode: { __typename?: 'QrCode', id: string } };

export type GetMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', locations: Array<{ __typename?: 'Location', id: string, name: string }> } | undefined };

export type GetPosTablesQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetPosTablesQuery = { __typename?: 'Query', getPosTables: Array<{ __typename?: 'PosTable', name: string, posReference: string }> };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', locations: Array<{ __typename?: 'Location', id: string, name: string }> } | undefined };

export type GetLocationQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetLocationQuery = { __typename?: 'Query', getLocation: { __typename?: 'Location', menuUrl?: string | undefined, name: string, posConnection?: { __typename?: 'PosConnection', id: string } | undefined, serviceChargeConfiguration: { __typename?: 'ServiceChargeConfiguration', enabled: boolean, percentageHigh?: number | undefined, percentageLow?: number | undefined, percentageMedium?: number | undefined } } };

export type GetTerminalsQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetTerminalsQuery = { __typename?: 'Query', getLocation: { __typename?: 'Location', terminals: Array<{ __typename?: 'Terminal', id: string, name: string, configuration: { __typename?: 'TerminalConfiguration', id: string, name: string } }> } };

export type GetTerminalTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTerminalTemplateQuery = { __typename?: 'Query', getTerminalConfigurations: Array<{ __typename?: 'TerminalConfiguration', id: string, name: string }> };

export type PutTerminalMutationVariables = Exact<{
  locationId: Scalars['String'];
  terminal: TerminalInput;
}>;


export type PutTerminalMutation = { __typename?: 'Mutation', putTerminal: { __typename?: 'Terminal', id: string } };

export type PutLocationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  menuUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  posConnectionId: Scalars['String'];
  enabled: Scalars['Boolean'];
  percentageHigh?: InputMaybe<Scalars['Float']>;
  percentageLow?: InputMaybe<Scalars['Float']>;
  percentageMedium?: InputMaybe<Scalars['Float']>;
}>;


export type PutLocationMutation = { __typename?: 'Mutation', putLocation: { __typename?: 'Location', id: string } };

export type GetQrCodesQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetQrCodesQuery = { __typename?: 'Query', getLocation: { __typename?: 'Location', qrCodes: Array<{ __typename?: 'QrCode', id: string, tableName: string, posTableReference: string }> } };

export type CreateMerchantMutationVariables = Exact<{
  businessName: Scalars['String'];
  countryCode: Scalars['String'];
  currency: Currency;
}>;


export type CreateMerchantMutation = { __typename?: 'Mutation', createMerchant: { __typename?: 'Merchant', id: string } };

export type MerchantListGetMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type MerchantListGetMerchantQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', businessName: string, id: string } | undefined };

export type MerchantListGetMerchantsQueryVariables = Exact<{
  listQuery?: InputMaybe<MerchantListQuery>;
}>;


export type MerchantListGetMerchantsQuery = { __typename?: 'Query', getMerchants?: { __typename?: 'MerchantList', nextToken?: string | undefined, items?: Array<{ __typename?: 'Merchant', businessName: string, id: string }> | undefined } | undefined };

export type MerchantListSetActiveMerchantMutationVariables = Exact<{
  merchantId: Scalars['String'];
}>;


export type MerchantListSetActiveMerchantMutation = { __typename?: 'Mutation', setActiveMerchant: boolean };

export type SignUpGetPosLocationsQueryVariables = Exact<{
  posConnectionId: Scalars['String'];
}>;


export type SignUpGetPosLocationsQuery = { __typename?: 'Query', getPosLocations: Array<{ __typename?: 'PosLocation', name: string, posReference: string }>, getMerchant?: { __typename?: 'Merchant', locations: Array<{ __typename?: 'Location', id: string, name: string }> } | undefined };

export type SigupPutPosConnectionMutationVariables = Exact<{
  posConnection: PosConnectionInput;
}>;


export type SigupPutPosConnectionMutation = { __typename?: 'Mutation', putPosConnection: { __typename?: 'PosConnection', id: string, config: { __typename?: 'DummyPosConfig' } | { __typename?: 'GoodtillPosConfig', outletId?: string | undefined } | { __typename?: 'SumUpPosConfig' } } };

export type SignUpPutLocationMutationVariables = Exact<{
  location: LocationInput;
}>;


export type SignUpPutLocationMutation = { __typename?: 'Mutation', putLocation: { __typename?: 'Location', id: string } };

export type GetPaymentsQueryVariables = Exact<{
  listQuery?: InputMaybe<PaymentListQuery>;
}>;


export type GetPaymentsQuery = { __typename?: 'Query', getMerchantPayments: { __typename?: 'PaymentList', nextToken?: string | undefined, pageSize?: number | undefined, items?: Array<{ __typename?: 'Payment', amount: number, consumerName?: string | undefined, createdAt: string, currency: Currency, id: string, parties?: number | undefined, paymentMethod?: PaymentMethod | undefined, processorReference?: string | undefined, orderId?: string | undefined, source: Source, cardDetails?: { __typename?: 'PaymentCardDetails', brand: string, last4: string, wallet?: PaymentCardWallet | undefined } | undefined, order?: { __typename?: 'Order', posTableName?: string | undefined } | undefined, location?: { __typename?: 'Location', name: string } | undefined }> | undefined } };

export type GetPaymentQueryVariables = Exact<{
  paymentId: Scalars['String'];
}>;


export type GetPaymentQuery = { __typename?: 'Query', getPayment?: { __typename?: 'Payment', amount: number, consumerName?: string | undefined, currency: Currency, id: string, orderId?: string | undefined, source: Source, createdAt: string, paymentMethod?: PaymentMethod | undefined, order?: { __typename?: 'Order', posTableName?: string | undefined } | undefined, location?: { __typename?: 'Location', id: string, name: string, merchant: { __typename?: 'Merchant', id: string, businessName: string } } | undefined, cardDetails?: { __typename?: 'PaymentCardDetails', brand: string, last4: string, wallet?: PaymentCardWallet | undefined } | undefined, refunds: Array<{ __typename?: 'Payment', amount: number, consumerName?: string | undefined, createdAt: string, currency: Currency, id: string, orderId?: string | undefined, parties?: number | undefined, paymentMethod?: PaymentMethod | undefined, processorReference?: string | undefined, notes?: string | undefined, cardDetails?: { __typename?: 'PaymentCardDetails', brand: string, last4: string, wallet?: PaymentCardWallet | undefined } | undefined }> } | undefined };

export type CreatePaymentRefundMutationVariables = Exact<{
  amount: Scalars['Int'];
  notes: Scalars['String'];
  paymentId: Scalars['String'];
}>;


export type CreatePaymentRefundMutation = { __typename?: 'Mutation', createPaymentRefund: { __typename?: 'Payment', currency: Currency, id: string, parties?: number | undefined, paymentMethod?: PaymentMethod | undefined, amount: number, cardDetails?: { __typename?: 'PaymentCardDetails', brand: string, last4: string, wallet?: PaymentCardWallet | undefined } | undefined } };

export type GetPayoutsQueryVariables = Exact<{
  listQuery?: InputMaybe<PayoutListQuery>;
}>;


export type GetPayoutsQuery = { __typename?: 'Query', getPayouts: { __typename?: 'PayoutList', nextToken?: string | undefined, items?: Array<{ __typename?: 'Payout', amount: number, arrivalAt: string, createdAt: string, currency: Currency, descriptor?: string | undefined, failureMessage?: string | undefined, id: string, status: PayoutStatus, type: string }> | undefined } };

export type GetPayoutQueryVariables = Exact<{
  payoutId: Scalars['String'];
}>;


export type GetPayoutQuery = { __typename?: 'Query', getPayout: { __typename?: 'Payout', status: PayoutStatus, failureMessage?: string | undefined, descriptor?: string | undefined, currency: Currency, createdAt: string, arrivalAt: string, amount: number, type: string } };

export type GetPayoutTransactionsQueryVariables = Exact<{
  payoutId: Scalars['String'];
  listQuery?: InputMaybe<TransactionListQuery>;
}>;


export type GetPayoutTransactionsQuery = { __typename?: 'Query', getPayoutTransactions: { __typename?: 'TransactionList', nextToken?: string | undefined, items?: Array<{ __typename?: 'Transaction', amount: number, createdAt: string, currency: Currency, description?: string | undefined, id: string, status: string }> | undefined } };

export type GetPosConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPosConnectionsQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', posConnections: Array<{ __typename?: 'PosConnection', posSystem: PosSystem, id: string, name: string }> } | undefined };

export type GetPosConnectionQueryVariables = Exact<{
  posConnectionId: Scalars['String'];
}>;


export type GetPosConnectionQuery = { __typename?: 'Query', getPosConnection: { __typename?: 'PosConnection', posSystem: PosSystem, id: string } };

export type PutPosConnectionMutationVariables = Exact<{
  token: Scalars['String'];
  posSystem: PosSystem;
}>;


export type PutPosConnectionMutation = { __typename?: 'Mutation', putPosConnection: { __typename?: 'PosConnection', id: string, name: string } };

export type GetPriceBooksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPriceBooksQuery = { __typename?: 'Query', getPriceBooks: Array<{ __typename?: 'PriceBook', default: boolean, id: string, name: string }> };

export type GetPriceBookQueryVariables = Exact<{
  priceBookId: Scalars['String'];
}>;


export type GetPriceBookQuery = { __typename?: 'Query', getPriceBook: { __typename?: 'PriceBook', id: string, name: string } };

export type PutPriceBookMutationVariables = Exact<{
  priceBook: PriceBookInput;
}>;


export type PutPriceBookMutation = { __typename?: 'Mutation', putPriceBook: { __typename?: 'PriceBook', id: string, name: string } };

export type CreateQrCodeOrderMutationVariables = Exact<{
  businessName: Scalars['String'];
  contactDetails: ContactDetailsInput;
  type: QrCodesType;
}>;


export type CreateQrCodeOrderMutation = { __typename?: 'Mutation', createQrCodesOrder: { __typename?: 'QrCodesOrder', type: QrCodesType } };

export type DeleteQrCodeMutationVariables = Exact<{
  locationId: Scalars['String'];
  qrCodeId: Scalars['String'];
}>;


export type DeleteQrCodeMutation = { __typename?: 'Mutation', deleteQrCode?: boolean | undefined };

export type GetRecentPaymentsQueryVariables = Exact<{
  posLocationId: Scalars['String'];
  posSystem: PosSystem;
}>;


export type GetRecentPaymentsQuery = { __typename?: 'Query', getRecentPaymentsPos: Array<{ __typename?: 'Payment', amount: number, currency: Currency, id: string, createdAt: string, parties?: number | undefined, source: Source, cardDetails?: { __typename?: 'PaymentCardDetails', last4: string, brand: string } | undefined, lineItems?: Array<{ __typename?: 'PaymentLineItem', amount: number, name: string, posId: string, quantity: number }> | undefined, order?: { __typename?: 'Order', posTableName?: string | undefined, id: string, openedAt: string } | undefined }> };

export type GetTerminalConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTerminalConfigurationsQuery = { __typename?: 'Query', getTerminalConfigurations: Array<{ __typename?: 'TerminalConfiguration', id: string, name: string }> };

export type PutTerminalConfigurationMutationVariables = Exact<{
  terminalConfiguration: TerminalConfigurationInput;
}>;


export type PutTerminalConfigurationMutation = { __typename?: 'Mutation', putTerminalConfiguration: { __typename?: 'TerminalConfiguration', id: string } };

export type GetTerminalConfigurationQueryVariables = Exact<{
  terminalConfigurationId: Scalars['String'];
}>;


export type GetTerminalConfigurationQuery = { __typename?: 'Query', getTerminalConfiguration: { __typename?: 'TerminalConfiguration', id: string, name: string, operatorMenuPin: string, allowedSumupMerchantIds: Array<string>, showAccessibilityOption: boolean, appearance: { __typename?: 'TerminalAppearance', colors: { __typename?: 'TerminalAppearanceColors', accent: string, background: string, primary: string, primaryText: string, warningText: string, text: string }, images: { __typename?: 'TerminalAppearanceImages', logoUrl: string, checkoutBannerUrl?: string | undefined, screenSaverImageUrls: Array<string> } }, localisation?: { __typename?: 'TerminalLocalisationConfiguration', currency: Currency, defaultLocale: string } | undefined, priceBooks?: Array<{ __typename?: 'TerminalPriceBook', id: string, name: string }> | undefined, upsellPriceBook?: { __typename?: 'TerminalUpsellPriceBook', modalText?: string | undefined, priceBookId: string } | undefined, receipt?: { __typename?: 'TerminalReceiptConfiguration', additionalReceiptText?: string | undefined, print: boolean, showPrices: boolean, receiptHeaderText?: string | undefined } | undefined, strings: { __typename?: 'TerminalStrings', orderConfirmationText?: string | undefined, globalMessageText?: string | undefined }, kdsConfig?: { __typename?: 'TerminalKdsConfig', locationId: string, connection: { __typename?: 'KdsConnection', id: string, name: string, kdsSystem: KdsSystem } } | undefined } };

export type GetKdsConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKdsConnectionsQuery = { __typename?: 'Query', getKdsConnections: Array<{ __typename?: 'KdsConnection', id: string, name: string, kdsSystem: KdsSystem }> };

export type GetMerchantPayoutAccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantPayoutAccountDetailsQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', paymentConfiguration?: { __typename?: 'PaymentConfiguration', enabled: boolean, payoutBankAccount?: { __typename?: 'BankAccount', accountNumber: string, name: string, routingNumber: string } | undefined } | undefined } | undefined };

export type PutPayoutBankAccountMutationVariables = Exact<{
  payoutBankAccount: BankAccountInput;
}>;


export type PutPayoutBankAccountMutation = { __typename?: 'Mutation', putPayoutBankAccount?: { __typename?: 'BankAccount', name: string } | undefined };

export type GetMerchantPayoutPaymentDescriptorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantPayoutPaymentDescriptorQuery = { __typename?: 'Query', getMerchant?: { __typename?: 'Merchant', paymentConfiguration?: { __typename?: 'PaymentConfiguration', statementDescriptor?: string | undefined, enabled: boolean } | undefined } | undefined };

export type PutStatementDescriptorMutationVariables = Exact<{
  statementDescriptor: Scalars['String'];
}>;


export type PutStatementDescriptorMutation = { __typename?: 'Mutation', putStatementDescriptor: string };

export type GetKycQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKycQuery = { __typename?: 'Query', getKyc?: { __typename?: 'StripeKyc', businessType?: StripeBusinessType | undefined, businessProfile?: { __typename?: 'StripeBusinessProfile', name?: string | undefined, url?: string | undefined } | undefined, company?: { __typename?: 'StripeCompany', name?: string | undefined, phone?: string | undefined, taxId?: string | undefined, address?: { __typename?: 'StripeAddress', city: string, country: string, line1: string, line2?: string | undefined, postalCode: string } | undefined, people: Array<{ __typename?: 'StripePerson', dateOfBirth: string, email: string, firstName: string, lastName: string, phone: string, address: { __typename?: 'StripeAddress', city: string, country: string, line1: string, postalCode: string, line2?: string | undefined }, relationship?: { __typename?: 'StripePersonRelationship', director: boolean, executive: boolean, owner: boolean, representative: boolean, title: string } | undefined }> } | undefined, individual?: { __typename?: 'StripePerson', dateOfBirth: string, email: string, firstName: string, lastName: string, phone: string, address: { __typename?: 'StripeAddress', city: string, country: string, line1: string, line2?: string | undefined, postalCode: string }, relationship?: { __typename?: 'StripePersonRelationship', director: boolean, executive: boolean, owner: boolean, representative: boolean, title: string } | undefined } | undefined, payoutBankAccount?: { __typename?: 'StripeBankAccount', accountNumber: string, name: string, routingNumber: string } | undefined } | undefined };

export type PutKycMutationVariables = Exact<{
  businessProfile?: InputMaybe<StripeBusinessProfileInput>;
  businessType?: InputMaybe<StripeBusinessType>;
  payoutBankAccount?: InputMaybe<StripeBankAccountInput>;
  company?: InputMaybe<StripeCompanyInput>;
  individual?: InputMaybe<StripePersonInput>;
}>;


export type PutKycMutation = { __typename?: 'Mutation', putKyc: { __typename?: 'StripeKyc', businessType?: StripeBusinessType | undefined, businessProfile?: { __typename?: 'StripeBusinessProfile', name?: string | undefined, url?: string | undefined } | undefined, company?: { __typename?: 'StripeCompany', name?: string | undefined, phone?: string | undefined, taxId?: string | undefined, address?: { __typename?: 'StripeAddress', city: string, country: string, line1: string, line2?: string | undefined, postalCode: string } | undefined, people: Array<{ __typename?: 'StripePerson', dateOfBirth: string, email: string, firstName: string, lastName: string, phone: string, address: { __typename?: 'StripeAddress', city: string, line1: string, country: string, line2?: string | undefined, postalCode: string }, relationship?: { __typename?: 'StripePersonRelationship', director: boolean, executive: boolean, owner: boolean, representative: boolean, title: string } | undefined }> } | undefined, individual?: { __typename?: 'StripePerson', dateOfBirth: string, email: string, firstName: string, lastName: string, phone: string, address: { __typename?: 'StripeAddress', city: string, country: string, line1: string, line2?: string | undefined, postalCode: string }, relationship?: { __typename?: 'StripePersonRelationship', director: boolean, executive: boolean, owner: boolean, representative: boolean, title: string } | undefined } | undefined, payoutBankAccount?: { __typename?: 'StripeBankAccount', accountNumber: string, name: string, routingNumber: string } | undefined } };

export type SubmitKycMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitKycMutation = { __typename?: 'Mutation', submitKyc?: boolean | undefined };


export const GetPreSignedUrlDocument = `
    query GetPreSignedUrl($fileName: String!, $fileSize: Int!, $mimeType: String!) {
  getPreSignedUrl(fileName: $fileName, fileSize: $fileSize, mimeType: $mimeType)
}
    `;
export const useGetPreSignedUrlQuery = <
      TData = GetPreSignedUrlQuery,
      TError = OapError[]
    >(
      variables: GetPreSignedUrlQueryVariables,
      options?: UseQueryOptions<GetPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<GetPreSignedUrlQuery, TError, TData>(
      ['GetPreSignedUrl', variables],
      oapFetcher<GetPreSignedUrlQuery, GetPreSignedUrlQueryVariables>(GetPreSignedUrlDocument, variables),
      options
    );
export const GetMerchantStatusDocument = `
    query GetMerchantStatus {
  getMerchant {
    paymentConfiguration {
      enabled
    }
  }
}
    `;
export const useGetMerchantStatusQuery = <
      TData = GetMerchantStatusQuery,
      TError = OapError[]
    >(
      variables?: GetMerchantStatusQueryVariables,
      options?: UseQueryOptions<GetMerchantStatusQuery, TError, TData>
    ) =>
    useQuery<GetMerchantStatusQuery, TError, TData>(
      variables === undefined ? ['GetMerchantStatus'] : ['GetMerchantStatus', variables],
      oapFetcher<GetMerchantStatusQuery, GetMerchantStatusQueryVariables>(GetMerchantStatusDocument, variables),
      options
    );
export const GetKdsConnectionListDocument = `
    query GetKdsConnectionList {
  getKdsConnections {
    id
    kdsSystem
    name
  }
}
    `;
export const useGetKdsConnectionListQuery = <
      TData = GetKdsConnectionListQuery,
      TError = OapError[]
    >(
      variables?: GetKdsConnectionListQueryVariables,
      options?: UseQueryOptions<GetKdsConnectionListQuery, TError, TData>
    ) =>
    useQuery<GetKdsConnectionListQuery, TError, TData>(
      variables === undefined ? ['GetKdsConnectionList'] : ['GetKdsConnectionList', variables],
      oapFetcher<GetKdsConnectionListQuery, GetKdsConnectionListQueryVariables>(GetKdsConnectionListDocument, variables),
      options
    );
export const PutKdsConnectionDocument = `
    mutation PutKdsConnection($kdsConnection: KdsConnectionInput!) {
  putKdsConnection(kdsConnection: $kdsConnection) {
    id
  }
}
    `;
export const usePutKdsConnectionMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutKdsConnectionMutation, TError, PutKdsConnectionMutationVariables, TContext>) =>
    useMutation<PutKdsConnectionMutation, TError, PutKdsConnectionMutationVariables, TContext>(
      ['PutKdsConnection'],
      (variables?: PutKdsConnectionMutationVariables) => oapFetcher<PutKdsConnectionMutation, PutKdsConnectionMutationVariables>(PutKdsConnectionDocument, variables)(),
      options
    );
export const PutQrCodeDocument = `
    mutation PutQrCode($id: String!, $posTableReference: String!, $tableName: String!, $locationId: String!) {
  putQrCode(
    locationId: $locationId
    qrCode: {posTableReference: $posTableReference, tableName: $tableName, id: $id}
  ) {
    id
  }
}
    `;
export const usePutQrCodeMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutQrCodeMutation, TError, PutQrCodeMutationVariables, TContext>) =>
    useMutation<PutQrCodeMutation, TError, PutQrCodeMutationVariables, TContext>(
      ['PutQrCode'],
      (variables?: PutQrCodeMutationVariables) => oapFetcher<PutQrCodeMutation, PutQrCodeMutationVariables>(PutQrCodeDocument, variables)(),
      options
    );
export const GetMerchantDocument = `
    query GetMerchant {
  getMerchant {
    locations {
      id
      name
    }
  }
}
    `;
export const useGetMerchantQuery = <
      TData = GetMerchantQuery,
      TError = OapError[]
    >(
      variables?: GetMerchantQueryVariables,
      options?: UseQueryOptions<GetMerchantQuery, TError, TData>
    ) =>
    useQuery<GetMerchantQuery, TError, TData>(
      variables === undefined ? ['GetMerchant'] : ['GetMerchant', variables],
      oapFetcher<GetMerchantQuery, GetMerchantQueryVariables>(GetMerchantDocument, variables),
      options
    );
export const GetPosTablesDocument = `
    query GetPosTables($locationId: String!) {
  getPosTables(locationId: $locationId) {
    name
    posReference
  }
}
    `;
export const useGetPosTablesQuery = <
      TData = GetPosTablesQuery,
      TError = OapError[]
    >(
      variables: GetPosTablesQueryVariables,
      options?: UseQueryOptions<GetPosTablesQuery, TError, TData>
    ) =>
    useQuery<GetPosTablesQuery, TError, TData>(
      ['GetPosTables', variables],
      oapFetcher<GetPosTablesQuery, GetPosTablesQueryVariables>(GetPosTablesDocument, variables),
      options
    );
export const GetLocationsDocument = `
    query GetLocations {
  getMerchant {
    locations {
      id
      name
    }
  }
}
    `;
export const useGetLocationsQuery = <
      TData = GetLocationsQuery,
      TError = OapError[]
    >(
      variables?: GetLocationsQueryVariables,
      options?: UseQueryOptions<GetLocationsQuery, TError, TData>
    ) =>
    useQuery<GetLocationsQuery, TError, TData>(
      variables === undefined ? ['GetLocations'] : ['GetLocations', variables],
      oapFetcher<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, variables),
      options
    );
export const GetLocationDocument = `
    query GetLocation($locationId: String!) {
  getLocation(locationId: $locationId) {
    menuUrl
    name
    posConnection {
      id
    }
    serviceChargeConfiguration {
      enabled
      percentageHigh
      percentageLow
      percentageMedium
    }
  }
}
    `;
export const useGetLocationQuery = <
      TData = GetLocationQuery,
      TError = OapError[]
    >(
      variables: GetLocationQueryVariables,
      options?: UseQueryOptions<GetLocationQuery, TError, TData>
    ) =>
    useQuery<GetLocationQuery, TError, TData>(
      ['GetLocation', variables],
      oapFetcher<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, variables),
      options
    );
export const GetTerminalsDocument = `
    query GetTerminals($locationId: String!) {
  getLocation(locationId: $locationId) {
    terminals {
      id
      name
      configuration {
        id
        name
      }
    }
  }
}
    `;
export const useGetTerminalsQuery = <
      TData = GetTerminalsQuery,
      TError = OapError[]
    >(
      variables: GetTerminalsQueryVariables,
      options?: UseQueryOptions<GetTerminalsQuery, TError, TData>
    ) =>
    useQuery<GetTerminalsQuery, TError, TData>(
      ['GetTerminals', variables],
      oapFetcher<GetTerminalsQuery, GetTerminalsQueryVariables>(GetTerminalsDocument, variables),
      options
    );
export const GetTerminalTemplateDocument = `
    query GetTerminalTemplate {
  getTerminalConfigurations {
    id
    name
  }
}
    `;
export const useGetTerminalTemplateQuery = <
      TData = GetTerminalTemplateQuery,
      TError = OapError[]
    >(
      variables?: GetTerminalTemplateQueryVariables,
      options?: UseQueryOptions<GetTerminalTemplateQuery, TError, TData>
    ) =>
    useQuery<GetTerminalTemplateQuery, TError, TData>(
      variables === undefined ? ['GetTerminalTemplate'] : ['GetTerminalTemplate', variables],
      oapFetcher<GetTerminalTemplateQuery, GetTerminalTemplateQueryVariables>(GetTerminalTemplateDocument, variables),
      options
    );
export const PutTerminalDocument = `
    mutation PutTerminal($locationId: String!, $terminal: TerminalInput!) {
  putTerminal(locationId: $locationId, terminal: $terminal) {
    id
  }
}
    `;
export const usePutTerminalMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutTerminalMutation, TError, PutTerminalMutationVariables, TContext>) =>
    useMutation<PutTerminalMutation, TError, PutTerminalMutationVariables, TContext>(
      ['PutTerminal'],
      (variables?: PutTerminalMutationVariables) => oapFetcher<PutTerminalMutation, PutTerminalMutationVariables>(PutTerminalDocument, variables)(),
      options
    );
export const PutLocationDocument = `
    mutation PutLocation($id: String, $menuUrl: String, $name: String!, $posConnectionId: String!, $enabled: Boolean!, $percentageHigh: Float, $percentageLow: Float, $percentageMedium: Float) {
  putLocation(
    location: {id: $id, posConnectionId: $posConnectionId, serviceChargeConfiguration: {enabled: $enabled, percentageMedium: $percentageMedium, percentageLow: $percentageLow, percentageHigh: $percentageHigh}, menuUrl: $menuUrl, name: $name}
  ) {
    id
  }
}
    `;
export const usePutLocationMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutLocationMutation, TError, PutLocationMutationVariables, TContext>) =>
    useMutation<PutLocationMutation, TError, PutLocationMutationVariables, TContext>(
      ['PutLocation'],
      (variables?: PutLocationMutationVariables) => oapFetcher<PutLocationMutation, PutLocationMutationVariables>(PutLocationDocument, variables)(),
      options
    );
export const GetQrCodesDocument = `
    query GetQrCodes($locationId: String!) {
  getLocation(locationId: $locationId) {
    qrCodes {
      id
      tableName
      posTableReference
    }
  }
}
    `;
export const useGetQrCodesQuery = <
      TData = GetQrCodesQuery,
      TError = OapError[]
    >(
      variables: GetQrCodesQueryVariables,
      options?: UseQueryOptions<GetQrCodesQuery, TError, TData>
    ) =>
    useQuery<GetQrCodesQuery, TError, TData>(
      ['GetQrCodes', variables],
      oapFetcher<GetQrCodesQuery, GetQrCodesQueryVariables>(GetQrCodesDocument, variables),
      options
    );
export const CreateMerchantDocument = `
    mutation CreateMerchant($businessName: String!, $countryCode: String!, $currency: Currency!) {
  createMerchant(
    merchant: {businessName: $businessName, countryCode: $countryCode, currency: $currency}
  ) {
    id
  }
}
    `;
export const useCreateMerchantMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<CreateMerchantMutation, TError, CreateMerchantMutationVariables, TContext>) =>
    useMutation<CreateMerchantMutation, TError, CreateMerchantMutationVariables, TContext>(
      ['CreateMerchant'],
      (variables?: CreateMerchantMutationVariables) => oapFetcher<CreateMerchantMutation, CreateMerchantMutationVariables>(CreateMerchantDocument, variables)(),
      options
    );
export const MerchantListGetMerchantDocument = `
    query MerchantListGetMerchant {
  getMerchant {
    businessName
    id
  }
}
    `;
export const useMerchantListGetMerchantQuery = <
      TData = MerchantListGetMerchantQuery,
      TError = OapError[]
    >(
      variables?: MerchantListGetMerchantQueryVariables,
      options?: UseQueryOptions<MerchantListGetMerchantQuery, TError, TData>
    ) =>
    useQuery<MerchantListGetMerchantQuery, TError, TData>(
      variables === undefined ? ['MerchantListGetMerchant'] : ['MerchantListGetMerchant', variables],
      oapFetcher<MerchantListGetMerchantQuery, MerchantListGetMerchantQueryVariables>(MerchantListGetMerchantDocument, variables),
      options
    );
export const MerchantListGetMerchantsDocument = `
    query MerchantListGetMerchants($listQuery: MerchantListQuery) {
  getMerchants(listQuery: $listQuery) {
    items {
      businessName
      id
    }
    nextToken
  }
}
    `;
export const useMerchantListGetMerchantsQuery = <
      TData = MerchantListGetMerchantsQuery,
      TError = OapError[]
    >(
      variables?: MerchantListGetMerchantsQueryVariables,
      options?: UseQueryOptions<MerchantListGetMerchantsQuery, TError, TData>
    ) =>
    useQuery<MerchantListGetMerchantsQuery, TError, TData>(
      variables === undefined ? ['MerchantListGetMerchants'] : ['MerchantListGetMerchants', variables],
      oapFetcher<MerchantListGetMerchantsQuery, MerchantListGetMerchantsQueryVariables>(MerchantListGetMerchantsDocument, variables),
      options
    );
export const MerchantListSetActiveMerchantDocument = `
    mutation MerchantListSetActiveMerchant($merchantId: String!) {
  setActiveMerchant(merchantId: $merchantId)
}
    `;
export const useMerchantListSetActiveMerchantMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<MerchantListSetActiveMerchantMutation, TError, MerchantListSetActiveMerchantMutationVariables, TContext>) =>
    useMutation<MerchantListSetActiveMerchantMutation, TError, MerchantListSetActiveMerchantMutationVariables, TContext>(
      ['MerchantListSetActiveMerchant'],
      (variables?: MerchantListSetActiveMerchantMutationVariables) => oapFetcher<MerchantListSetActiveMerchantMutation, MerchantListSetActiveMerchantMutationVariables>(MerchantListSetActiveMerchantDocument, variables)(),
      options
    );
export const SignUpGetPosLocationsDocument = `
    query SignUpGetPosLocations($posConnectionId: String!) {
  getPosLocations(posConnectionId: $posConnectionId) {
    name
    posReference
  }
  getMerchant {
    locations {
      id
      name
    }
  }
}
    `;
export const useSignUpGetPosLocationsQuery = <
      TData = SignUpGetPosLocationsQuery,
      TError = OapError[]
    >(
      variables: SignUpGetPosLocationsQueryVariables,
      options?: UseQueryOptions<SignUpGetPosLocationsQuery, TError, TData>
    ) =>
    useQuery<SignUpGetPosLocationsQuery, TError, TData>(
      ['SignUpGetPosLocations', variables],
      oapFetcher<SignUpGetPosLocationsQuery, SignUpGetPosLocationsQueryVariables>(SignUpGetPosLocationsDocument, variables),
      options
    );
export const SigupPutPosConnectionDocument = `
    mutation SigupPutPosConnection($posConnection: PosConnectionInput!) {
  putPosConnection(posConnection: $posConnection) {
    config {
      ... on GoodtillPosConfig {
        outletId
      }
    }
    id
  }
}
    `;
export const useSigupPutPosConnectionMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<SigupPutPosConnectionMutation, TError, SigupPutPosConnectionMutationVariables, TContext>) =>
    useMutation<SigupPutPosConnectionMutation, TError, SigupPutPosConnectionMutationVariables, TContext>(
      ['SigupPutPosConnection'],
      (variables?: SigupPutPosConnectionMutationVariables) => oapFetcher<SigupPutPosConnectionMutation, SigupPutPosConnectionMutationVariables>(SigupPutPosConnectionDocument, variables)(),
      options
    );
export const SignUpPutLocationDocument = `
    mutation SignUpPutLocation($location: LocationInput!) {
  putLocation(location: $location) {
    id
  }
}
    `;
export const useSignUpPutLocationMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<SignUpPutLocationMutation, TError, SignUpPutLocationMutationVariables, TContext>) =>
    useMutation<SignUpPutLocationMutation, TError, SignUpPutLocationMutationVariables, TContext>(
      ['SignUpPutLocation'],
      (variables?: SignUpPutLocationMutationVariables) => oapFetcher<SignUpPutLocationMutation, SignUpPutLocationMutationVariables>(SignUpPutLocationDocument, variables)(),
      options
    );
export const GetPaymentsDocument = `
    query GetPayments($listQuery: PaymentListQuery) {
  getMerchantPayments(listQuery: $listQuery) {
    items {
      amount
      cardDetails {
        brand
        last4
        wallet
      }
      consumerName
      createdAt
      currency
      id
      parties
      paymentMethod
      processorReference
      orderId
      order {
        posTableName
      }
      location {
        name
      }
      source
    }
    nextToken
    pageSize
  }
}
    `;
export const useGetPaymentsQuery = <
      TData = GetPaymentsQuery,
      TError = OapError[]
    >(
      variables?: GetPaymentsQueryVariables,
      options?: UseQueryOptions<GetPaymentsQuery, TError, TData>
    ) =>
    useQuery<GetPaymentsQuery, TError, TData>(
      variables === undefined ? ['GetPayments'] : ['GetPayments', variables],
      oapFetcher<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, variables),
      options
    );
export const GetPaymentDocument = `
    query GetPayment($paymentId: String!) {
  getPayment(id: $paymentId) {
    order {
      posTableName
    }
    location {
      id
      name
      merchant {
        id
        businessName
      }
    }
    amount
    cardDetails {
      brand
      last4
      wallet
    }
    consumerName
    currency
    id
    orderId
    source
    refunds {
      amount
      cardDetails {
        brand
        last4
        wallet
      }
      consumerName
      createdAt
      currency
      id
      orderId
      parties
      paymentMethod
      processorReference
      notes
    }
    createdAt
    paymentMethod
    refunds {
      amount
      notes
      createdAt
    }
  }
}
    `;
export const useGetPaymentQuery = <
      TData = GetPaymentQuery,
      TError = OapError[]
    >(
      variables: GetPaymentQueryVariables,
      options?: UseQueryOptions<GetPaymentQuery, TError, TData>
    ) =>
    useQuery<GetPaymentQuery, TError, TData>(
      ['GetPayment', variables],
      oapFetcher<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, variables),
      options
    );
export const CreatePaymentRefundDocument = `
    mutation CreatePaymentRefund($amount: Int!, $notes: String!, $paymentId: String!) {
  createPaymentRefund(
    refund: {amount: $amount, notes: $notes, paymentId: $paymentId}
  ) {
    currency
    id
    cardDetails {
      brand
      last4
      wallet
    }
    parties
    paymentMethod
    amount
  }
}
    `;
export const useCreatePaymentRefundMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<CreatePaymentRefundMutation, TError, CreatePaymentRefundMutationVariables, TContext>) =>
    useMutation<CreatePaymentRefundMutation, TError, CreatePaymentRefundMutationVariables, TContext>(
      ['CreatePaymentRefund'],
      (variables?: CreatePaymentRefundMutationVariables) => oapFetcher<CreatePaymentRefundMutation, CreatePaymentRefundMutationVariables>(CreatePaymentRefundDocument, variables)(),
      options
    );
export const GetPayoutsDocument = `
    query GetPayouts($listQuery: PayoutListQuery) {
  getPayouts(listQuery: $listQuery) {
    nextToken
    items {
      amount
      arrivalAt
      createdAt
      currency
      descriptor
      failureMessage
      id
      status
      type
    }
  }
}
    `;
export const useGetPayoutsQuery = <
      TData = GetPayoutsQuery,
      TError = OapError[]
    >(
      variables?: GetPayoutsQueryVariables,
      options?: UseQueryOptions<GetPayoutsQuery, TError, TData>
    ) =>
    useQuery<GetPayoutsQuery, TError, TData>(
      variables === undefined ? ['GetPayouts'] : ['GetPayouts', variables],
      oapFetcher<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, variables),
      options
    );
export const GetPayoutDocument = `
    query GetPayout($payoutId: String!) {
  getPayout(payoutId: $payoutId) {
    status
    failureMessage
    descriptor
    currency
    createdAt
    arrivalAt
    amount
    type
  }
}
    `;
export const useGetPayoutQuery = <
      TData = GetPayoutQuery,
      TError = OapError[]
    >(
      variables: GetPayoutQueryVariables,
      options?: UseQueryOptions<GetPayoutQuery, TError, TData>
    ) =>
    useQuery<GetPayoutQuery, TError, TData>(
      ['GetPayout', variables],
      oapFetcher<GetPayoutQuery, GetPayoutQueryVariables>(GetPayoutDocument, variables),
      options
    );
export const GetPayoutTransactionsDocument = `
    query GetPayoutTransactions($payoutId: String!, $listQuery: TransactionListQuery) {
  getPayoutTransactions(payoutId: $payoutId, listQuery: $listQuery) {
    items {
      amount
      createdAt
      currency
      description
      id
      status
    }
    nextToken
  }
}
    `;
export const useGetPayoutTransactionsQuery = <
      TData = GetPayoutTransactionsQuery,
      TError = OapError[]
    >(
      variables: GetPayoutTransactionsQueryVariables,
      options?: UseQueryOptions<GetPayoutTransactionsQuery, TError, TData>
    ) =>
    useQuery<GetPayoutTransactionsQuery, TError, TData>(
      ['GetPayoutTransactions', variables],
      oapFetcher<GetPayoutTransactionsQuery, GetPayoutTransactionsQueryVariables>(GetPayoutTransactionsDocument, variables),
      options
    );
export const GetPosConnectionsDocument = `
    query GetPosConnections {
  getMerchant {
    posConnections {
      posSystem
      id
      name
    }
  }
}
    `;
export const useGetPosConnectionsQuery = <
      TData = GetPosConnectionsQuery,
      TError = OapError[]
    >(
      variables?: GetPosConnectionsQueryVariables,
      options?: UseQueryOptions<GetPosConnectionsQuery, TError, TData>
    ) =>
    useQuery<GetPosConnectionsQuery, TError, TData>(
      variables === undefined ? ['GetPosConnections'] : ['GetPosConnections', variables],
      oapFetcher<GetPosConnectionsQuery, GetPosConnectionsQueryVariables>(GetPosConnectionsDocument, variables),
      options
    );
export const GetPosConnectionDocument = `
    query GetPosConnection($posConnectionId: String!) {
  getPosConnection(posConnectionId: $posConnectionId) {
    posSystem
    id
  }
}
    `;
export const useGetPosConnectionQuery = <
      TData = GetPosConnectionQuery,
      TError = OapError[]
    >(
      variables: GetPosConnectionQueryVariables,
      options?: UseQueryOptions<GetPosConnectionQuery, TError, TData>
    ) =>
    useQuery<GetPosConnectionQuery, TError, TData>(
      ['GetPosConnection', variables],
      oapFetcher<GetPosConnectionQuery, GetPosConnectionQueryVariables>(GetPosConnectionDocument, variables),
      options
    );
export const PutPosConnectionDocument = `
    mutation PutPosConnection($token: String!, $posSystem: PosSystem!) {
  putPosConnection(
    posConnection: {config: {token: $token}, posSystem: $posSystem}
  ) {
    id
    name
  }
}
    `;
export const usePutPosConnectionMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutPosConnectionMutation, TError, PutPosConnectionMutationVariables, TContext>) =>
    useMutation<PutPosConnectionMutation, TError, PutPosConnectionMutationVariables, TContext>(
      ['PutPosConnection'],
      (variables?: PutPosConnectionMutationVariables) => oapFetcher<PutPosConnectionMutation, PutPosConnectionMutationVariables>(PutPosConnectionDocument, variables)(),
      options
    );
export const GetPriceBooksDocument = `
    query GetPriceBooks {
  getPriceBooks {
    default
    id
    name
  }
}
    `;
export const useGetPriceBooksQuery = <
      TData = GetPriceBooksQuery,
      TError = OapError[]
    >(
      variables?: GetPriceBooksQueryVariables,
      options?: UseQueryOptions<GetPriceBooksQuery, TError, TData>
    ) =>
    useQuery<GetPriceBooksQuery, TError, TData>(
      variables === undefined ? ['GetPriceBooks'] : ['GetPriceBooks', variables],
      oapFetcher<GetPriceBooksQuery, GetPriceBooksQueryVariables>(GetPriceBooksDocument, variables),
      options
    );
export const GetPriceBookDocument = `
    query GetPriceBook($priceBookId: String!) {
  getPriceBook(priceBookId: $priceBookId) {
    id
    name
  }
}
    `;
export const useGetPriceBookQuery = <
      TData = GetPriceBookQuery,
      TError = OapError[]
    >(
      variables: GetPriceBookQueryVariables,
      options?: UseQueryOptions<GetPriceBookQuery, TError, TData>
    ) =>
    useQuery<GetPriceBookQuery, TError, TData>(
      ['GetPriceBook', variables],
      oapFetcher<GetPriceBookQuery, GetPriceBookQueryVariables>(GetPriceBookDocument, variables),
      options
    );
export const PutPriceBookDocument = `
    mutation PutPriceBook($priceBook: PriceBookInput!) {
  putPriceBook(priceBook: $priceBook) {
    id
    name
  }
}
    `;
export const usePutPriceBookMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutPriceBookMutation, TError, PutPriceBookMutationVariables, TContext>) =>
    useMutation<PutPriceBookMutation, TError, PutPriceBookMutationVariables, TContext>(
      ['PutPriceBook'],
      (variables?: PutPriceBookMutationVariables) => oapFetcher<PutPriceBookMutation, PutPriceBookMutationVariables>(PutPriceBookDocument, variables)(),
      options
    );
export const CreateQrCodeOrderDocument = `
    mutation CreateQrCodeOrder($businessName: String!, $contactDetails: ContactDetailsInput!, $type: QrCodesType!) {
  createQrCodesOrder(
    contactDetails: $contactDetails
    type: $type
    businessName: $businessName
  ) {
    type
  }
}
    `;
export const useCreateQrCodeOrderMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<CreateQrCodeOrderMutation, TError, CreateQrCodeOrderMutationVariables, TContext>) =>
    useMutation<CreateQrCodeOrderMutation, TError, CreateQrCodeOrderMutationVariables, TContext>(
      ['CreateQrCodeOrder'],
      (variables?: CreateQrCodeOrderMutationVariables) => oapFetcher<CreateQrCodeOrderMutation, CreateQrCodeOrderMutationVariables>(CreateQrCodeOrderDocument, variables)(),
      options
    );
export const DeleteQrCodeDocument = `
    mutation DeleteQrCode($locationId: String!, $qrCodeId: String!) {
  deleteQrCode(locationId: $locationId, qrCodeId: $qrCodeId)
}
    `;
export const useDeleteQrCodeMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<DeleteQrCodeMutation, TError, DeleteQrCodeMutationVariables, TContext>) =>
    useMutation<DeleteQrCodeMutation, TError, DeleteQrCodeMutationVariables, TContext>(
      ['DeleteQrCode'],
      (variables?: DeleteQrCodeMutationVariables) => oapFetcher<DeleteQrCodeMutation, DeleteQrCodeMutationVariables>(DeleteQrCodeDocument, variables)(),
      options
    );
export const GetRecentPaymentsDocument = `
    query GetRecentPayments($posLocationId: String!, $posSystem: PosSystem!) {
  getRecentPaymentsPos(posLocationId: $posLocationId, posSystem: $posSystem) {
    amount
    currency
    id
    cardDetails {
      last4
      brand
    }
    createdAt
    lineItems {
      amount
      name
      posId
      quantity
    }
    parties
    order {
      posTableName
      id
      openedAt
    }
    source
  }
}
    `;
export const useGetRecentPaymentsQuery = <
      TData = GetRecentPaymentsQuery,
      TError = OapError[]
    >(
      variables: GetRecentPaymentsQueryVariables,
      options?: UseQueryOptions<GetRecentPaymentsQuery, TError, TData>
    ) =>
    useQuery<GetRecentPaymentsQuery, TError, TData>(
      ['GetRecentPayments', variables],
      oapFetcher<GetRecentPaymentsQuery, GetRecentPaymentsQueryVariables>(GetRecentPaymentsDocument, variables),
      options
    );
export const GetTerminalConfigurationsDocument = `
    query GetTerminalConfigurations {
  getTerminalConfigurations {
    id
    name
  }
}
    `;
export const useGetTerminalConfigurationsQuery = <
      TData = GetTerminalConfigurationsQuery,
      TError = OapError[]
    >(
      variables?: GetTerminalConfigurationsQueryVariables,
      options?: UseQueryOptions<GetTerminalConfigurationsQuery, TError, TData>
    ) =>
    useQuery<GetTerminalConfigurationsQuery, TError, TData>(
      variables === undefined ? ['GetTerminalConfigurations'] : ['GetTerminalConfigurations', variables],
      oapFetcher<GetTerminalConfigurationsQuery, GetTerminalConfigurationsQueryVariables>(GetTerminalConfigurationsDocument, variables),
      options
    );
export const PutTerminalConfigurationDocument = `
    mutation PutTerminalConfiguration($terminalConfiguration: TerminalConfigurationInput!) {
  putTerminalConfiguration(terminalConfiguration: $terminalConfiguration) {
    id
  }
}
    `;
export const usePutTerminalConfigurationMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutTerminalConfigurationMutation, TError, PutTerminalConfigurationMutationVariables, TContext>) =>
    useMutation<PutTerminalConfigurationMutation, TError, PutTerminalConfigurationMutationVariables, TContext>(
      ['PutTerminalConfiguration'],
      (variables?: PutTerminalConfigurationMutationVariables) => oapFetcher<PutTerminalConfigurationMutation, PutTerminalConfigurationMutationVariables>(PutTerminalConfigurationDocument, variables)(),
      options
    );
export const GetTerminalConfigurationDocument = `
    query GetTerminalConfiguration($terminalConfigurationId: String!) {
  getTerminalConfiguration(terminalConfigurationId: $terminalConfigurationId) {
    appearance {
      colors {
        accent
        background
        primary
        primaryText
        warningText
        text
      }
      images {
        logoUrl
        checkoutBannerUrl
        screenSaverImageUrls
      }
    }
    id
    localisation {
      currency
      defaultLocale
    }
    name
    operatorMenuPin
    allowedSumupMerchantIds
    priceBooks {
      id
      name
    }
    upsellPriceBook {
      modalText
      priceBookId
    }
    receipt {
      additionalReceiptText
      print
      showPrices
      receiptHeaderText
    }
    showAccessibilityOption
    strings {
      orderConfirmationText
      globalMessageText
    }
    kdsConfig {
      connection {
        id
        name
        kdsSystem
      }
      locationId
    }
  }
}
    `;
export const useGetTerminalConfigurationQuery = <
      TData = GetTerminalConfigurationQuery,
      TError = OapError[]
    >(
      variables: GetTerminalConfigurationQueryVariables,
      options?: UseQueryOptions<GetTerminalConfigurationQuery, TError, TData>
    ) =>
    useQuery<GetTerminalConfigurationQuery, TError, TData>(
      ['GetTerminalConfiguration', variables],
      oapFetcher<GetTerminalConfigurationQuery, GetTerminalConfigurationQueryVariables>(GetTerminalConfigurationDocument, variables),
      options
    );
export const GetKdsConnectionsDocument = `
    query GetKdsConnections {
  getKdsConnections {
    id
    name
    kdsSystem
  }
}
    `;
export const useGetKdsConnectionsQuery = <
      TData = GetKdsConnectionsQuery,
      TError = OapError[]
    >(
      variables?: GetKdsConnectionsQueryVariables,
      options?: UseQueryOptions<GetKdsConnectionsQuery, TError, TData>
    ) =>
    useQuery<GetKdsConnectionsQuery, TError, TData>(
      variables === undefined ? ['GetKdsConnections'] : ['GetKdsConnections', variables],
      oapFetcher<GetKdsConnectionsQuery, GetKdsConnectionsQueryVariables>(GetKdsConnectionsDocument, variables),
      options
    );
export const GetMerchantPayoutAccountDetailsDocument = `
    query getMerchantPayoutAccountDetails {
  getMerchant {
    paymentConfiguration {
      payoutBankAccount {
        accountNumber
        name
        routingNumber
      }
      enabled
    }
  }
}
    `;
export const useGetMerchantPayoutAccountDetailsQuery = <
      TData = GetMerchantPayoutAccountDetailsQuery,
      TError = OapError[]
    >(
      variables?: GetMerchantPayoutAccountDetailsQueryVariables,
      options?: UseQueryOptions<GetMerchantPayoutAccountDetailsQuery, TError, TData>
    ) =>
    useQuery<GetMerchantPayoutAccountDetailsQuery, TError, TData>(
      variables === undefined ? ['getMerchantPayoutAccountDetails'] : ['getMerchantPayoutAccountDetails', variables],
      oapFetcher<GetMerchantPayoutAccountDetailsQuery, GetMerchantPayoutAccountDetailsQueryVariables>(GetMerchantPayoutAccountDetailsDocument, variables),
      options
    );
export const PutPayoutBankAccountDocument = `
    mutation PutPayoutBankAccount($payoutBankAccount: BankAccountInput!) {
  putPayoutBankAccount(bankAccount: $payoutBankAccount) {
    name
  }
}
    `;
export const usePutPayoutBankAccountMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutPayoutBankAccountMutation, TError, PutPayoutBankAccountMutationVariables, TContext>) =>
    useMutation<PutPayoutBankAccountMutation, TError, PutPayoutBankAccountMutationVariables, TContext>(
      ['PutPayoutBankAccount'],
      (variables?: PutPayoutBankAccountMutationVariables) => oapFetcher<PutPayoutBankAccountMutation, PutPayoutBankAccountMutationVariables>(PutPayoutBankAccountDocument, variables)(),
      options
    );
export const GetMerchantPayoutPaymentDescriptorDocument = `
    query GetMerchantPayoutPaymentDescriptor {
  getMerchant {
    paymentConfiguration {
      statementDescriptor
      enabled
    }
  }
}
    `;
export const useGetMerchantPayoutPaymentDescriptorQuery = <
      TData = GetMerchantPayoutPaymentDescriptorQuery,
      TError = OapError[]
    >(
      variables?: GetMerchantPayoutPaymentDescriptorQueryVariables,
      options?: UseQueryOptions<GetMerchantPayoutPaymentDescriptorQuery, TError, TData>
    ) =>
    useQuery<GetMerchantPayoutPaymentDescriptorQuery, TError, TData>(
      variables === undefined ? ['GetMerchantPayoutPaymentDescriptor'] : ['GetMerchantPayoutPaymentDescriptor', variables],
      oapFetcher<GetMerchantPayoutPaymentDescriptorQuery, GetMerchantPayoutPaymentDescriptorQueryVariables>(GetMerchantPayoutPaymentDescriptorDocument, variables),
      options
    );
export const PutStatementDescriptorDocument = `
    mutation PutStatementDescriptor($statementDescriptor: String!) {
  putStatementDescriptor(statementDescriptor: $statementDescriptor)
}
    `;
export const usePutStatementDescriptorMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutStatementDescriptorMutation, TError, PutStatementDescriptorMutationVariables, TContext>) =>
    useMutation<PutStatementDescriptorMutation, TError, PutStatementDescriptorMutationVariables, TContext>(
      ['PutStatementDescriptor'],
      (variables?: PutStatementDescriptorMutationVariables) => oapFetcher<PutStatementDescriptorMutation, PutStatementDescriptorMutationVariables>(PutStatementDescriptorDocument, variables)(),
      options
    );
export const GetKycDocument = `
    query GetKyc {
  getKyc {
    businessProfile {
      name
      url
    }
    businessType
    company {
      address {
        city
        country
        line1
        line2
        postalCode
      }
      name
      phone
      people {
        address {
          city
          country
          line1
          postalCode
          line2
        }
        dateOfBirth
        email
        firstName
        lastName
        phone
        relationship {
          director
          executive
          owner
          representative
          title
        }
      }
      taxId
    }
    individual {
      dateOfBirth
      address {
        city
        country
        line1
        line2
        postalCode
      }
      email
      firstName
      lastName
      phone
      relationship {
        director
        executive
        owner
        representative
        title
      }
    }
    payoutBankAccount {
      accountNumber
      name
      routingNumber
    }
  }
}
    `;
export const useGetKycQuery = <
      TData = GetKycQuery,
      TError = OapError[]
    >(
      variables?: GetKycQueryVariables,
      options?: UseQueryOptions<GetKycQuery, TError, TData>
    ) =>
    useQuery<GetKycQuery, TError, TData>(
      variables === undefined ? ['GetKyc'] : ['GetKyc', variables],
      oapFetcher<GetKycQuery, GetKycQueryVariables>(GetKycDocument, variables),
      options
    );
export const PutKycDocument = `
    mutation PutKyc($businessProfile: StripeBusinessProfileInput, $businessType: StripeBusinessType, $payoutBankAccount: StripeBankAccountInput, $company: StripeCompanyInput, $individual: StripePersonInput) {
  putKyc(
    data: {businessProfile: $businessProfile, businessType: $businessType, payoutBankAccount: $payoutBankAccount, company: $company, individual: $individual}
  ) {
    businessProfile {
      name
      url
    }
    businessType
    company {
      address {
        city
        country
        line1
        line2
        postalCode
      }
      name
      phone
      people {
        address {
          city
          line1
          country
          line2
          postalCode
        }
        dateOfBirth
        email
        firstName
        lastName
        phone
        relationship {
          director
          executive
          owner
          representative
          title
        }
      }
      taxId
    }
    individual {
      address {
        city
        country
        line1
        line2
        postalCode
      }
      dateOfBirth
      email
      firstName
      lastName
      phone
      relationship {
        director
        executive
        owner
        representative
        title
      }
    }
    payoutBankAccount {
      accountNumber
      name
      routingNumber
    }
  }
}
    `;
export const usePutKycMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<PutKycMutation, TError, PutKycMutationVariables, TContext>) =>
    useMutation<PutKycMutation, TError, PutKycMutationVariables, TContext>(
      ['PutKyc'],
      (variables?: PutKycMutationVariables) => oapFetcher<PutKycMutation, PutKycMutationVariables>(PutKycDocument, variables)(),
      options
    );
export const SubmitKycDocument = `
    mutation SubmitKyc {
  submitKyc
}
    `;
export const useSubmitKycMutation = <
      TError = OapError[],
      TContext = unknown
    >(options?: UseMutationOptions<SubmitKycMutation, TError, SubmitKycMutationVariables, TContext>) =>
    useMutation<SubmitKycMutation, TError, SubmitKycMutationVariables, TContext>(
      ['SubmitKyc'],
      (variables?: SubmitKycMutationVariables) => oapFetcher<SubmitKycMutation, SubmitKycMutationVariables>(SubmitKycDocument, variables)(),
      options
    );