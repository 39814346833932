import { Theme, light } from '@sumup/design-tokens';

import { StylesConfig } from 'react-select';

export interface StyleProps {
    theme: Theme;
}

export const colourStyles = <T>(error?: boolean): StylesConfig<T> => ({
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        borderRadius: light.borderRadius.byte,
        padding: '2px 8px',
        minHeight: '48px',
        maxHeight: '48px',
        color: `${error ? `${light.colors.danger}` : styles.color}`,
        border: `${error ? `solid 2px ${light.colors.danger}` : styles.border}`,
        boxShadow: `${error ? light.colors.danger : styles.boxShadow}`,
        ':hover': {
            border: `${error ? `solid 2px ${light.colors.danger}` : styles.border}`,
        },
    }),
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? light.colors.p100 : 'white',
            cursor: 'pointer',
            color: 'black',
            borderRadius: '8px',
            marginBottom: '2px',
            padding: light.spacings.kilo,
            border: `2px solid ${isSelected ? light.colors.p500 : 'transparent'}`,
            ':hover': {
                backgroundColor: isSelected ? light.colors.p200 : 'white',
                border: isSelected ? `solid 2px ${light.colors.p500}` : `solid 2px ${light.colors.n500}`,
            },
        };
    },
    indicatorSeparator: () => ({
        display: 'none',
    }),
    container: (styles) => ({
        ...styles,
        minWidth: '116px',
        marginBottom: '2px',
    }),
    menuList: (styles) => ({ ...styles, padding: '0px', margin: '0px' }),
    menu: (styles) => ({
        ...styles,
        borderRadius: '8px',
        border: `2px solid ${light.colors.n200}`,
        boxShadow: 'none',
        padding: '0px',
        zIndex: 9999,
    }),
    loadingMessage: (styles) => ({
        ...styles,
        fontWeight: 'bold',
        color: 'black',
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontWeight: 'bold',
        color: 'black',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    placeholder: (styles) => ({ ...styles, color: `${error ? `${light.colors.danger}` : styles.color}` }),
    dropdownIndicator: (styles) => ({
        ...styles,
        width: '24px',
        padding: '0',
        color: `${error ? light.colors.danger : light.colors.n500}`,
    }),
    clearIndicator: (base) => ({ ...base, zIndex: 99999, padding: '8px' }),
});
