import { Auth } from 'aws-amplify';
import { isStringMessage } from 'typings/typePredicates';

export type TenantInfo = {
    [key: string]: { role: string; active: boolean; name: string };
};

export type CognitoTokenPayload = {
    [key: string]: unknown;
    'cognito:username': string;
    email: string;
    name: string;
    'custom:op_tenants': string;
    auth_time: number;
    exp: number;
    iat: number;
    email_verified: boolean;
    'cognito:groups': string[];
};

export async function getIdTokenPayload() {
    try {
        const payload = await Auth.currentSession().then(
            (resp) => resp.getIdToken().decodePayload() as CognitoTokenPayload
        );
        return payload;
    } catch (err) {
        // This is expected if the client is logged out
        if (isStringMessage(err) && err === 'No current user') {
            return null;
        }
        // This should catch anything other than the expected.
        throw new Error('failed to get current user');
    }
}

export function parseTenantInfo(cognitoTokenPayload?: CognitoTokenPayload | null): TenantInfo | undefined {
    if (cognitoTokenPayload && cognitoTokenPayload['custom:op_tenants'])
        return JSON.parse(cognitoTokenPayload['custom:op_tenants']);
    return undefined;
}

export function getGroupInfo(cognitoTokenPayload?: CognitoTokenPayload | null): string[] | undefined {
    if (cognitoTokenPayload && cognitoTokenPayload['cognito:groups']) {
        return cognitoTokenPayload['cognito:groups'];
    }
    return undefined;
}

export function getActiveTenant(tenantInfo?: TenantInfo) {
    if (tenantInfo) {
        return Object.keys(tenantInfo).find((key) => tenantInfo[key].active === true);
    }
    return undefined;
}

export async function getUserInfo() {
    const user = await getIdTokenPayload();
    const tenantInfo = parseTenantInfo(user);
    const groups = getGroupInfo(user);
    const activeTenant = getActiveTenant(tenantInfo);
    if (!user || !tenantInfo || !activeTenant) {
        // return null if cant get user info to reset sentry user to null.
        return null;
    }
    return {
        id: activeTenant,
        username: tenantInfo[activeTenant].name,
        email: user.email,
        name: user.name,
        groups,
    };
}

export const refreshAuth = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const refreshToken = await currentSession.getRefreshToken();

    return new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err: unknown, session: unknown) => {
            if (err) reject(err);
            resolve(session);
        });
    });
};
