import React from 'react';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import PageHeader from 'components/layout/PageHeader';
import SimpleGrid from 'components/layout/SimpleGrid';
import PayoutBankAccount from './PayoutBankAccount';
import PayoutBillingDescriptor from './PayoutBillingDescriptor';

function PayoutSettings() {
    return (
        <ContentPane>
            <Content>
                <PageHeader title="Payout and bank information" showBack />
                <SimpleGrid columnTemplate="100%" gap="20px">
                    <PayoutBankAccount />
                    <PayoutBillingDescriptor />
                </SimpleGrid>
            </Content>
        </ContentPane>
    );
}

export default PayoutSettings;

PayoutSettings.defaultProps = { edit: false };
