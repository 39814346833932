import { Headline, Image } from '@sumup/circuit-ui';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import React from 'react';

function LinkQrSuccess() {
    // TODO do we need a button here to go back to the dashboard? Or just redirect?
    return (
        <ContentPane>
            <Content style={{ maxWidth: '660px', alignSelf: 'center', gap: '20px' }}>
                <Image
                    style={{ width: '90px', alignSelf: 'center', marginTop: '16rem' }}
                    src="/CircleCheck.svg"
                    alt="check mark"
                />
                <Headline noMargin style={{ marginTop: '3rem', width: '100%', textAlign: 'center' }} as="h1" size="two">
                    QR linked
                </Headline>
            </Content>
        </ContentPane>
    );
}

export default LinkQrSuccess;
