import React, { useEffect, useState } from 'react';
import { ArrowRight } from '@sumup/icons';
import { Select } from '@sumup/circuit-ui';
import { Theme } from '@sumup/design-tokens';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { DateTimeRange } from 'typings/Calendar';

interface Props {
    dateTimeRange: DateTimeRange | null;
    setTime: (x: Partial<DateTimeRange>) => void;
}

export const getTimeRange = (): string[] => {
    const hours = 23;
    const intervals = ['00', '15', '30', '45'];
    const interval = intervals.length;
    const timeRange = [];
    let i;
    let j;

    for (i = 0; i <= hours; i += 1) {
        for (j = 0; j < interval; j += 1) {
            const value = `${i < 10 ? `0${i}` : i}:${intervals[j]}`;
            timeRange.push(value);
            if (i === hours && j === 3) {
                timeRange.push('23:59');
                break;
            }
        }
    }

    return timeRange;
};

const InputsContainer = styled.div`
    ${({ theme }: { theme: Theme }) => `
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: ${theme.spacings.kilo};
        border-radius: ${theme.borderRadius.pill};
        border: 1px solid ${theme.colors.n500};
        text-align: center;
        min-height: 48px;
        max-width: 98%;
        min-width: 98%;
    `}
`;

const StyledSelect = styled(Select)`
    ${({ theme }: { theme: Theme }) => `
        svg {
            display: none;
        }
        select {
            box-shadow: none;
            border: none;
            padding: ${theme.spacings.byte};
            &:hover,
            &:focus,
            &:active {
                box-shadow: none;
            }
            widht: 100%;
            text-align: center;
        }
    `}
`;

function TimeRange({ dateTimeRange, setTime }: Props) {
    const theme = useTheme();
    const [endTimeRange, setEndTimeRange] = useState<{ value: string; label: string }[]>();

    const convertStringTime = (time?: string) => {
        if (time) {
            const timeArray = time.split(':');
            return { hour: Number(timeArray[0]), minute: Number(timeArray[1]) };
        }
        return undefined;
    };

    const handleSetTime = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();
        if (event.currentTarget.name === 'START_TIME_SELECT') {
            setTime({ startTime: convertStringTime(event.currentTarget.value) });
        }
        if (event.currentTarget.name === 'END_TIME_SELECT') {
            setTime({ endTime: convertStringTime(event.currentTarget.value) });
        }
    };

    useEffect(() => {
        const getReducedTimeRange = () => {
            const fullTimeRange = getTimeRange().map((time) => ({
                value: time,
                label: time,
            }));
            if (dateTimeRange?.startTime) {
                const filteredEndTimes = fullTimeRange.filter(
                    (item) =>
                        parseInt(item.value.replace(':', ''), 10) >
                        parseInt(`${dateTimeRange.startTime?.hour}${dateTimeRange.startTime?.minute}`, 10)
                );
                return filteredEndTimes;
            }
            return fullTimeRange;
        };
        setEndTimeRange(getReducedTimeRange());
    }, [dateTimeRange]);

    return (
        <InputsContainer theme={theme as Theme}>
            <StyledSelect
                theme={theme as Theme}
                noMargin
                label="Start time"
                hideLabel
                name="START_TIME_SELECT"
                options={getTimeRange().map((time) => ({
                    value: time,
                    label: time,
                }))}
                onChange={handleSetTime}
            />

            <ArrowRight />

            <StyledSelect
                theme={theme as Theme}
                noMargin
                label="End time"
                hideLabel
                name="END_TIME_SELECT"
                options={endTimeRange}
                onChange={handleSetTime}
            />
        </InputsContainer>
    );
}

export default TimeRange;
