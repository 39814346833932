import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { Label } from '@sumup/circuit-ui';
import { Alert } from '@sumup/icons';
import { colourStyles } from 'styles/react-select';
import { light } from '@sumup/design-tokens';
import styled from '@emotion/styled';

const SelectWrapper = styled.div();

export interface RQSelectProps<T> {
    options?: T[];
    optionIsSelected?: (x: T) => boolean;
    getOptionValue: (x: T) => string;
    getOptionLabel: (x: T) => string;
    updateSearch?: (searchString: string) => void;
    onChange: (newValue: SingleValue<T> | null, action: ActionMeta<T>) => void | undefined;
    defaultValue?: T;
    placeholder?: string;
    value?: T;
    label?: string;
    id?: string;
    loading?: boolean;
    isClearable?: boolean;
    formError?: boolean;
    errorMessage?: string;
}

function RQSelect<T>({
    options,
    optionIsSelected,
    onChange,
    getOptionLabel,
    getOptionValue,
    updateSearch,
    defaultValue,
    placeholder,
    value,
    label,
    id,
    loading,
    isClearable,
    formError,
    errorMessage,
}: RQSelectProps<T>): JSX.Element {
    return (
        <SelectWrapper>
            {label && id && (
                <Label style={{ width: '100%', marginBottom: '4px' }} htmlFor={id} about={`Label for ${label}`}>
                    {label}
                </Label>
            )}
            <Select
                styles={colourStyles<T>(formError)}
                menuPosition="fixed"
                id={id}
                isClearable={isClearable}
                backspaceRemovesValue={isClearable}
                isLoading={loading}
                isOptionSelected={optionIsSelected}
                menuPortalTarget={document.body}
                onChange={onChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                options={options}
                isSearchable
                onInputChange={updateSearch}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                isMulti={false}
            />
            {formError && id && errorMessage && (
                <Label
                    style={{ color: light.colors.danger, width: '100%', marginTop: '1px' }}
                    htmlFor={id}
                    about={`Error for ${label}`}
                >
                    <span>
                        <Alert
                            style={{
                                display: 'inline-block',
                                width: `${light.iconSizes.kilo}`,
                                height: `${light.iconSizes.kilo}`,
                                verticalAlign: 'text-top',
                                marginRight: `${light.spacings.bit}`,
                                color: `${light.colors.danger}`,
                            }}
                        />
                        {errorMessage}
                    </span>
                </Label>
            )}
        </SelectWrapper>
    );
}

RQSelect.defaultProps = {
    id: undefined,
    label: undefined,
    value: undefined,
    defaultValue: undefined,
    optionIsSelected: undefined,
    options: undefined,
    getOptionValue: undefined,
    placeholder: 'Select',
    updateSearch: undefined,
    loading: false,
    isClearable: false,
    formError: false,
    errorMessage: undefined,
};

export default RQSelect;
