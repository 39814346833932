interface UploadProps {
    mimeType: string;
    file: File;
    preSignedUrl: string;
    handleError: (msg?: string | undefined) => void;
}

export async function upload({ mimeType, file, preSignedUrl, handleError }: UploadProps) {
    const headers = new Headers();
    headers.append('Content-Type', mimeType);
    headers.append('content-disposition', 'inline');
    const putObjectResponse = await fetch(preSignedUrl, {
        method: 'PUT',
        headers,
        body: file,
    });
    if (!putObjectResponse.ok) {
        handleError('Upload failed');
    }
    if (putObjectResponse.status === 503) {
        // Status 503 is a rate limit. This can be ignored as the file is available in all of my testing, but it should be added to our logging to know if this is going to be a bigger issue.
        console.error(putObjectResponse.body);
        handleError(undefined);
    }
    if (putObjectResponse.ok) {
        handleError(undefined);
    }
}

export default upload;
