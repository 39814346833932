import React from 'react';
import { Body, Headline, ModalProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

const ImageCardTitle = styled.div`
    margin: 20px;
`;

const teaserDetails = (setModal: (x: Omit<ModalProps, 'isOpen'>) => void) => [
    {
        title: (
            <ImageCardTitle>
                <Headline noMargin as="h3" size="three">
                    Book a free session
                </Headline>
            </ImageCardTitle>
        ),
        image: '/onBoarding.png',
        buttonLabel: 'Book session',
        description:
            'One of our customer success managers will be happy to support you and make sure you get the most out of Magic Pay.',
        teaserUrl: 'https://meetings.hubspot.com/diego178/free-onboarding-magic-pay-diego',
        notAvailable: false,
        imageBackgroundColor: '#f5f5f5',
    },
    {
        title: (
            <ImageCardTitle>
                <Headline noMargin as="h3" size="three">
                    Magic Pay setup
                </Headline>
            </ImageCardTitle>
        ),
        image: '/magicPaySetup.png',
        buttonLabel: 'Watch video',
        description: 'Learn how to connect Magic Pay to your POS system seamlessly.',
        notAvailable: false,
        onClick: () =>
            setModal({
                closeButtonLabel: 'Close',
                variant: 'immersive',
                children: (
                    <Body>
                        <Headline as="h2" size="three">
                            Magic Pay Setup
                        </Headline>
                        <iframe
                            title="Setup Magic Pay"
                            src="https://player.vimeo.com/video/738267189?h=a087b6547a"
                            width="100%"
                            height="250px"
                            allow="autoplay; fullscreen; picture-in-picture"
                        />
                    </Body>
                ),
            }),
        imageBackgroundColor: '#f5f5f5',
    },
];

export default teaserDetails;
