import React from 'react';
import { Button, ButtonGroup } from '@sumup/circuit-ui';
import Form from 'components/Form';
import SimpleGrid from 'components/layout/SimpleGrid';
import DisplayOnlyInput from 'components/ui/forms/DisplayOnlyInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BankAccount } from 'utils/generated';

interface Props {
    defaultValues?: BankAccount;
    paymentsEnabled?: boolean;
}

function PayoutSettingsDetail({ defaultValues, paymentsEnabled }: Props) {
    const navigate = useNavigate();
    const { register } = useForm<BankAccount>({ defaultValues });

    return (
        <Form>
            <DisplayOnlyInput {...register('name')} label="Account description" readOnly />
            <SimpleGrid columnTemplate="2fr 2fr" gap="20px">
                <DisplayOnlyInput {...register('accountNumber')} label="Account number" readOnly />
                <DisplayOnlyInput {...register('routingNumber')} label="Sort code" readOnly />
            </SimpleGrid>
            <ButtonGroup align="left">
                <Button disabled={!paymentsEnabled} variant="primary" onClick={() => navigate('../edit/bank-account')}>
                    Change bank account
                </Button>
            </ButtonGroup>
        </Form>
    );
}

export default PayoutSettingsDetail;
