import React, { ReactNode } from 'react';
import * as yup from 'yup';
import { DeepPartial, FormProvider, Resolver, UnpackNestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props<T, M> {
    schema: yup.SchemaOf<T>;
    onSubmit: (x: UnpackNestedValue<T>) => Promise<M | void>;
    initialValues?: UnpackNestedValue<DeepPartial<T>>;
    children: ReactNode;
}

function FormWithSchema<T extends Record<string, unknown>, M>({
    onSubmit,
    schema,
    initialValues,
    children,
}: Props<T, M>) {
    const methods = useForm<T>({
        resolver: yupResolver(schema) as Resolver<T>,
        defaultValues: initialValues,
    });

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
    );
}

export default FormWithSchema;
