import React from 'react';

import {
    CreatePaymentRefundMutationVariables,
    GetPaymentQuery,
    Payment,
    Source,
    useCreatePaymentRefundMutation,
    useGetPaymentQuery,
} from 'utils/generated';
import { Body, Button, ButtonGroup, Headline, IconButton, Spinner, useModal } from '@sumup/circuit-ui';
import ErrorBanner from 'components/ErrorBanner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import { Receipt, Refresh } from '@sumup/icons';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';
import DetailPanelHeader from 'components/layout/DetailPanelHeader';
import { light } from '@sumup/design-tokens';
import { css } from '@emotion/css';
import { useIntl } from 'react-intl';
import Content from 'components/layout/Content';
import IFrame from 'components/ui/IFrame';
import RefundModalBody from './RefundModalBody';
import RefundStatusModalBody from './RefundStatusModalBody';
import OrderPreviewUnavailable from './OrderPreviewUnavailable';

type Params = {
    paymentId: string;
    merchantId: string;
};

const detailsResponsiveStyles = () => {
    return css`
        ${light.mq.untilKilo} {
            top: 0px;
            width: 100%;
            border-left: none;
            max-height: calc(100vh - 70px);
        }
        ${light.mq.tera} {
            top: 16px;
            border: 2px solid #e6e6e6;
            max-width: 434px;
            border-radius: 16px;
            padding-left: 8px;
            padding-right: 8px;
            margin: 8px;
            max-height: calc(100vh - 90px);
            position: sticky;
        }
    `;
};

const detailsBaseStyles = () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    border-radius: 0px;
    border-left: 2px solid #e6e6e6;
    border-top: none;
    border-right: none;
    padding: 0px;
    width: 100%;
    min-width: 378px;
    top: 0px;
    position: sticky;
`;

const StyledDetailBody = styled.div(detailsBaseStyles, detailsResponsiveStyles);

function PaymentDetail() {
    const { paymentId } = useParams() as Params;
    const { formatTime } = useIntl();
    const locationWindow = useLocation();
    const { data: paymentData, error } = useGetPaymentQuery(
        { paymentId },
        {
            initialData: { getPayment: locationWindow?.state } as GetPaymentQuery,
        }
    );

    const navigate = useNavigate();

    const { setModal, removeModal } = useModal();

    const { mutateAsync } = useCreatePaymentRefundMutation();

    const queryClient = useQueryClient();

    const onSubmit = async (values: CreatePaymentRefundMutationVariables) => {
        return mutateAsync(values)
            .then((res) => {
                queryClient.refetchQueries({
                    queryKey: ['GetPayment', { paymentId }],
                });
                setModal({
                    children: (
                        <RefundStatusModalBody
                            status="Success"
                            refundDetails={res}
                            currency={res.createPaymentRefund.currency}
                        />
                    ),
                    variant: 'immersive',
                    closeButtonLabel: 'Close modal',
                });
            })
            .catch((errors) =>
                setModal({
                    children: <RefundStatusModalBody errors={errors} status="Failed" />,
                    variant: 'immersive',
                    closeButtonLabel: 'Close modal',
                })
            );
    };

    if (error && error?.length > 0) return <ErrorBanner message={error![0].message as string} />;

    if (!paymentData?.getPayment) return <ErrorBanner message="Can't get payment info" />;
    const { amount, currency, refunds, location, createdAt } = paymentData.getPayment as Payment;

    const receiptDomain = process.env.REACT_APP_CONSUMER_FRONT_URL as string;

    const receiptUrl = paymentData?.getPayment?.location?.merchant?.id
        ? `${receiptDomain}/merchant/${paymentData?.getPayment?.location?.merchant.id}/location/${paymentData?.getPayment?.location?.id}/order/${paymentData?.getPayment?.orderId}`
        : undefined;

    const handleReceiptClick = () => window.open(receiptUrl, '_blank');

    const totalRefunds = refunds?.map((x) => x.amount).reduce((acc, refund) => acc + refund, 0) ?? 0;

    const handleClick = () => {
        setModal({
            children: (
                <RefundModalBody
                    currency={currency}
                    paymentAmount={amount}
                    totalRefunds={totalRefunds}
                    defaultValues={{ paymentId }}
                    onSubmit={onSubmit}
                    closeCallback={removeModal}
                />
            ),
            variant: 'immersive',
            closeButtonLabel: 'Close modal',
            id: paymentId,
            preventClose: true,
        });
    };

    return (
        <StyledDetailBody>
            <DetailPanelHeader
                title="Payment details"
                onClose={() => navigate('/payments', { state: locationWindow.state })}
            />
            <Content narrow>
                <Headline style={{ textAlign: 'center' }} as="h1" noMargin>
                    <CurrencyFormat amount={amount} currency={currency} />
                </Headline>
                <Body size="two" style={{ textAlign: 'center' }}>
                    {createdAt &&
                        Date.parse(createdAt) !== Date.now() &&
                        formatTime(createdAt, {
                            dateStyle: 'medium',
                        })}
                    &nbsp;
                    {formatTime(createdAt, {
                        hour: 'numeric',
                        minute: 'numeric',
                    })}
                </Body>

                <Headline size="four" as="h3" style={{ textAlign: 'center' }}>
                    {paymentData?.getPayment.order?.posTableName &&
                        `${paymentData?.getPayment.order?.posTableName.toUpperCase()}, `}
                    {
                        location?.name /** Will add table name here when available. It's not in the schema so can't add it yet. */
                    }
                </Headline>

                {paymentData?.getPayment.source === Source.TablePay ? (
                    <IFrame url={receiptUrl} />
                ) : (
                    <OrderPreviewUnavailable />
                )}

                <ButtonGroup align="center">
                    {amount < 0 ? null : (
                        <Button style={{ minWidth: '45%' }} variant="secondary" onClick={handleClick}>
                            <Refresh /> &nbsp; Refund
                        </Button>
                    )}
                    <IconButton
                        style={{ minWidth: '45%' }}
                        variant="primary"
                        onClick={handleReceiptClick}
                        label="Receipt"
                        disabled={!paymentData?.getPayment?.location?.merchant?.id}
                    >
                        {!paymentData?.getPayment?.location?.merchant?.id ? (
                            <Spinner style={{ height: '24px', width: '24px' }} />
                        ) : (
                            <>
                                <Receipt /> &nbsp; Receipt
                            </>
                        )}
                    </IconButton>
                </ButtonGroup>
            </Content>
        </StyledDetailBody>
    );
}

export default PaymentDetail;
