import React, { useState } from 'react';
import { Button, ButtonGroup, Input } from '@sumup/circuit-ui';
import Form from 'components/Form';
import SimpleGrid from 'components/layout/SimpleGrid';
import { useForm } from 'react-hook-form';
import { isPayoutBankAccountVariables } from 'typings/typePredicates';
import { BankAccount, BankAccountInput, Exact, OapError, PutPayoutBankAccountMutation } from 'utils/generated';
import useErrorHandler from 'hooks/useErrorHandler';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ErrorBanner from 'components/ErrorBanner';

interface Props {
    defaultValues?: Partial<BankAccount>;
    mutation: UseMutationResult<
        PutPayoutBankAccountMutation,
        OapError[],
        Exact<{
            payoutBankAccount: BankAccountInput;
        }>,
        unknown
    >;
    paymentsEnabled?: boolean;
}

function PayoutSettingsEdit({ defaultValues, mutation, paymentsEnabled }: Props) {
    const { handleMutationError, validationErrors, clearValidationErrors } = useErrorHandler();
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset: resetForm,
        formState: { errors, isDirty },
    } = useForm<BankAccount>({ defaultValues }); // retrieve all hook methods

    const { mutateAsync, reset } = mutation;

    const returnToViewOnly = () => {
        reset();
        navigate('/user-settings/payout-settings/view');
    };

    const onError = (error: unknown) => {
        setSubmitInProgress(false);
        handleMutationError(error);
    };

    const handleResponse = (res: void | PutPayoutBankAccountMutation) => {
        if (res?.putPayoutBankAccount) {
            setSubmitInProgress(false);
            resetForm(res?.putPayoutBankAccount);
            returnToViewOnly();
        }
    };

    const beforeSubmit = (x: BankAccount) => {
        clearValidationErrors();
        if (isPayoutBankAccountVariables(x)) {
            setSubmitInProgress(true);
            const formattedRoutingNumber = x.routingNumber.replace('-', '');
            mutateAsync({ payoutBankAccount: { ...x, routingNumber: formattedRoutingNumber } })
                .catch(onError)
                .then(handleResponse);
        }
    };

    return (
        <>
            {validationErrors.length > 0 &&
                validationErrors.map((validationError) => (
                    <ErrorBanner
                        key={validationError.field}
                        message={`${validationError.field}: ${validationError.message}`}
                    />
                ))}
            <Form onSubmit={handleSubmit(beforeSubmit)}>
                <Input
                    validationHint={errors?.name && errors?.name?.message}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('name', { required: true })}
                    label="Account description"
                />
                <SimpleGrid columnTemplate="2fr 2fr" gap="20px">
                    <Input
                        validationHint={errors?.accountNumber && errors?.accountNumber?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('accountNumber', { required: true })}
                        label="Account number"
                        placeholder="Account number"
                        invalid={!!errors?.accountNumber}
                    />
                    <Input
                        validationHint={errors?.routingNumber && errors?.routingNumber?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('routingNumber', { required: true })}
                        label="Sort code"
                        placeholder="Sort code"
                        invalid={!!errors?.routingNumber}
                    />
                </SimpleGrid>
                <ButtonGroup align="left">
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={submitInProgress}
                        loadingLabel="Processing..."
                        disabled={!isDirty && !paymentsEnabled}
                    >
                        Save
                    </Button>
                    <Button onClick={returnToViewOnly}>Cancel</Button>
                </ButtonGroup>
            </Form>
        </>
    );
}

export default PayoutSettingsEdit;
