import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import ImageContainer from './ImageContainer';
import ImageCardContainer from './ImageCardContainer';
import CardBody from './ImageCardBody';

const ImageWrapper = styled.img`
    max-width: 100%;
    max-height: 250px;
    min-height: 250px;
    height: auto;
    object-fit: cover;
`;

const ImageCardFooter = styled.div`
    padding: 0px 20px 20px;
`;

function ImageCard({
    title,
    image,
    buttonLabel,
    description,
    teaserUrl,
    onClick,
    notAvailable,
    center,
    imageBackgroundColor,
}: {
    title: React.ReactNode;
    image: string;
    buttonLabel: string;
    description?: React.ReactNode;
    teaserUrl?: string;
    onClick?: (event: ClickEvent<Element>) => void;
    notAvailable?: boolean;
    center?: boolean;
    imageBackgroundColor?: string;
}) {
    return (
        <ImageCardContainer backgroundColor="#f1f0f0" gridTemplateRows="250px 0.5fr 2fr 1fr">
            <ImageContainer backgroundColor={imageBackgroundColor}>
                <ImageWrapper src={image} alt={`${title} image`} />
            </ImageContainer>
            {title}
            {description && <CardBody center={center}>{description}</CardBody>}
            <ImageCardFooter>
                <Button
                    size="giga"
                    stretch
                    disabled={notAvailable}
                    variant="primary"
                    onClick={onClick}
                    href={teaserUrl}
                >
                    {buttonLabel}
                </Button>
            </ImageCardFooter>
        </ImageCardContainer>
    );
}

export default ImageCard;

ImageCard.defaultProps = {
    center: false,
};
