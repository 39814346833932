import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo } from 'helpers/userInfoHelper';
import { Amplify, Hub } from 'aws-amplify';
import {
    Authenticator,
    Flex,
    Heading,
    useAuthenticator,
    Image,
    Button,
    Text,
    CheckboxField,
} from '@aws-amplify/ui-react';
import { Body, Button as CUIButton } from '@sumup/circuit-ui';
import * as Sentry from '@sentry/react';
import { useQueryClient } from 'react-query';
import awsconfig from './awsConfig';
import 'styles/amplify.css';

const components = {
    Header() {
        return (
            <Flex grow="0.5" direction="column" justifyContent="flex-start">
                <Image marginTop="52px" marginBottom="14px" width="125px" alt="SumUp logo" src="/sumup-logo.svg" />
            </Flex>
        );
    },

    Footer() {
        const navigate = useNavigate();
        const { route, toSignIn, toSignUp } = useAuthenticator();

        const handleToSignUpClick = () => {
            navigate('/sign-up', { replace: true });
            toSignUp();
        };

        return (
            <Flex grow="1" direction="column" gap="0" justifyContent="center" alignContent="center">
                {route === 'signIn' ? (
                    <Button onClick={handleToSignUpClick} variation="link">
                        Create your login
                    </Button>
                ) : (
                    <>
                        {route === 'signUp' && (
                            <Text marginTop="1rem" textAlign="center">
                                Already have an account?
                            </Text>
                        )}
                        <Button margin="2px" onClick={toSignIn} variation="link">
                            {route === 'signUp' ? 'Log in' : 'Return to Login'}
                        </Button>
                    </>
                )}
            </Flex>
        );
    },

    SignIn: {
        Header() {
            return (
                <Flex justifyContent="flex-start" alignContent="center">
                    <Heading
                        marginLeft="24px"
                        marginTop="16px"
                        color="black"
                        fontWeight="700"
                        letterSpacing="-0.03em"
                        fontSize="18px"
                        level={6}
                    >
                        Log in
                    </Heading>
                </Flex>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();
            return (
                <Flex justifyContent="center" alignContent="center">
                    <Button onClick={toResetPassword} variation="link">
                        Reset Password
                    </Button>
                </Flex>
            );
        },
    },

    SignUp: {
        FormFields() {
            const { validationErrors } = useAuthenticator();
            return (
                <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    <CheckboxField
                        required
                        errorMessage={validationErrors.termsOfService as string}
                        hasError={!!validationErrors.termsOfService}
                        name="termsOfService"
                        value="termsOfService"
                        margin="0px 5px"
                        label={
                            <>
                                &nbsp;I accept the
                                <CUIButton
                                    style={{ margin: '0 auto', padding: '0px 3px' }}
                                    variant="tertiary"
                                    size="kilo"
                                    target="_blank"
                                    href="https://thegoodtill.com/terms-conditions/"
                                >
                                    terms of service
                                </CUIButton>
                            </>
                        }
                    />

                    <CheckboxField
                        required
                        errorMessage={validationErrors.privacyPolicy as string}
                        hasError={!!validationErrors.privacyPolicy}
                        name="privacyPolicy"
                        value="privacyPolicy"
                        margin="0px 5px"
                        label={
                            <>
                                &nbsp; I accept the
                                <CUIButton
                                    style={{ margin: '0 auto', padding: '0px 3px' }}
                                    variant="tertiary"
                                    size="kilo"
                                    target="_blank"
                                    href="https://thegoodtill.com/privacy-policy/"
                                >
                                    privacy policy
                                </CUIButton>
                            </>
                        }
                    />
                </>
            );
        },
        Header() {
            return (
                <Heading
                    marginLeft="24px"
                    marginTop="16px"
                    color="black"
                    fontWeight="700"
                    letterSpacing="-0.03em"
                    fontSize="18px"
                    level={6}
                >
                    Create your login
                </Heading>
            );
        },
    },
    ConfirmSignUp: {
        Header() {
            return (
                <Heading
                    marginTop="16px"
                    color="black"
                    fontWeight="700"
                    letterSpacing="-0.03em"
                    fontSize="18px"
                    level={6}
                >
                    Enter Code
                </Heading>
            );
        },
    },
    SetupTOTP: {
        Header() {
            return (
                <Heading
                    marginTop="16px"
                    color="black"
                    fontWeight="700"
                    letterSpacing="-0.03em"
                    fontSize="18px"
                    level={6}
                >
                    Enter Code
                </Heading>
            );
        },
    },
    ConfirmSignIn: {
        Header() {
            return (
                <Heading color="black" fontWeight="700" letterSpacing="-0.03em" fontSize="18px" level={6}>
                    Enter Code
                </Heading>
            );
        },
    },
    ResetPassword: {
        Header() {
            return (
                <>
                    <Heading color="black" fontWeight="700" letterSpacing="-0.03em" fontSize="18px" level={6}>
                        Password Reset
                    </Heading>
                    <Body>
                        Please enter your email address. We&#39;ll send you instructions on how to reset your password.
                    </Body>
                </>
            );
        },
    },
    ConfirmResetPassword: {
        Header() {
            return (
                <Heading color="black" fontWeight="700" letterSpacing="-0.03em" fontSize="18px" level={6}>
                    Enter Code
                </Heading>
            );
        },
    },
};

const formFields = {
    signUp: {
        email: {
            order: 1,
            labelHidden: false,
            placeholder: 'Your email',
            label: 'Email *',
            isRequired: true,
        },
        name: {
            order: 2,
            labelHidden: false,
            label: 'Name *',
            placeholder: 'Your name',
            isRequired: true,
        },
        password: {
            order: 3,
            labelHidden: false,
            placeholder: '••••••••',
            label: 'Password *',
            isRequired: true,
        },
        confirm_password: {
            order: 4,
            labelHidden: false,
            placeholder: '••••••••',
            label: 'Confirm password *',
            isRequired: true,
        },
    },
    signIn: {
        username: {
            order: 1,
            labelHidden: false,
            placeholder: 'Your email',
            label: 'Email *',
            isRequired: true,
        },
        password: {
            order: 2,
            labelHidden: false,
            placeholder: '••••••••',
            label: 'Password *',
            isRequired: true,
        },
    },
    resetPassword: {
        username: {
            labelHidden: false,
            placeholder: 'Your email',
            label: 'Email *',
            isRequired: true,
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            placeholder: 'Code',
            label: 'Code *',
            isRequired: true,
        },
        confirm_password: {
            labelHidden: false,
            label: 'Conrifm password *',
            placeholder: 'Confirm password',
            isRequired: true,
        },
    },
    setupTOTP: {
        confirmation_code: {
            labelHidden: false,
            label: 'Code *',
            placeholder: 'Code',
            isRequired: true,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: false,
            label: 'Code *',
            placeholder: 'Code',
            isRequired: true,
        },
    },
    verifyUser: {
        confirmation_code: {
            labelHidden: false,
            label: 'Code *',
            placeholder: 'Code',
            isRequired: true,
        },
    },
};

export default function RequireAuth() {
    Amplify.configure(awsconfig);

    const queryClient = useQueryClient();

    useEffect(() => {
        const setUserTracking = async () => {
            const userInfo = await getUserInfo();
            // id = activeTenant username = tenantName - Filtered out name and email.
            Sentry.setUser({ id: userInfo?.id, username: userInfo?.username });
        };
        const authChange = (data?: { payload: { event: string } }) => {
            if (data && data?.payload?.event === 'signIn') {
                setUserTracking();
            }
            if (data && data?.payload?.event === 'signOut') {
                queryClient.clear();
            }
            if (data && data.payload.event === 'tokenRefresh') {
                queryClient.refetchQueries();
            }
        };
        Hub.listen('auth', authChange); // listen for auth events
        setUserTracking(); // First run because Hub wont be active yet
        return () => Hub.remove('auth', setUserTracking);
    }, [queryClient]);

    const location = useLocation();

    return (
        <Authenticator
            initialState={location.pathname.includes('sign-up') ? 'signUp' : 'signIn'}
            loginMechanisms={['email']}
            formFields={formFields}
            components={components}
            services={{
                // eslint-disable-next-line consistent-return
                validateCustomSignUp: async (formData, touchedData) => {
                    const errors = {} as { privacyPolicy?: string; termsOfService?: string };
                    if (touchedData.privacyPolicy && !formData.privacyPolicy) {
                        errors.privacyPolicy = 'You must agree to the privacy policy';
                    }
                    if (touchedData.termsOfService && !formData.termsOfService) {
                        errors.termsOfService = 'You must agree to the terms of service';
                    }
                    if (errors.privacyPolicy || errors.termsOfService) {
                        return errors;
                    }
                },
            }}
            signUpAttributes={['name', 'email']}
            passwordSettings={{
                passwordPolicyCharacters: [
                    'REQUIRES_LOWERCASE',
                    'REQUIRES_NUMBERS',
                    'REQUIRES_SYMBOLS',
                    'REQUIRES_UPPERCASE',
                ],
                passwordPolicyMinLength: 8,
            }}
        >
            {({ signOut, user }) => <Outlet context={{ user, signOut }} />}
        </Authenticator>
    );
}
