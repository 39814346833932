import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

const ToggleWrapperStyles = () => css`
    width: 100%;
    max-height: 24px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 40px 200px;
    gap: 16px;
`;

const ToggleWrapper = styled('div')(ToggleWrapperStyles);

function ToggleLoading() {
    return (
        <ToggleWrapper>
            <Skeleton enableAnimation count={1} borderRadius="16px" height="20px" width="40px" />
            <Skeleton enableAnimation count={1} borderRadius="16px" height="20px" width="200px" />
        </ToggleWrapper>
    );
}

export default ToggleLoading;
