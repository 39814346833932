import React from 'react';
import { Body, useModal } from '@sumup/circuit-ui';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import ContentPane from 'components/layout/ContentPane';
import ListView from 'components/layout/ListView';
import { KdsConnection, KdsConnectionInput, KdsSystem, useGetKdsConnectionListQuery } from 'utils/generated';
import { useQueryClient } from 'react-query';
import KdsConnectionModal from './components/KdsConnectionModal';

function KdsConnectionList() {
    const { data, error, isLoading, isFetching } = useGetKdsConnectionListQuery();

    const { setModal, removeModal } = useModal();

    const queryClient = useQueryClient();

    const openKdsConnection = (kdsConnection: Omit<KdsConnectionInput, 'config'>) => {
        setModal({
            children: (
                <KdsConnectionModal
                    kdsConnection={kdsConnection}
                    title="Add connection"
                    submitButtonName="Create"
                    closeCallback={() => {
                        queryClient.invalidateQueries(['GetKdsConnectionList']);
                        removeModal();
                    }}
                />
            ),
            variant: 'immersive',
            closeButtonLabel: 'Close modal',
            id: kdsConnection?.id || 'new-kds-connection',
            preventClose: true,
        });
    };

    const getDetails = (x: KdsConnection): ItemProps => ({
        key: x.id,
        label: x.name,
        details: x.kdsSystem,
        variant: 'action',
    });

    return (
        <ContentPane>
            <ListView<KdsConnection>
                detailsOpen={false}
                heading={{
                    title: 'KDS Connections',
                    cta: {
                        buttonText: 'Add new',
                        callBack: () => openKdsConnection({ id: 'new-kds-connection', kdsSystem: KdsSystem.Tickncook }),
                    },
                }}
                getDetails={getDetails}
                listItems={data?.getKdsConnections}
                isListScreen
                errors={error}
                isLoading={isLoading || isFetching}
                noDataMessage={<Body>There are no kds connections. Click Add new to create one.</Body>}
            />
        </ContentPane>
    );
}

export default KdsConnectionList;
