import { TerminalInput } from 'utils/generated';
import * as yup from 'yup';

const TerminalConfigSchema: yup.SchemaOf<TerminalInput> = yup
    .object({
        id: yup.string(),
        name: yup.string().required('You must add a name to this terminal'),
        configurationId: yup.string().required('You must select a terminal config'),
    })
    .required();

export default TerminalConfigSchema;
