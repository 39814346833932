import React from 'react';
import { Body, useModal } from '@sumup/circuit-ui';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import ContentPane from 'components/layout/ContentPane';
import ListView from 'components/layout/ListView';
import { PriceBookInput, useGetPriceBooksQuery } from 'utils/generated';
import { Edit } from '@sumup/icons';
import { useQueryClient } from 'react-query';
import PriceBookModal from './components/PriceBookModal';

function PricebooksList() {
    const { data, error, isLoading, isFetching } = useGetPriceBooksQuery();

    const { setModal, removeModal } = useModal();

    const queryClient = useQueryClient();

    const openPricebookModal = (priceBook?: { id: string; name: string }) => {
        setModal({
            children: (
                <PriceBookModal
                    priceBook={priceBook}
                    title={priceBook?.name !== '' ? 'Update Price Book' : 'Create Price Book'}
                    submitButtonName={priceBook?.name !== '' ? 'Update' : 'Create'}
                    closeCallback={() => {
                        queryClient.invalidateQueries(['GetPriceBooks']);
                        removeModal();
                    }}
                />
            ),
            variant: 'immersive',
            closeButtonLabel: 'Close modal',
            id: priceBook?.id || 'new-price-book',
            preventClose: true,
        });
    };

    const getDetails = (x: PriceBookInput): ItemProps => ({
        key: x.id || 'new-id',
        label: x.name,
        details: x.id,
        variant: 'action',
        suffix: <Edit onClick={() => openPricebookModal({ id: x?.id || 'new-id', name: x.name || '' })} />,
    });

    const priceBookCount = data?.getPriceBooks.length || 0;

    const newPricebookId = ['DEFAULT', 'TAKEAWAY'];

    const canAddPriceBook = priceBookCount < 2;

    return (
        <ContentPane>
            <ListView<PriceBookInput>
                detailsOpen={false}
                heading={{
                    title: 'Price Books',
                    cta: canAddPriceBook
                        ? {
                              buttonText: 'Add new',
                              callBack: () => openPricebookModal({ id: newPricebookId[priceBookCount], name: '' }),
                          }
                        : undefined,
                }}
                getDetails={getDetails}
                listItems={data?.getPriceBooks}
                isListScreen
                errors={error}
                isLoading={isLoading || isFetching}
                noDataMessage={<Body>There are no Price Books configured. Click Add new to create one.</Body>}
            />
        </ContentPane>
    );
}

export default PricebooksList;
