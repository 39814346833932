import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/layout/PageHeader';
import {
    Currency,
    PutTerminalConfigurationMutationVariables,
    usePutTerminalConfigurationMutation,
} from 'utils/generated';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import { TerminalTemplateAppearanceChange } from 'typings/TerminalConfig';
import FormError from 'components/FormError/FormError';
import TerminalTemplateForm from './TerminalTemplateForm';

function TerminalConfigurationCreate() {
    const navigate = useNavigate();
    const { mutateAsync, error, isLoading } = usePutTerminalConfigurationMutation();

    const onSubmit = async (values: PutTerminalConfigurationMutationVariables) => {
        return mutateAsync(values).then((x) => navigate(`../${x.putTerminalConfiguration.id}`));
    };

    const formDefaultValues: Partial<TerminalTemplateAppearanceChange> = {
        appearance: {
            images: {
                screenSaverImageUrls: [],
                logoUrl: '',
            },
            colors: {
                primaryText: '#FFFFFF', // Used for success button text
                primary: '#1A1A1A',
                warningText: '#FF0000', // Used if we have an element with background of "warning colour"
                background: '#1A1A1A', // kiosk background
                accent: '#3063E9',
                text: '#1A1A1A', // for text over background colour
            },
        },
        strings: {
            globalMessageText: '',
            orderConfirmationText: '',
        },
        localisation: {
            currency: Currency.Gbp,
            defaultLocale: 'en-GB',
        },
        receipt: {
            print: true,
            showPrices: false,
            additionalReceiptText: null,
            receiptHeaderText: null,
        },
        showAccessibilityOption: true,
    };

    return (
        <ContentPane>
            <Content>
                <PageHeader title="Create Terminal Template" showBack />
                <FormError errors={error} />
                <TerminalTemplateForm
                    defaultValues={formDefaultValues}
                    onSubmit={onSubmit}
                    isSavingTerminal={isLoading}
                />
            </Content>
        </ContentPane>
    );
}

export default TerminalConfigurationCreate;
