import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@sumup/design-tokens';

interface SplitPaneProps {
    theme: Theme;
    isSplit: boolean;
}

const splitPaneBaseStyles = ({ theme, isSplit }: SplitPaneProps) => css`
    display: flex;
    justify-content: ${isSplit ? '' : 'center'};
    align-items: ${isSplit ? 'center' : ''};
    flex-direction: ${isSplit ? 'column' : 'row'};
    width: 100%;
    ${theme.mq.untilKilo} {
        flex-direction: column-reverse;
    }
`;

const SplitPane = styled.div<SplitPaneProps>(splitPaneBaseStyles);

export default SplitPane;
