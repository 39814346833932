import { css } from '@emotion/react';
import styled from '@emotion/styled';

const formStyles = () => css`
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 16px;
    position: relative;
`;
const Form = styled('form')(formStyles);

export default Form;
