import { Body, Headline } from '@sumup/circuit-ui';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import React from 'react';
import { CreatePaymentRefundMutation, Currency, OapError } from 'utils/generated';

type StatusType = 'Success' | 'Failed';

interface Props {
    currency?: Currency;
    status: StatusType;
    refundDetails?: CreatePaymentRefundMutation;
    errors?: OapError[];
}

function RefundStatusModalBody({ refundDetails, status, errors, currency }: Props) {
    const hasLastFour = !!refundDetails?.createPaymentRefund.cardDetails?.last4;

    return (
        <Body>
            <Headline as="h2" size="three">
                {status}
            </Headline>
            {status === 'Success' && (
                <>
                    Refund successful. We have refunded{' '}
                    <CurrencyFormat
                        amount={Math.abs(refundDetails?.createPaymentRefund.amount ?? 0)}
                        currency={currency!}
                    />{' '}
                    {hasLastFour && `to card ending ${refundDetails?.createPaymentRefund.cardDetails?.last4}`}
                </>
            )}
            {errors && status === 'Failed' && errors.map((error) => error.message)}
        </Body>
    );
}

export default RefundStatusModalBody;
