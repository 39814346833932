import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { Row, Col, Input, Headline, Body } from '@sumup/circuit-ui';
import { Taxes } from '@sumup/icons';
import { Location } from 'typings/Setup';

interface Props {
    methods: UseFormReturn<Location>;
}

const StyledHeadline = styled(Headline)`
    margin-bottom: 8px;
`;

const AmountFieldWrapper = styled.div`
    max-width: 410px;
`;

const PercentageIcon = styled(Taxes)`
    position: absolute;
    right: 12px;
    top: 50%;
    transform: scale(0.75) translateY(-50%);
    transform-origin: top;
`;

function TipAmounts({ methods }: Props) {
    const { register, watch } = methods;
    const watchTipsEnabled = watch('tips.enabled', true);

    return (
        <>
            <StyledHeadline as="h3" size="four">
                Customise tip
            </StyledHeadline>
            <Body>
                Choose the amount of tip your guests are allowed to give. The chosen amount will be displayed in the
                consumer app
            </Body>
            <AmountFieldWrapper>
                <Row>
                    <Col span="4">
                        <Input
                            label="Low"
                            placeholder="7"
                            type="number"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...register('tips.amounts.percentageLow', { required: true })}
                            renderSuffix={() => <PercentageIcon />}
                            disabled={!watchTipsEnabled}
                        />
                    </Col>
                    <Col span="4">
                        <Input
                            label="Medium"
                            placeholder="10"
                            type="number"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...register('tips.amounts.percentageMedium', { required: true })}
                            renderSuffix={() => <PercentageIcon />}
                            disabled={!watchTipsEnabled}
                        />
                    </Col>
                    <Col span="4">
                        <Input
                            label="High"
                            placeholder="12"
                            type="number"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...register('tips.amounts.percentageHigh', { required: true })}
                            renderSuffix={() => <PercentageIcon />}
                            disabled={!watchTipsEnabled}
                        />
                    </Col>
                </Row>
            </AmountFieldWrapper>
        </>
    );
}

export default TipAmounts;
