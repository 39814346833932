import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Image } from '@sumup/circuit-ui';

interface NoDataStyleProps {
    hasBorder: boolean;
    small: boolean;
}

const noDataStyles = ({ hasBorder, small }: NoDataStyleProps) => css`
    width: 100%;
    margin-top: 2rem;
    min-height: ${small ? undefined : '60vh'};
    border: ${hasBorder ? '2px solid #e6e6e6' : ''};
    border-radius: ${hasBorder ? '16px' : ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const NoDataWrapper = styled.div<NoDataStyleProps>(noDataStyles);

interface Props {
    noDataMessage?: React.ReactNode;
    hasBorder?: boolean;
    small?: boolean;
}

function NoData({ noDataMessage, hasBorder, small }: Props) {
    return (
        <NoDataWrapper hasBorder={hasBorder || false} small={small || false}>
            <Image style={{ maxWidth: '320px', margin: '1.5rem' }} src="/NoData.svg" alt="no data" />
            <Body style={{ maxWidth: '400px', textAlign: 'center' }} size="one">
                {noDataMessage}
            </Body>
        </NoDataWrapper>
    );
}

export default NoData;

NoData.defaultProps = {
    noDataMessage: 'No data',
    hasBorder: false,
    small: false,
};
