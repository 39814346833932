import React, { useEffect, useState } from 'react';
import { Badge, Body, Button, ButtonGroup } from '@sumup/circuit-ui';
import { useNavigate } from 'react-router-dom';
import { Payout, PayoutListQuery, useGetPayoutsQuery } from 'utils/generated';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import { useIntl } from 'react-intl';
import ContentPane from 'components/layout/ContentPane';
import ListView from 'components/layout/ListView';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import usePagination from 'hooks/usePagination';

function PayoutList() {
    const { nextPageToken, goToNextpage, goToPreviousPage } = usePagination();
    const [listQuery, setListQuery] = useState<PayoutListQuery>();
    const { data, error, isLoading } = useGetPayoutsQuery();
    const navigate = useNavigate();
    const { formatDate } = useIntl();

    const details = (payout: Payout | undefined) => {
        return (
            <Body noMargin>
                <Badge
                    style={{ marginRight: '8px' }}
                    variant={payout?.status.toUpperCase() === 'PAID' ? 'success' : 'neutral'}
                >
                    {payout?.status}
                </Badge>
                {formatDate(payout?.arrivalAt, {
                    dateStyle: 'long',
                })}
            </Body>
        );
    };

    useEffect(() => {
        setListQuery({ ...listQuery, nextToken: nextPageToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPageToken]);

    const getDetails = (x: Payout): ItemProps => ({
        key: x.id,
        variant: 'navigation',
        onClick: () => navigate(x.id, { state: x }),
        label: details(x),
        selected: undefined,
        suffix: <CurrencyFormat amount={x.amount} currency={x.currency} />,
    });

    return (
        <ContentPane>
            <ListView<Payout>
                detailsOpen={false}
                heading={{
                    title: 'Payouts',
                }}
                getDetails={getDetails}
                listItems={data?.getPayouts.items as Payout[]}
                isListScreen
                errors={error}
                isLoading={isLoading}
                noDataMessage="No payouts found"
                bottomListControls={
                    <ButtonGroup align="right">
                        {nextPageToken !== undefined ? (
                            <Button onClick={() => goToPreviousPage()}> Previous </Button>
                        ) : null}
                        {data?.getPayouts?.nextToken ? (
                            <Button onClick={() => goToNextpage(data?.getPayouts.nextToken)}>Next</Button>
                        ) : null}
                    </ButtonGroup>
                }
            />
        </ContentPane>
    );
}

export default PayoutList;
