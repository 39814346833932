import React from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

function CognitoLogout() {
    const { signOut } = useOutletContext<{ signOut: () => void }>();

    signOut();

    return <Navigate to="/" />;
}

export default CognitoLogout;
