import React from 'react';
import { Selector } from '@sumup/circuit-ui';

interface DateRangePresetProps {
    selected: boolean;
    thisPreset: string;
    onClick: React.MouseEventHandler<HTMLInputElement>;
}

export enum PRESETS {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    LASTWEEK = 'Last week',
    LASTTHIRTY = 'Last 30 days',
    CUSTOM = 'Custom',
}

function DateRangePreset({ onClick, selected, thisPreset }: DateRangePresetProps) {
    return (
        <Selector style={{ width: '100%' }} value={thisPreset} onClick={onClick} checked={selected}>
            {PRESETS[thisPreset as keyof typeof PRESETS].valueOf()}
        </Selector>
    );
}

export default DateRangePreset;
