import React from 'react';
import { Card, CardHeader, Headline, Body } from '@sumup/circuit-ui';
import Loading from 'components/layout/Loading';
import {
    PaymentConfiguration,
    useGetMerchantPayoutPaymentDescriptorQuery,
    usePutStatementDescriptorMutation,
} from 'utils/generated';
import { useParams } from 'react-router-dom';
import PayoutBillingDescriptorEdit from './PayoutBillingDescriptorEdit';
import PayoutBillingDescriptorView from './PayoutBillingDescriptorView';

function PayoutBillingDescriptor() {
    const { sectionName } = useParams();

    const { data, isLoading } = useGetMerchantPayoutPaymentDescriptorQuery();
    const { mutateAsync } = usePutStatementDescriptorMutation();

    const onSubmit = async (values: Pick<PaymentConfiguration, 'statementDescriptor'>) => {
        return mutateAsync({ statementDescriptor: values.statementDescriptor as string });
    };

    const defaultValues = data?.getMerchant?.paymentConfiguration ?? undefined;

    const paymentsEnabled = data?.getMerchant?.paymentConfiguration?.enabled;

    const Form =
        sectionName === 'descriptor' ? (
            <PayoutBillingDescriptorEdit
                paymentsEnabled={paymentsEnabled}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
            />
        ) : (
            <PayoutBillingDescriptorView paymentsEnabled={paymentsEnabled} defaultValues={defaultValues} />
        );

    return (
        <Card style={{ width: '100%', gap: '2px' }}>
            <CardHeader>
                <Headline as="h3" size="three" noMargin>
                    Billing descriptor
                </Headline>
            </CardHeader>
            <Body>
                Your billing descriptor is the business name that will appear on your customer&apos;s bank statements.
            </Body>
            {isLoading ? <Loading /> : Form}
        </Card>
    );
}

export default PayoutBillingDescriptor;

PayoutBillingDescriptor.defaultProps = {
    edit: false,
};
