import React, { useEffect } from 'react';

import PageHeader from 'components/layout/PageHeader';
import {
    PutTerminalConfigurationMutationVariables,
    TerminalConfiguration,
    useGetTerminalConfigurationQuery,
    usePutTerminalConfigurationMutation,
} from 'utils/generated';
import { useParams } from 'react-router-dom';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import useErrorHandler from 'hooks/useErrorHandler';
import { TerminalTemplateAppearanceChange } from 'typings/TerminalConfig';
import FormError from 'components/FormError/FormError';
import TerminalTemplateForm from './TerminalTemplateForm';
import TerminalTemplateFormLoading from './TerminalTemplateFormLoading';

type Params = {
    terminalTemplateId: string;
};

function TerminalTemplateDetail() {
    const { terminalTemplateId } = useParams() as Params;
    const { checkValidParam } = useErrorHandler();
    const { data, error, isFetching } = useGetTerminalConfigurationQuery(
        {
            terminalConfigurationId: terminalTemplateId,
        },
        {
            refetchOnWindowFocus: false,
        }
    );
    const { mutateAsync, error: savingError, isLoading: isSavingTerminal } = usePutTerminalConfigurationMutation();

    let name: string | undefined;

    const onSubmit = async (values: PutTerminalConfigurationMutationVariables) => {
        return mutateAsync(values);
    };

    const getDefaultValues = () => {
        if (data?.getTerminalConfiguration) {
            const { kdsConfig, ...terminalConfig }: TerminalConfiguration = { ...data?.getTerminalConfiguration };

            const getScreenSaverUrls = () => terminalConfig?.appearance.images?.screenSaverImageUrls ?? [];

            return {
                ...terminalConfig,
                receipt: {
                    ...terminalConfig.receipt,
                    showPrices: terminalConfig.receipt?.showPrices ?? false,
                    receiptHeaderText: terminalConfig.receipt?.receiptHeaderText ?? null,
                },
                allowedSumupMerchantIds: terminalConfig?.allowedSumupMerchantIds?.map((id) => ({ mid: id })) ?? [],
                appearance: {
                    ...terminalConfig?.appearance,
                    images: {
                        ...terminalConfig?.appearance.images,
                        screenSaverImageUrls: getScreenSaverUrls()?.map((item) => ({ url: item })),
                    },
                },
                kdsConfig: {
                    connectionId: kdsConfig?.connection.id,
                    locationId: kdsConfig?.locationId,
                },
            } as TerminalTemplateAppearanceChange;
        }
        return undefined;
    };

    // This will redirect if the terminalConfig id is bad. Should probably also include a notification.
    useEffect(() => {
        if (error) {
            // eslint-disable-next-line no-useless-escape
            checkValidParam(error, 'Terminal configuration ID not found');
        }
    }, [error, checkValidParam]);

    return (
        <ContentPane>
            <Content>
                <PageHeader title={name || data?.getTerminalConfiguration?.name} showBack />
                <FormError errors={savingError} />
                <FormError errors={error} />
                {isFetching && <TerminalTemplateFormLoading />}
                {!isFetching && (
                    <TerminalTemplateForm
                        defaultValues={getDefaultValues()}
                        onSubmit={onSubmit}
                        isSavingTerminal={isSavingTerminal}
                    />
                )}
            </Content>
        </ContentPane>
    );
}

export default TerminalTemplateDetail;
