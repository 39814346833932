import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { Button, ButtonGroup, Input, SubHeadline, Toggle, TextArea } from '@sumup/circuit-ui';
import {
    Currency,
    KdsConnection,
    PriceBook,
    PutTerminalConfigurationMutation,
    PutTerminalConfigurationMutationVariables,
    TerminalConfigurationInput,
    useGetKdsConnectionsQuery,
    useGetPriceBooksQuery,
} from 'utils/generated';
import Form from 'components/Form';
import 'react-loading-skeleton/dist/skeleton.css';
import SimpleGrid from 'components/layout/SimpleGrid';
import SelectWithController from 'components/ui/components/SelectWithController';
import { LOCALES } from 'utils/constants';
import { TerminalTemplateAppearanceChange } from 'typings/TerminalConfig';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import MultiSelectWithController from 'components/ui/components/MultiSelectWithController';
import FormError from 'components/FormError/FormError';
import LabelWithToolTip from 'components/ui/components/LabelWithToolTip';
import TextInputLoading from 'components/ui/loading/TextInputLoading';
import FormSelect from 'components/FormWithSchema/fields/FormSelect/FormSelect';
import Spacer from 'components/Spacer';
import ScreensaverImageUrlInput from './components/ScreensaverImageUrlInput';
import FormSection from './components/FormSection';
import PinInput from '../../components/ui/forms/PinInput';
import SumUpMerchantIdInput from './components/SumupMerchantIdInput';
import ImageUploadInput from '../../components/UploadInput/ImageUploadInput';

interface Props {
    defaultValues?: Partial<TerminalTemplateAppearanceChange>;
    isSavingTerminal: boolean;
    onSubmit: (values: PutTerminalConfigurationMutationVariables) => Promise<PutTerminalConfigurationMutation | void>;
}

function TerminalTemplateForm({ defaultValues, onSubmit, isSavingTerminal }: Props) {
    const methods = useForm<TerminalTemplateAppearanceChange>({ defaultValues });
    const { data, error, isFetching } = useGetPriceBooksQuery({}, { refetchOnWindowFocus: false });

    const {
        reset,
        register,
        control,
        watch,
        handleSubmit,
        unregister,
        setValue,
        formState: { errors, isDirty },
    } = methods;

    const beforeSubmit = (x: TerminalTemplateAppearanceChange) => {
        // This is all made nessesary by react-hook-forms in ability to handle string arrays.
        const {
            appearance: {
                images: { screenSaverImageUrls },
            },
        } = x;
        const formData: TerminalConfigurationInput = {
            ...x,
            appearance: {
                ...x.appearance,
                images: {
                    ...x.appearance.images,
                    checkoutBannerUrl:
                        x.appearance.images.checkoutBannerUrl !== '' ? x.appearance.images.checkoutBannerUrl : null,
                    screenSaverImageUrls: screenSaverImageUrls?.map((item) => item.url) ?? [],
                },
            },
            priceBooks: x?.priceBooks?.map((item) => ({ id: item.id, name: item.name })) ?? [],
            upsellPriceBook: x.upsellPriceBook
                ? {
                      modalText: x.upsellPriceBook.modalText || null,
                      priceBookId: x.upsellPriceBook.priceBookId,
                  }
                : null,
            allowedSumupMerchantIds:
                x?.allowedSumupMerchantIds?.filter((entry) => entry.mid !== '').map((entry) => entry.mid) ?? [],
            receipt: {
                showPrices: Boolean(x.receipt?.showPrices),
                receiptHeaderText: x.receipt?.receiptHeaderText === '' ? null : x.receipt?.receiptHeaderText,
                print: Boolean(x.receipt?.print),
                additionalReceiptText:
                    x.receipt?.additionalReceiptText === '' ? null : x.receipt?.additionalReceiptText,
            },
        };
        if (formData.kdsConfig?.connectionId == null) {
            formData.kdsConfig = null;
        }
        onSubmit({ terminalConfiguration: formData }).finally(() => reset(x));
    };

    const currencies = Object.keys(Currency) as Array<keyof typeof Currency>;

    const locales = Object.keys(LOCALES) as Array<keyof typeof LOCALES>;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'appearance.images.screenSaverImageUrls',
    });

    const {
        fields: mids,
        append: appendMid,
        remove: removeMid,
    } = useFieldArray({ control, name: 'allowedSumupMerchantIds' });

    const {
        data: kdsConnections,
        error: kdsConnectionErrors,
        isFetching: kdsConnectionsLoading,
    } = useGetKdsConnectionsQuery({}, { refetchOnWindowFocus: false });

    const addScreenSaverImage = (e: ClickEvent<Element>) => {
        e.preventDefault();
        append({ url: '' });
    };

    const removeScreenSaverImage = (e: ClickEvent<Element>, index: number) => {
        e.preventDefault();
        remove(index);
    };

    const handleRemoveMid = (e: ClickEvent<Element>, index: number) => {
        e.preventDefault();
        removeMid(index);
    };

    const handleAddMid = (e: ClickEvent<Element>) => {
        e.preventDefault();
        appendMid({ mid: '' });
    };

    const enumToOptions = (array: unknown[], options: { [x: string]: string }) =>
        array.map((item) => ({
            label: options[item as keyof typeof options],
            value: options[item as keyof typeof options],
        }));

    const getDefaultValue = (value: unknown) => {
        if (value !== undefined) return { label: value, value } as { label: string; value: string };
        return undefined;
    };

    useEffect(() => {
        if (!mids || (mids && mids?.length <= 0)) {
            appendMid({ mid: '' });
        }
    }, [appendMid, mids]);

    const selectedKdsConnectionId = watch('kdsConfig.connectionId');

    const kdsSelected = !!selectedKdsConnectionId;

    useEffect(() => {
        if (!kdsConnectionsLoading && !kdsSelected) {
            setValue('kdsConfig.locationId', '');
            unregister('kdsConfig.locationId');
        }
    }, [kdsSelected, kdsConnectionsLoading, selectedKdsConnectionId, setValue, unregister]);

    const upsellPriceBooksEnabled = Boolean(watch('upsellPriceBook'));

    const selectedPriceBooks = watch('priceBooks');
    const availablePriceBooks = data?.getPriceBooks.filter((priceBook) =>
        selectedPriceBooks?.find((selectedPriceBook) => selectedPriceBook.id === priceBook.id)
    );

    return (
        <FormProvider {...methods}>
            <FormError errors={error} />
            <FormError errors={kdsConnectionErrors} />
            <Form onSubmit={handleSubmit(beforeSubmit)}>
                <FormSection title="Details">
                    <Input
                        validationHint={errors?.name && errors?.name.message}
                        invalid={!!errors?.name}
                        {...register('name', {
                            required: { value: true, message: 'Name is required' },
                        })}
                        label="Name *"
                        showValid={false}
                    />
                </FormSection>
                <FormSection title="Accessibility">
                    <Controller
                        name="showAccessibilityOption"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Toggle
                                label="Show accessibility controls"
                                checkedLabel="Enabled"
                                uncheckedLabel="Disabled"
                                checked={Boolean(value)}
                                onChange={() => onChange(!value)}
                            />
                        )}
                    />
                </FormSection>
                <FormSection title="Security">
                    <PinInput<TerminalTemplateAppearanceChange>
                        path="operatorMenuPin"
                        label="Operator Pin *"
                        pinInputError={errors?.operatorMenuPin}
                        validationRegex={/^[0-9]{6}$/}
                    />
                    <SubHeadline as="h3">Allowed SumUp merchant ids</SubHeadline>
                    {mids.map((field, index) => (
                        <SumUpMerchantIdInput<TerminalTemplateAppearanceChange>
                            index={index}
                            midErrors={errors?.allowedSumupMerchantIds}
                            path="allowedSumupMerchantIds"
                            remove={handleRemoveMid}
                            key={field.id}
                        />
                    ))}
                    <ButtonGroup align="right">
                        <Button onClick={handleAddMid}>Add Merchant Id</Button>
                    </ButtonGroup>
                </FormSection>
                <FormSection title="Price Books">
                    <MultiSelectWithController<PriceBook, TerminalTemplateAppearanceChange>
                        label="Selected Price Books *"
                        control={control}
                        placeholder=""
                        getOptionLabel={(x) => x.name}
                        getOptionValue={(x) => x.id}
                        name="priceBooks"
                        id="priceBooks"
                        options={data?.getPriceBooks}
                        loading={isFetching}
                    />
                    <Spacer space="kilo" />
                    <SubHeadline as="h3">Upsell Pricebook</SubHeadline>
                    <Controller
                        name="upsellPriceBook"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Toggle
                                label="Enable Upsell Price Book"
                                checkedLabel="Enabled"
                                uncheckedLabel="Disabled"
                                checked={Boolean(value)}
                                onChange={() => onChange(!value ? { priceBookId: null, modalText: null } : null)}
                            />
                        )}
                    />
                    {upsellPriceBooksEnabled && availablePriceBooks && (
                        <>
                            <SelectWithController<PriceBook>
                                label="Upsell Price Book *"
                                name="upsellPriceBook.priceBookId"
                                getDefaultValue={(priceBookId: string) =>
                                    availablePriceBooks.find((priceBook) => priceBook.id === priceBookId) ?? undefined
                                }
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Upsell Price Book must be selected if upselling is enabled',
                                    },
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={availablePriceBooks ?? []}
                            />
                            <Spacer space="kilo" />
                            <Input
                                {...register('upsellPriceBook.modalText', {
                                    maxLength: { value: 100, message: '100 character limit' },
                                })}
                                label="Upsell modal text"
                            />
                        </>
                    )}
                </FormSection>
                <FormSection title="Appearance">
                    <SimpleGrid columnTemplate="auto auto">
                        <ImageUploadInput
                            name="appearance.images.logoUrl"
                            label="Logo url"
                            required
                            hasError={!!errors?.appearance?.images?.logoUrl}
                            validationHint={errors?.appearance?.images?.logoUrl?.message}
                        />
                        <ImageUploadInput
                            hasError={!!errors?.appearance?.images?.checkoutBannerUrl}
                            label="Checkout banner url (6:1 Aspect Ratio)"
                            required={false}
                            name="appearance.images.checkoutBannerUrl"
                            validationHint={errors?.appearance?.images?.checkoutBannerUrl?.message}
                        />
                    </SimpleGrid>
                    <SubHeadline as="h3">Screensavers</SubHeadline>
                    {fields.map((item, index) => (
                        <ScreensaverImageUrlInput<TerminalTemplateAppearanceChange>
                            id={item.id}
                            remove={removeScreenSaverImage}
                            path="appearance.images.screenSaverImageUrls"
                            index={index}
                            screensaverErrors={errors?.appearance?.images?.screenSaverImageUrls}
                        />
                    ))}
                    <ButtonGroup align="right">
                        <Button stretch={false} onClick={addScreenSaverImage}>
                            Add screensaver
                        </Button>
                    </ButtonGroup>
                    <SubHeadline style={{ marginTop: '20px' }} as="h3">
                        Colours
                    </SubHeadline>
                    <SimpleGrid columnTemplate="auto auto">
                        <Input
                            type="color"
                            {...register('appearance.colors.accent')}
                            label={
                                <LabelWithToolTip
                                    labelText="Accent colour"
                                    tipText="The accent colour, contrasts with the primary (currently used on the category list)"
                                />
                            }
                            aria-details="The primary brand colour, used for important action buttons such as success"
                        />
                        <Input
                            type="color"
                            {...register('appearance.colors.primary')}
                            label={
                                <LabelWithToolTip
                                    labelText="Primary colour"
                                    tipText="The primary brand colour, used for important action buttons such as success"
                                />
                            }
                        />
                        <Input
                            type="color"
                            {...register('appearance.colors.primaryText')}
                            label={
                                <LabelWithToolTip
                                    labelText="Primary text colour"
                                    tipText="The colour of text or other elements that sits on top of the primary (borders, icons, text)"
                                />
                            }
                        />
                        <Input
                            type="color"
                            {...register('appearance.colors.background')}
                            label={
                                <LabelWithToolTip
                                    labelText="Background colour"
                                    tipText="The background for the Kiosk (used on landing, pricebook selector, checkout and confirmation pages)"
                                />
                            }
                        />
                        <Input
                            type="color"
                            {...register('appearance.colors.text')}
                            label={
                                <LabelWithToolTip
                                    labelText="Text"
                                    tipText="The main text colour which sits on white backgrounds (most likely only a dark colour for now)"
                                />
                            }
                        />
                        <Input
                            type="color"
                            {...register('appearance.colors.warningText')}
                            label={
                                <LabelWithToolTip
                                    labelText="Warning text colour"
                                    tipText="Colour for our error buttons, controls borders, icons, text"
                                />
                            }
                        />
                    </SimpleGrid>
                </FormSection>

                <FormSection title="Localisation">
                    <SimpleGrid>
                        <SelectWithController<{ label: string; value: string }>
                            label="Currency *"
                            name="localisation.currency"
                            getDefaultValue={getDefaultValue}
                            rules={{ required: { value: true, message: 'Currency is required' } }}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={enumToOptions(currencies, Currency)}
                        />
                        <SelectWithController<{ label: string; value: string }>
                            label="Default locale *"
                            name="localisation.defaultLocale"
                            getDefaultValue={getDefaultValue}
                            rules={{ required: { value: true, message: 'Currency is required' } }}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={enumToOptions(locales, LOCALES)}
                        />
                    </SimpleGrid>
                </FormSection>
                <FormSection title="Customer Messages *">
                    <TextArea
                        {...register('strings.globalMessageText', {
                            required: { value: true, message: 'Global message text is required' },
                        })}
                        label="Global message text *"
                        invalid={!!errors.strings?.globalMessageText}
                        validationHint={errors.strings?.globalMessageText?.message}
                    />
                    <Input
                        {...register('strings.orderConfirmationText', {
                            maxLength: { value: 200, message: 'Charecter limit 200' },
                            required: { value: true, message: 'Order confirmation text is required' },
                        })}
                        label="Order confirmation text *"
                        validationHint={
                            errors?.strings?.orderConfirmationText && errors?.strings?.orderConfirmationText?.message
                        }
                        invalid={!!errors.strings?.orderConfirmationText}
                    />
                </FormSection>
                <FormSection title="Receipt">
                    <Controller
                        name="receipt.print"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Toggle
                                label="Enable printing"
                                checkedLabel="Enabled"
                                uncheckedLabel="Disabled"
                                checked={Boolean(value)}
                                onChange={() => onChange(!value)}
                            />
                        )}
                    />
                    <Controller
                        name="receipt.showPrices"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Toggle
                                label="Show prices"
                                checkedLabel="Enabled"
                                uncheckedLabel="Disabled"
                                checked={Boolean(value)}
                                onChange={() => onChange(!value)}
                            />
                        )}
                    />
                    <TextArea {...register('receipt.receiptHeaderText')} label="Receipt header text" />
                    <Input
                        {...register('receipt.additionalReceiptText', {
                            maxLength: { value: 100, message: '100 character limit' },
                        })}
                        label="Additional receipt text"
                    />
                </FormSection>
                <FormSection title="KDS Connection">
                    <SimpleGrid>
                        {kdsConnectionsLoading ? (
                            <TextInputLoading />
                        ) : (
                            <FormSelect<TerminalTemplateAppearanceChange, KdsConnection>
                                label="Connection"
                                name="kdsConfig.connectionId"
                                placeholder="No KDS or use POS KDS"
                                getOptionLabel={(option) => `${option.name} (${option.kdsSystem})`}
                                getOptionValue={(option) => option.id}
                                options={kdsConnections?.getKdsConnections || []}
                                // eslint-disable-next-line react/jsx-boolean-value
                                isClearable={true}
                            />
                        )}
                        {!kdsConnectionsLoading && kdsSelected && (
                            <Input
                                {...register('kdsConfig.locationId', {
                                    required: { value: true, message: 'KDS location id is required' },
                                })}
                                noMargin
                                label="Location ID *"
                                validationHint={
                                    errors.kdsConfig && 'locationId' in errors.kdsConfig
                                        ? errors.kdsConfig.locationId?.message
                                        : undefined
                                }
                                invalid={!!(errors?.kdsConfig && 'locationId' in errors.kdsConfig)}
                            />
                        )}
                    </SimpleGrid>
                </FormSection>
                <ButtonGroup align="left">
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={isSavingTerminal}
                        loadingLabel="Loading"
                        disabled={!isDirty}
                    >
                        Save
                    </Button>
                </ButtonGroup>
            </Form>
        </FormProvider>
    );
}

export default TerminalTemplateForm;
