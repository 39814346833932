import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { light, Theme } from '@sumup/design-tokens';
import { OapError } from 'utils/generated';
import { TimeLineItem } from 'helpers/listHelper';
import { Body, Button, Headline, ListItemGroup } from '@sumup/circuit-ui';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import NoData from 'components/NoData';
import FormError from 'components/FormError/FormError';
import PageHeader from './PageHeader';
import Loading from './Loading';

interface ListViewProps<T> {
    heading: {
        title: string;
        cta?: {
            buttonText: string;
            callBack: () => void;
        };
        showBack?: boolean;
    };
    getDetails: (x: T) => ItemProps;
    detailsOpen: boolean;
    timelineListItems?: TimeLineItem<T>[];
    isLoading?: boolean;
    listItems?: T[] | undefined;
    errors?: OapError[] | null;
    bottomListControls?: React.ReactNode;
    isListScreen?: boolean;
    noDataMessage?: React.ReactNode;
    noDataShowBorder?: boolean;
    filters?: React.ReactNode;
}

interface ListViewWrapperProps {
    detailsOpen: boolean;
    isListScreen?: boolean;
}

const listViewBaseStyles = ({ theme, detailsOpen, isListScreen }: { theme: Theme } & ListViewWrapperProps) => {
    const displayOnMobile = () => {
        if (isListScreen && detailsOpen) return 'none';
        if (isListScreen && !detailsOpen) return 'flex';
        return undefined;
    };

    return css`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: ${!isListScreen ? '1rem' : 'unset'};
        margin-bottom: 1rem;
        max-width: ${detailsOpen ? '450px' : '900px'};
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: ${isListScreen ? '8px' : 'unset'};
        padding-right: ${isListScreen ? '8px' : 'unset'};
        ${theme.mq.untilMega} {
            display: ${displayOnMobile()};
        }
    `;
};

const ListViewWrapper = styled.div<{ theme: Theme } & ListViewWrapperProps>(listViewBaseStyles);

function ListView<T>({
    heading,
    listItems,
    timelineListItems,
    isLoading,
    errors,
    getDetails,
    detailsOpen,
    bottomListControls,
    isListScreen,
    noDataMessage,
    noDataShowBorder,
    filters,
}: ListViewProps<T>) {
    return (
        <ListViewWrapper theme={light} detailsOpen={detailsOpen} isListScreen={isListScreen}>
            {isListScreen ? (
                <PageHeader
                    title={heading.title}
                    showBack={heading.showBack}
                    cta={heading.cta && <Button onClick={heading.cta.callBack}>{heading.cta?.buttonText}</Button>}
                />
            ) : (
                <Headline size="four" as="h3">
                    {heading.title}
                </Headline>
            )}
            {filters}
            <FormError errors={errors} />
            {isLoading && <Loading />}
            {!isLoading &&
                timelineListItems &&
                timelineListItems.length > 0 &&
                timelineListItems.map((group) => (
                    <Body>
                        <ListItemGroup
                            label={group.groupName}
                            items={group.listItems.map((x) => getDetails(x)) ?? []}
                        />
                    </Body>
                ))}
            {!isLoading && listItems && listItems.length > 0 && (
                <Body>
                    <ListItemGroup
                        label={`${heading.title}list`}
                        hideLabel
                        style={{ marginTop: '28px' }}
                        items={listItems.map((x) => getDetails(x))}
                    />
                </Body>
            )}
            {!isLoading &&
                !errors &&
                (!listItems || listItems.length <= 0) &&
                (!timelineListItems || timelineListItems.length <= 0) && (
                    <NoData hasBorder={noDataShowBorder} noDataMessage={noDataMessage} />
                )}
            {(listItems || timelineListItems) && bottomListControls && bottomListControls}
        </ListViewWrapper>
    );
}

export default ListView;
