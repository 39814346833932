import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Tooltip as CUIToolTip } from '@sumup/circuit-ui';
import { Info } from '@sumup/icons';

export const TooltipContainer = styled('div')`
    position: relative;
    line-height: 0;
    margin: 0px 0.5rem;
`;

interface Props {
    tipText: string;
}

function Tooltip({ tipText }: Props) {
    const [showText, toggleShowText] = useState(false);

    return (
        <TooltipContainer onMouseOver={() => toggleShowText(true)} onMouseLeave={() => toggleShowText(false)}>
            <Info size="16" />
            <CUIToolTip
                position="right"
                align="top"
                style={{
                    minWidth: '200px',
                    visibility: showText ? 'visible' : 'hidden',
                    opacity: showText ? 1 : 0,
                }}
            >
                {tipText}
            </CUIToolTip>
        </TooltipContainer>
    );
}

export default Tooltip;
