import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
    date: string | undefined | null;
}

function FormatedDateTime({ date }: Props) {
    const { formatDate } = useIntl();
    return (
        <>
            {date &&
                Date.parse(date) !== Date.now() &&
                formatDate(date, {
                    dateStyle: 'medium',
                })}
            &nbsp;
            {date &&
                formatDate(date, {
                    hour: 'numeric',
                    minute: 'numeric',
                })}
        </>
    );
}

export default FormatedDateTime;
