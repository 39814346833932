export const emptyPerson = {
    firstName: '',
    address: { city: '', country: '', line1: '', postalCode: '', line2: '' },
    dateOfBirth: '',
    email: '',
    lastName: '',
    phone: '',
    relationship: {
        director: false,
        executive: false,
        owner: false,
        title: '',
        representative: false,
    },
};

export const defaultServiceConfig = {
    enabled: false,
    percentageLow: 10.0,
    percentageMedium: 12.5,
    percentageHigh: 15.0,
};

export default { emptyPerson };

export enum LOCALES {
    'bg-BG' = 'bg-BG',
    'cs-CZ' = 'cs-CZ',
    'da-DK' = 'da-DK',
    'de-AT' = 'de-AT',
    'de-DE' = 'de-DE',
    'de-CH' = 'de-CH',
    'de-LU' = 'de-LU',
    'el-CY' = 'el-CY',
    'el-GR' = 'el-GR',
    'en-GB' = 'en-GB',
    'en-IE' = 'en-IE',
    'en-MT' = 'en-MT',
    'en-US' = 'en-US',
    'es-CL' = 'es-CL',
    'es-ES' = 'es-ES',
    'et-EE' = 'et-EE',
    'fi-FI' = 'fi-FI',
    'fr-BE' = 'fr-BE',
    'fr-CH' = 'fr-CH',
    'fr-FR' = 'fr-FR',
    'fr-LU' = 'fr-LU',
    'hu-HU' = 'hu-HU',
    'it-CH' = 'it-CH',
    'it-IT' = 'it-IT',
    'lt-LT' = 'lt-LT',
    'lv-LV' = 'lv-LV',
    'nb-NO' = 'nb-NO',
    'nl-BE' = 'nl-BE',
    'nl-NL' = 'nl-NL',
    'pl-PL' = 'pl-PL',
    'pt-BR' = 'pt-BR',
    'pt-PT' = 'pt-PT',
    'sk-SK' = 'sk-SK',
    'sl-SI' = 'sl-SI',
    'sv-SE' = 'sv-SE',
    'hr-HR' = 'hr-HR',
}
