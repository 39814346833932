import React, { useEffect } from 'react';
import Body from '@sumup/circuit-ui/dist/es/components/Body';
import { CreatePaymentRefundMutationVariables, Currency } from 'utils/generated';
import { Button, ButtonGroup, CurrencyInput, Headline, SubHeadline, TextArea } from '@sumup/circuit-ui';
import currencyFormatter from 'currency.js';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import Form from 'components/Form';

interface Props {
    currency: Currency;
    paymentAmount: number;
    totalRefunds: number;
    defaultValues: Partial<CreatePaymentRefundMutationVariables>;
    onSubmit: (values: CreatePaymentRefundMutationVariables) => Promise<unknown>;
    closeCallback: () => void;
}

function RefundModalBody({ currency, paymentAmount, totalRefunds, defaultValues, onSubmit, closeCallback }: Props) {
    const {
        reset,
        register,
        handleSubmit,
        watch,
        formState: { isSubmitting, errors, isDirty },
    } = useForm<CreatePaymentRefundMutationVariables>({ defaultValues });

    const intl = useIntl();

    const watchedAmount = watch('amount', 0);

    const maxRefundAmount = paymentAmount - Math.abs(totalRefunds);

    useEffect(() => {
        register('paymentId');
    }, [register]);

    const beforeSubmit = (x: CreatePaymentRefundMutationVariables) => {
        const newValues = x;
        newValues.amount = currencyFormatter(x.amount).intValue;
        return newValues;
    };

    const closeModal = (e: ClickEvent<Element>) => {
        e.preventDefault();
        closeCallback();
    };

    const refundAmountError = (type: string) => {
        const maxRefundForDisplay = currencyFormatter(maxRefundAmount, {
            fromCents: true,
            precision: 2,
        }).value;
        if (type === 'max')
            return `Refund amount too high, should be ${intl.formatNumber(maxRefundForDisplay, {
                currency,
                style: 'currency',
            })} or less`;
        if (type === 'required') return 'Refund amount is required.';
        return 'Input is invalid';
    };

    return (
        <Body>
            <Headline as="h2" size="three">
                Refund
            </Headline>
            <SubHeadline style={{ textAlign: 'center' }} as="h2">
                The selected payment was
            </SubHeadline>
            <Headline style={{ textAlign: 'center' }} size="one" as="h2">
                <CurrencyFormat amount={paymentAmount} currency={currency} />
            </Headline>
            {totalRefunds !== 0 && (
                <Headline style={{ textAlign: 'center' }} size="four" as="h3">
                    <CurrencyFormat amount={Math.abs(totalRefunds)} currency={currency} /> has already been refunded.
                </Headline>
            )}
            {maxRefundAmount > 0 && (
                <Form
                    onSubmit={handleSubmit((x) =>
                        onSubmit(beforeSubmit(x)).finally(() => reset({ amount: 0, notes: '' }))
                    )}
                >
                    <CurrencyInput
                        currency={currency}
                        label="Refund amount"
                        textAlign="left"
                        validationHint={errors.amount && refundAmountError(errors.amount.type)}
                        invalid={errors.amount && true}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('amount', {
                            required: true,
                            validate: {
                                max: (x) => currencyFormatter(x).intValue <= maxRefundAmount,
                            },
                        })}
                    />
                    <TextArea
                        label="Notes"
                        validationHint={errors.notes && 'Please provide information about the refund'}
                        invalid={errors.notes && true}
                        {...register('notes', { required: true })}
                    />
                    <ButtonGroup>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={isSubmitting}
                            destructive
                            disabled={!isDirty}
                            loadingLabel="Processing..."
                        >
                            {`Refund ${intl.formatNumber(currencyFormatter(watchedAmount, { precision: 2 }).value, {
                                currency,
                                style: 'currency',
                            })}`}
                        </Button>
                        <Button onClick={closeModal}>Cancel</Button>
                    </ButtonGroup>
                </Form>
            )}
        </Body>
    );
}

export default RefundModalBody;
