import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useUserInfo } from './UserInfoContext';

interface Props {
    children: React.ReactNode;
}

interface FeatureFlagState {
    kioskConfigEnabled: boolean;
}

const FeatureFlagContext = React.createContext<FeatureFlagState>({ kioskConfigEnabled: false });

function FeatureFlagProvider({ children }: Props) {
    const [kioskConfigEnabled, setKioskConfigEnabled] = useState(false);
    const { userInfo, isUpdating } = useUserInfo();

    useEffect(() => {
        const isInternalUser = userInfo?.email.includes('@sumup.com') || userInfo?.email.includes('@supay.test');
        if (isUpdating) return;
        if (isInternalUser) {
            setKioskConfigEnabled(isInternalUser);
        }
        if (!isInternalUser && userInfo) {
            const isAllowed = String(process.env.REACT_APP_KIOSK_ALLOWED).split(',').includes(userInfo.id);
            setKioskConfigEnabled(isAllowed);
        }
    }, [isUpdating, userInfo]);

    const state = useMemo(
        () => ({
            kioskConfigEnabled,
        }),
        [kioskConfigEnabled]
    );

    return <FeatureFlagContext.Provider value={state}>{children}</FeatureFlagContext.Provider>;
}

export default FeatureFlagProvider;

export function useFeatureFlags() {
    const { kioskConfigEnabled } = useContext(FeatureFlagContext);
    return { kioskConfigEnabled };
}
