import { css, CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { Card } from '@sumup/circuit-ui';

const imageCardContainerStyles = ({
    gridTemplateRows,
    backgroundColor,
    borderColor,
}: {
    gridTemplateRows: CSSObject['gridTemplateRows'];
    backgroundColor?: CSSObject['backgroundColor'];
    borderColor?: CSSObject['borderColor'];
}) => css`
    padding: 0;
    background-color: ${backgroundColor};
    overflow: hidden;
    min-width: 280px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-template-rows: ${gridTemplateRows};
    border-color: ${borderColor};
`;

const ImageCardContainer = styled(Card)(imageCardContainerStyles);

export default ImageCardContainer;
