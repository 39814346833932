import { css } from '@emotion/css';
import styled from '@emotion/styled';

const contentBaseStyles = () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ContentPane = styled.div(contentBaseStyles);

export default ContentPane;
