import React, { useEffect } from 'react';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import Loading from 'components/layout/Loading';

function ConnectPos() {
    useEffect(() => {
        window.location.href = `${process.env.REACT_APP_GOODTILL_BACKOFFICE_URL}/magicpay.html?redirectUrl=${window.location.origin}/pos-connections/create`;
    }, []);

    return (
        <ContentPane>
            <Content>
                <Loading />
            </Content>
        </ContentPane>
    );
}

export default ConnectPos;
