import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface ImageProps {
    backgroundColor?: string;
}

const ImageContainer = styled.div<ImageProps>(
    ({ backgroundColor }) => css`
        display: flex;
        justify-content: center;
        background-color: ${backgroundColor || 'transparent'};
    `
);

export default ImageContainer;
