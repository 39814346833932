import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import { light } from '@sumup/design-tokens';

interface Props {
    url?: string;
}

const iframeBaseStyles = () => css`
    flex: 1 1 auto;
    padding: 0;
    margin: 8px auto;
    width: 100%;
    max-height: 65vh;
    min-height: calc(100vh - 380px);
    ${light.mq.untilKilo} {
        display: none;
    }
`;

const IFrameComponent = styled('iframe')(iframeBaseStyles);

function IFrame({ url }: Props) {
    return url !== undefined ? <IFrameComponent width="100%" height="100%" src={url} /> : <Skeleton />;
}

export default IFrame;
