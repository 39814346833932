import { Card, CardHeader, Headline } from '@sumup/circuit-ui';
import React from 'react';

interface Props {
    title: string;
    children: React.ReactNode;
}

function FormSection({ title, children }: Props) {
    return (
        <Card style={{ width: '100%' }}>
            <CardHeader>
                <Headline as="h3" size="three" noMargin>
                    {title}
                </Headline>
            </CardHeader>
            {children}
        </Card>
    );
}

export default FormSection;
