import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input } from '@sumup/circuit-ui';

const displayOnlyInputStyles = () => css`
    box-shadow: none;
    background: transparent;
    border: none;
    :hover {
        box-shadow: none;
    }
    :focus {
        box-shadow: none;
    }
`;

const DisplayOnlyInput = styled(Input)(displayOnlyInputStyles);

export default DisplayOnlyInput;
