import styled from '@emotion/styled';
import { Body, Card } from '@sumup/circuit-ui';
import React from 'react';

const OrderDetailsCard = styled(Card)`
    margin-block: 45px;
`;

function OrderPreviewUnavailable() {
    return (
        <OrderDetailsCard>
            <Body noMargin> Order details are not available for Kiosk payments yet. </Body>
        </OrderDetailsCard>
    );
}

export default OrderPreviewUnavailable;
