import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Badge, Button, Headline } from '@sumup/circuit-ui';
import { PosSystem, useGetRecentPaymentsQuery } from 'utils/generated';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import { useQueryClient } from 'react-query';
import { PaymentListItem } from 'typings/Payments';
import { tableNumberFormatter, upperFirstChar } from 'helpers/textHelpers';
import Loading from 'components/layout/Loading';
import NoData from 'components/NoData';
import FormatedDateTime from 'components/ui/DateTimeFormat';
import moment from 'moment';
import { css } from '@emotion/react';
import { isOapError, isPosSystem } from 'typings/typePredicates';
import EmbeddableContent from 'components/layout/EmbeddableContent';
import FormError from 'components/FormError/FormError';
import MagicPayTeaser from './MagicPayTeaser';

const DetailsWrapper = styled.div`
    display: grid;
    border: 2px solid #dfdfdf;
    background: white;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    grid-template-columns: 5.5fr 2fr 1fr 1.5fr;
`;

interface detailCellProps {
    alignItems: string;
}

const detailCellBaseStyle = ({ alignItems }: detailCellProps) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${alignItems};
    gap: 2px;
`;

const DetailCell = styled.div<detailCellProps>(detailCellBaseStyle);

const HeadingWrapperGt = styled.div`
    position: sticky;
    top: 0;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2.5rem;
    padding-right: 8px;
    padding-left: 8px;
`;

function Details({ payment }: { payment: PaymentListItem | undefined }) {
    const tableName = payment?.order?.posTableName;

    return (
        <DetailsWrapper>
            <DetailCell alignItems="flex-start">
                <Headline size="four" as="h2" noMargin>
                    {tableNumberFormatter(tableName)}
                </Headline>
                <FormatedDateTime date={payment?.createdAt} />
            </DetailCell>
            <DetailCell style={{ fontWeight: 600 }} alignItems="flex-start">
                {moment(payment?.createdAt).fromNow()}
            </DetailCell>
            <DetailCell alignItems="flex-end">
                {payment && payment.amount < 0 && (
                    <Badge style={{ backgroundColor: '#F5C625', color: 'black' }}>Refund</Badge>
                )}
            </DetailCell>
            <DetailCell style={{ fontWeight: 700, fontSize: '1.2em' }} alignItems="flex-end">
                {payment && <CurrencyFormat amount={payment.amount} currency={payment.currency} />}
            </DetailCell>
        </DetailsWrapper>
    );
}

function RecentPayments() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const queryClient = useQueryClient();

    const posSystemParam = upperFirstChar(params.get('posSystem'));

    const posSystem = posSystemParam && isPosSystem(posSystemParam) ? PosSystem[posSystemParam] : undefined;

    const locationIdFromParams = () => {
        const outletId = params.get('outletId');
        if (posSystem?.toUpperCase() === PosSystem.Goodtill?.toUpperCase() && outletId) {
            return outletId;
        }
        return undefined;
    };

    const { data, isLoading, error } = useGetRecentPaymentsQuery(
        {
            posLocationId: locationIdFromParams()!,
            posSystem: posSystem!,
        },
        {
            enabled: Boolean(locationIdFromParams()),
            retry: 1,
        }
    );

    const refresh = () =>
        queryClient.resetQueries({
            queryKey: ['GetRecentPayments'],
        });

    if (isOapError(error)) {
        const filteredError = error.find((e) => e.errorType === 'NOT_FOUND');
        if (filteredError) return <MagicPayTeaser />;
    }

    return (
        <EmbeddableContent>
            <HeadingWrapperGt>
                <Headline noMargin as="h1" size="three">
                    Magic Pay Transactions
                </Headline>
                <Button onClick={refresh}>Refresh</Button>
            </HeadingWrapperGt>
            <FormError errors={error} />
            {isLoading && <Loading />}
            {data?.getRecentPaymentsPos && data?.getRecentPaymentsPos.map((item) => <Details payment={item} />)}
            {!isLoading && !error && (!data?.getRecentPaymentsPos || data?.getRecentPaymentsPos.length <= 0) && (
                <NoData hasBorder={false} noDataMessage="No transactions found" />
            )}
        </EmbeddableContent>
    );
}

export default RecentPayments;
