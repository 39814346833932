import styled from '@emotion/styled';
import { light, Theme } from '@sumup/design-tokens';
import { Body } from '@sumup/circuit-ui';
import { css } from '@emotion/react';

const pageTitleStyles = (theme: Theme) => css`
    ${theme.mq.tera} {
        display: none;
    }
    ${theme.mq.untilTera} {
        padding-left: 8px;
    }
`;

const MobilePageHeader = styled(Body)(pageTitleStyles(light));

export default MobilePageHeader;
