import React from 'react';
import { Button, Card, CardHeader, Headline, ListItemGroup, useModal } from '@sumup/circuit-ui';
import { useGetTerminalsQuery } from 'utils/generated';
import Skeleton from 'react-loading-skeleton';
import { Edit } from '@sumup/icons';
import { useQueryClient } from 'react-query';
import NoData from 'components/NoData';
import LocationTerminalConfigModal from './LocationTerminalTemplateModal';

function TerminalListCard({ locationId }: { locationId: string }) {
    const { data: terminals, isLoading: terminalsLoading } = useGetTerminalsQuery({ locationId });

    const { setModal, removeModal } = useModal();

    const queryClient = useQueryClient();

    const openTerminalModal = (terminal?: { id: string; name: string; configurationId: string }) => {
        setModal({
            children: (
                <LocationTerminalConfigModal
                    title={terminal ? 'Edit Terminal' : 'Create Terminal'}
                    submitButtonName={terminal ? 'Update' : 'Create'}
                    closeCallback={() => {
                        queryClient.invalidateQueries([
                            'GetTerminals',
                            {
                                locationId,
                            },
                        ]);
                        removeModal();
                    }}
                    locationId={locationId}
                    terminal={terminal || null}
                />
            ),
            variant: 'immersive',
            closeButtonLabel: 'Close modal',
            id: terminal?.id || 'new-terminal',
            preventClose: true,
        });
    };

    return (
        <Card style={{ width: '100%' }}>
            <CardHeader>
                <Headline as="h3" size="three" noMargin>
                    Terminals
                </Headline>
                <div>
                    <Button type="button" onClick={() => openTerminalModal()} variant="primary">
                        Add Terminal
                    </Button>
                </div>
            </CardHeader>
            {terminalsLoading && <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />}
            {!terminalsLoading && terminals?.getLocation?.terminals && terminals?.getLocation?.terminals?.length > 0 && (
                <ListItemGroup
                    label="Terminals"
                    variant="inset"
                    hideLabel
                    items={terminals.getLocation.terminals.map((terminal) => ({
                        key: terminal.id,
                        label: terminal.name,
                        details: terminal.configuration.name,
                        variant: 'action',
                        suffix: (
                            <Edit
                                onClick={() =>
                                    openTerminalModal({
                                        id: terminal.id,
                                        name: terminal.name,
                                        configurationId: terminal.configuration.id,
                                    })
                                }
                            />
                        ),
                    }))}
                />
            )}
            {!terminalsLoading &&
                terminals?.getLocation?.terminals &&
                terminals?.getLocation?.terminals?.length === 0 && <NoData noDataMessage="No terminals exist" small />}
        </Card>
    );
}

export default TerminalListCard;
