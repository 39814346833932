import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from '../../../ui/components/Select';
import { FormSelectProps } from './FormSelect.types';

/**
 * @params props from RQSelect
 * @types <FormType, SelectType> FormType is the type used in the from. Used for path resolution]
 * SelectType is the type used in the select component.
 */
function FormSelect<FormPaths, SelectType>(props: FormSelectProps<FormPaths, SelectType>) {
    const { name, options, getOptionValue, isClearable, placeholder } = props;
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                <Select<SelectType>
                    {...props}
                    id={name}
                    errorMessage={error?.message}
                    onChange={(x, a) => {
                        if (a.action === 'clear') onChange(null);
                        if (a.action === 'select-option') onChange(getOptionValue(x as SelectType));
                    }}
                    value={options ? options?.find((x) => getOptionValue(x as SelectType) === value) : undefined}
                    formError={invalid}
                    placeholder={placeholder}
                    isClearable={isClearable}
                />
            )}
        />
    );
}

export default FormSelect;
