import React from 'react';
import { UseFormReturn, FieldError } from 'react-hook-form';
import { Input } from '@sumup/circuit-ui';
import { Location } from 'typings/Setup';

interface Props {
    errors?: FieldError;
    methods: UseFormReturn<Location>;
}

function MenuUrl({ errors, methods }: Props) {
    const { register } = methods;

    return (
        <Input
            label="Menu URL"
            placeholder="https://mycafe.com/menu"
            optionalLabel="Optional"
            invalid={errors && true}
            validationHint={errors?.message}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('menuUrl', { required: true })}
        />
    );
}

export default MenuUrl;

MenuUrl.defaultProps = {
    errors: undefined,
};
