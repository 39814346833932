import React from 'react';
import { ListItem } from '@sumup/circuit-ui';
import { useGetPosTablesQuery } from 'utils/generated';
import Loading from 'components/layout/Loading';
import FormError from 'components/FormError/FormError';

interface Props {
    locationId: string;
    didSelect: (posReference: string, tableName: string) => void;
}

function SelectTableList({ locationId, didSelect }: Props) {
    const { data, error, isLoading } = useGetPosTablesQuery({ locationId });

    return (
        <>
            <FormError errors={error} />
            {isLoading && <Loading />}
            {!isLoading &&
                data?.getPosTables?.map((x) => (
                    <ListItem
                        variant="navigation"
                        style={{ marginBottom: '10px' }}
                        onClick={() => didSelect(x.posReference, x.name)}
                        label={x.name}
                    />
                ))}
        </>
    );
}

export default SelectTableList;
