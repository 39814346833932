import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Toggle } from '@sumup/circuit-ui';
import { Location } from 'typings/Setup';

interface Props {
    methods: UseFormReturn<Location>;
}

function TipsEnable({ methods }: Props) {
    const { control } = methods;

    return (
        <Controller
            name="tips.enabled"
            control={control}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
                <Toggle
                    label="Activate tips on the consumer app"
                    checkedLabel="Enabled"
                    uncheckedLabel="Disabled"
                    checked={Boolean(value)}
                    onChange={() => onChange(!value)}
                />
            )}
        />
    );
}

export default TipsEnable;
