import React from 'react';
import styled from '@emotion/styled';
import Tooltip from './ToolTip';

export const LabelWithToolTipContainer = styled('div')`
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    margin: 0px;
    padding: 0px;
`;

interface Props {
    tipText: string;
    labelText: string;
}

function LabelWithToolTip({ tipText, labelText }: Props) {
    return (
        <LabelWithToolTipContainer>
            {labelText}
            <Tooltip tipText={tipText} />
        </LabelWithToolTipContainer>
    );
}

export default LabelWithToolTip;
