/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, ButtonGroup, CardHeader } from '@sumup/circuit-ui';
import { KdsConnectionInput, KdsSystem, PutKdsConnectionMutation, usePutKdsConnectionMutation } from 'utils/generated';
import SimpleGrid from 'components/layout/SimpleGrid';
import styled from '@emotion/styled';
import FormWithSchema from 'components/FormWithSchema/FormWithSchema';
import FormInput from 'components/FormWithSchema/fields/FormInput/FormInput';
import FormSelect from 'components/FormWithSchema/fields/FormSelect/FormSelect';
import FormError from 'components/FormError/FormError';
import KdsConnectionSchema from './KdsConnectionModal.schema';
import { kdsConnectionModalProps } from './KdsConnectionModal.types';

const ModalBody = styled.div`
    margin: 8px;
    padding: 0px;
    display: flex;
    flex-grow: 2;
    gap: 20px;
    flex-direction: column;
    overflow: visible;
`;

function KdsConnectionModal({ title, submitButtonName, kdsConnection, closeCallback }: kdsConnectionModalProps) {
    const { mutateAsync, isLoading, error: mutationError, isSuccess } = usePutKdsConnectionMutation();

    useEffect(() => {
        if (isSuccess) {
            closeCallback();
        }
    }, [isSuccess, closeCallback]);

    const kdsSystems = (Object.keys(KdsSystem) as Array<keyof typeof KdsSystem>).map((item) => ({
        label: item,
        value: item,
    }));

    return (
        <ModalBody>
            <CardHeader closeButtonLabel="Close" onClose={closeCallback}>
                {title}
            </CardHeader>
            <FormError errors={mutationError} />

            <FormWithSchema<KdsConnectionInput, PutKdsConnectionMutation>
                schema={KdsConnectionSchema}
                onSubmit={(x) =>
                    mutateAsync({ kdsConnection: { ...x, id: x.id === 'new-kds-connection' ? null : x.id } })
                }
                initialValues={kdsConnection || undefined}
            >
                <SimpleGrid columnTemplate="1fr">
                    <FormSelect<KdsConnectionInput, { label: string; value: string }>
                        name="kdsSystem"
                        options={kdsSystems}
                        getOptionLabel={(x) => x.label}
                        getOptionValue={(x) => KdsSystem[x.value as keyof typeof KdsSystem]}
                        label="KDS System *"
                    />
                    <FormInput name="config.clientId" label="Client ID *" />
                    <FormInput name="config.clientSecret" label="Client Secret *" />
                </SimpleGrid>
                <ButtonGroup align="right">
                    {kdsConnection?.id === 'new-kds-connection' ? (
                        <Button disabled={isLoading} variant="primary" type="submit">
                            {submitButtonName}
                        </Button>
                    ) : null}
                    <Button variant="secondary" onClick={() => closeCallback()} type="button">
                        Cancel
                    </Button>
                </ButtonGroup>
            </FormWithSchema>
        </ModalBody>
    );
}

export default KdsConnectionModal;
