import React from 'react';
import { PosConnection, useGetPosConnectionsQuery } from 'utils/generated';
import ContentPane from 'components/layout/ContentPane';
import ListView from 'components/layout/ListView';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import { Body } from '@sumup/circuit-ui';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from 'contexts/UserInfoContext';

function PosConnectionList() {
    const { data, error, isLoading } = useGetPosConnectionsQuery();
    const navigate = useNavigate();
    const { isStaff } = useUserInfo();

    const getDetails = (x: Pick<PosConnection, 'id' | 'name' | 'posSystem'>): ItemProps => ({
        key: x.id,
        variant: 'action',
        label: x.name,
        details: x.posSystem,
        selected: undefined,
    });

    return (
        <ContentPane>
            <ListView<Pick<PosConnection, 'id' | 'name' | 'posSystem'>>
                detailsOpen={false}
                heading={{
                    showBack: true,
                    title: 'POS Connections',
                    cta: isStaff
                        ? { callBack: () => navigate('connect'), buttonText: 'Create Goodtill POS' }
                        : undefined,
                }}
                getDetails={getDetails}
                listItems={data?.getMerchant?.posConnections}
                isListScreen
                errors={error}
                isLoading={isLoading}
                noDataMessage={
                    <Body>There are no POS connections configured. Click the Add new button to create one.</Body>
                }
            />
        </ContentPane>
    );
}

export default PosConnectionList;
