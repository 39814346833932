import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PaymentList from 'pages/payments/PaymentList';
import SetupScreen from 'pages/setup/SetupScreen';
import PaymentDetail from 'pages/payments/PaymentDetail';
import PayoutDetail from 'pages/payouts/PayoutDetail';
import PayoutList from 'pages/payouts/PayoutList';
import CognitoLogout from 'pages/auth/CognitoLogout';
import RequireAuth from 'pages/auth/RequireAuth';
import Dashboard from 'pages/dashboard/Dashboard';
import LinkQrSelectLocation from 'pages/link-qr/LinkQrSelectLocation';
import LinkQrSelectTable from 'pages/link-qr/LinkQrSelectTable';
import LinkQrSuccess from 'pages/link-qr/LinkQrSuccess';
import LocationList from 'pages/locations/LocationList';
import LocationDetail from 'pages/locations/LocationDetail';
import LocationCreate from 'pages/locations/LocationCreate';
import PosConnectionList from 'pages/pos-connections/PosConnectionList';
import MerchantList from 'pages/merchants/MerchantList';
import { Layout } from 'components/layout/Layout';
import RecentPayments from 'pages/recent-payments/RecentPayments';
import PayoutSettings from 'pages/user-settings/payout-and-bank-details/PayoutSettings';
import Loading from 'components/layout/Loading';
import OnboardingStatusRedirect from 'pages/onboarding/OnboardingStatusRedirect';
import QRDeliveryInformation from 'pages/qr-code-order/QRDeliveryInformation';
import OrderQRCode from 'pages/qr-code-order/OrderQRCodes';
import TerminalTemplateList from 'pages/terminal-templates/TerminalTemplateList';
import TerminalTemplateDetail from 'pages/terminal-templates/TerminalTemplateDetail';
import TerminalTemplateCreate from 'pages/terminal-templates/TerminalTemplateCreate';
import PriceBooksList from 'pages/pricebooks/PriceBooksList';
import CombinedContexts from 'contexts/CombinedContext';
import ConnectPos from 'pages/pos-connections/ConnectPos';
import CreatePosConnection from 'pages/pos-connections/CreatePosConnection';
import KdsConnectionList from 'pages/kds-connection/KdsConnectionList';
import QrList from 'pages/qr-list/QrList';

const Onboarding = React.lazy(() => import('./onboarding/Onboarding'));
const Kyc = React.lazy(() => import('./kyc/Kyc'));

function Router() {
    const location = useLocation();

    return (
        <Routes>
            <Route path="/pos/recent-payments/*" element={<RecentPayments />} />
            <Route element={<OnboardingStatusRedirect />}>
                <Route element={<RequireAuth />}>
                    <Route element={<CombinedContexts />}>
                        <Route path="sign-up" element={<Navigate to="/dashboard" />} />
                        <Route
                            path="/onboarding/*"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Onboarding location={location} />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/activate-your-account/*"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Kyc location={location} />
                                </Suspense>
                            }
                        />
                        <Route path="merchants">
                            <Route index element={<MerchantList />} />
                        </Route>

                        <Route element={<Layout />}>
                            <Route index element={<Navigate to="/dashboard" />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="payments" element={<PaymentList />}>
                                <Route path=":paymentId" element={<PaymentDetail />} />
                            </Route>

                            <Route path="payouts">
                                <Route index element={<PayoutList />} />
                                <Route path=":payoutId" element={<PayoutDetail />} />
                            </Route>
                            <Route path="locations">
                                <Route index element={<LocationList />} />
                                <Route path=":locationId" element={<LocationDetail />} />
                                <Route path=":locationId/qr-list" element={<QrList />} />
                                <Route path="create" element={<LocationCreate />} />
                            </Route>
                            <Route path="pos-connections">
                                <Route index element={<PosConnectionList />} />
                                <Route path="connect" element={<ConnectPos />} />
                                <Route path="create" element={<CreatePosConnection />} />
                            </Route>
                            <Route path="terminal-templates">
                                <Route index element={<TerminalTemplateList />} />
                                <Route path=":terminalTemplateId" element={<TerminalTemplateDetail />} />
                                <Route path="create" element={<TerminalTemplateCreate />} />
                            </Route>
                            <Route path="price-books">
                                <Route index element={<PriceBooksList />} />
                            </Route>
                            <Route path="kds-connections">
                                <Route index element={<KdsConnectionList />} />
                            </Route>
                            <Route path="setup" element={<SetupScreen />} />
                            <Route path="user-settings">
                                <Route path="payout-settings">
                                    <Route path="view" element={<PayoutSettings />} />
                                    <Route path="edit/:sectionName" element={<PayoutSettings />} />
                                </Route>
                            </Route>
                            <Route path="order-qr-code" element={<OrderQRCode />} />
                            <Route
                                path="confirm-delivery-information"
                                element={<QRDeliveryInformation hasNoSideBar={false} />}
                            />
                        </Route>
                        <Route element={<Layout showNavigation={false} showSideNavigation={false} />}>
                            <Route path="/link-qr">
                                <Route path=":qrCode">
                                    <Route path="locations">
                                        <Route index element={<LinkQrSelectLocation />} />
                                        <Route path=":locationId">
                                            <Route index element={<LinkQrSelectTable />} />
                                            <Route path="success" element={<LinkQrSuccess />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="*" element={<>not found</>} />
                        <Route path="/logout" element={<CognitoLogout />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default Router;
