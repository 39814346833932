import { Spinner } from '@sumup/circuit-ui';
import React, { useEffect } from 'react';
import { Checkmark, Alert } from '@sumup/icons';
import styled from '@emotion/styled';
import { useGetPreSignedUrlQuery } from '../../utils/generated';

interface UploadIndicatorProps {
    fileName: string;
    fileSize: number;
    mimeType: string;
    setPreSignedUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    setError: (msg?: string | undefined) => void;
}

export const StyledIndicatorWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 15px;
`;

function UploadIndicator({ fileName, fileSize, mimeType, setPreSignedUrl, setError }: UploadIndicatorProps) {
    const { data, isLoading, error } = useGetPreSignedUrlQuery({ fileName, fileSize, mimeType });

    useEffect(() => {
        if (data) {
            setPreSignedUrl(data.getPreSignedUrl);
        }
    }, [data, setPreSignedUrl]);
    if (error) {
        setError('Could not get preSignedUrl');
        return (
            <StyledIndicatorWrapper>
                <Alert />
            </StyledIndicatorWrapper>
        );
    }
    if (isLoading) {
        return (
            <StyledIndicatorWrapper>
                <Spinner size="byte" />
            </StyledIndicatorWrapper>
        );
    }

    return (
        <StyledIndicatorWrapper>
            <Checkmark size="16" />
        </StyledIndicatorWrapper>
    );
}

export default UploadIndicator;
