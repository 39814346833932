import { DateRange, DateTimeRange } from 'typings/Calendar';

function formatDateRange(
    dateRange: DateRange | null | undefined,
    dateTimeRange: DateTimeRange | null,
    isIso?: boolean
): { start: string | undefined; end: string | undefined } {
    if (dateRange?.startDate && dateRange.endDate) {
        const start = dateTimeRange?.startTime
            ? dateRange?.startDate?.clone().set({ ...dateTimeRange.startTime })
            : dateRange.startDate.clone().startOf('day');

        const end = dateTimeRange?.endTime
            ? dateRange?.endDate?.clone().set({ ...dateTimeRange.endTime })
            : dateRange?.endDate?.clone().endOf('day');

        if (isIso) {
            return dateRange.startDate.isSame(dateRange.endDate, 'day')
                ? {
                      start: start.toISOString(),
                      end: end.toISOString(),
                  }
                : {
                      start: end.toISOString(),
                      end: start.toISOString(),
                  };
        }
        return {
            start: start.toLocaleString(),
            end: end.toLocaleString(),
        };
    }

    return {
        start: undefined,
        end: undefined,
    };
}

export default formatDateRange;
