import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface AlignmentProps {
    center?: boolean;
}

const CardBody = styled.div<AlignmentProps>(
    ({ center }) => css`
        padding: 8px 20px;
        align-items: ${center ? 'center' : 'flex-start'};
        text-align: ${center ? 'center' : 'flex-start'};
    `
);

export default CardBody;
