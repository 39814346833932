import React from 'react';
import ErrorBanner from '../ErrorBanner';
import { FormErrorProps } from './FormError.types';

function FormError({ errors }: FormErrorProps): JSX.Element | null {
    if (!errors) return null;

    if (Array.isArray(errors)) {
        return (
            <>
                {errors.map((error) => (
                    <ErrorBanner key={error.errorType} message={error.message ?? 'An error has occurred'} />
                ))}
            </>
        );
    }

    if (!Array.isArray(errors)) return <ErrorBanner message={errors.message ?? 'An error has occurred'} />;

    return <ErrorBanner message="An error has occurred" />;
}

export default FormError;
