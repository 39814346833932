import { Button, Headline, Image } from '@sumup/circuit-ui';
import React from 'react';
import EmbeddableContent from 'components/layout/EmbeddableContent';

function MagicPayTeaser() {
    return (
        <EmbeddableContent style={{ gap: '28px', position: 'fixed' }}>
            <Image style={{ maxWidth: '300px' }} src="/magic-pay-logo.png" alt="Magic Pay logo" />
            <Image style={{ maxWidth: '400px' }} src="/demo-image.png" alt="Magic Pay demo" />
            <Headline
                style={{ textAlign: 'center', maxWidth: '550px', marginTop: '20px', fontFamily: 'sans-serif' }}
                size="three"
                as="h1"
            >
                A new and better way to pay in restaurants. Get the bill, split it, pay and leave in 15 seconds rather
                then 15 minutes.
            </Headline>
            <Button
                onClick={() => window.location.replace('https://sumup.co.uk/orderandpay')}
                style={{
                    minHeight: '48px',
                    borderColor: 'transparent',
                    background: '#d3d3d3',
                    color: 'black',
                    fontSize: '1.25em',
                    paddingInline: '8px',
                    paddingBlock: '12px',
                    fontWeight: 700,
                }}
            >
                Learn More
            </Button>
        </EmbeddableContent>
    );
}

export default MagicPayTeaser;
