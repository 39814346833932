import { getUserInfo } from 'helpers/userInfoHelper';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OapError, useGetMerchantStatusQuery } from 'utils/generated';

interface Props {
    children: React.ReactNode;
}

interface UserInfoState {
    hasCompletedSetup: boolean | undefined;
    userInfo:
        | {
              id: string;
              username: string;
              email: string;
              name: string;
              groups: string[] | undefined;
          }
        | undefined;
    isUpdating: boolean;
    loadingError: OapError[] | null;
    isStaff: boolean;
}

const UserInfoContext = React.createContext<UserInfoState>({} as UserInfoState);

function UserInfoProvider({ children }: Props) {
    const [loadingUserInfo, setLoadingUserInfo] = useState(false);
    const { data, isLoading, error, isFetching } = useGetMerchantStatusQuery(undefined, {
        refetchOnWindowFocus: false,
    });
    const hasCompletedSetup = data?.getMerchant?.paymentConfiguration?.enabled;
    const [userInfo, setUserInfo] = useState<
        | {
              id: string;
              username: string;
              email: string;
              name: string;
              groups: string[] | undefined;
          }
        | undefined
    >();

    useEffect(() => {
        const loadUserInfo = async () => {
            setLoadingUserInfo(true);
            try {
                const fetchedUserInfo = await getUserInfo();
                if (fetchedUserInfo) setUserInfo(fetchedUserInfo);
                setLoadingUserInfo(false);
            } catch (err) {
                console.error(err);
                setLoadingUserInfo(false);
            }
        };
        loadUserInfo();
    }, [data]);

    const state = useMemo(
        () => ({
            hasCompletedSetup,
            userInfo,
            isUpdating: isLoading || isFetching || loadingUserInfo,
            loadingError: error,
            isStaff: userInfo?.groups?.includes('Staff') || false,
        }),
        [error, hasCompletedSetup, isFetching, isLoading, loadingUserInfo, userInfo]
    );

    return <UserInfoContext.Provider value={state}>{children}</UserInfoContext.Provider>;
}

export default UserInfoProvider;

export function useUserInfo() {
    const { hasCompletedSetup, isUpdating, loadingError, userInfo, isStaff } = useContext(UserInfoContext);
    return { hasCompletedSetup, isUpdating, loadingError, userInfo, isStaff };
}
