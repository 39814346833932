import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Body, Headline, InlineElements, SubHeadline, ButtonGroup, Button } from '@sumup/circuit-ui';
import PageHeader from 'components/layout/PageHeader';
import {
    GetPayoutQuery,
    Payout,
    Transaction,
    TransactionListQuery,
    useGetPayoutQuery,
    useGetPayoutTransactionsQuery,
} from 'utils/generated';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import ContentPane from 'components/layout/ContentPane';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import usePagination from 'hooks/usePagination';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import { Checkmark, Refunded } from '@sumup/icons';
import FormError from 'components/FormError/FormError';
import ListView from 'components/layout/ListView';
import Loading from 'components/layout/Loading';

const STATUS = {
    SUCCESS: { icon: Checkmark, color: 'green', status: 'Successful' },
    REFUND: { icon: Refunded, color: '#F5C625', status: 'Refunded' },
};

const detailsBaseStyles = () => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
    max-width: 900px;
    margin: 1rem;
    padding: 1rem;
`;

const DetailsWrapper = styled.div(detailsBaseStyles);

const summaryGridStyles = () => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
`;
const summaryCellStyles = ({ align = 'start' }: { align?: 'start' | 'end' | 'center' | 'justify' }) => css`
    width: 100%;
    text-align: ${align};
`;

const SummaryGrid = styled.div(summaryGridStyles);
const SummaryCell = styled.div(summaryCellStyles);

function PayoutDetails() {
    const location = useLocation();
    const { payoutId } = useParams();
    const { nextPageToken, goToPreviousPage, goToNextpage } = usePagination();
    const [listQuery, setListQuery] = useState<TransactionListQuery>({
        pageSize: '5',
    });
    const {
        data: payoutData,
        error: payoutErrors,
        isLoading: payoutLoading,
    } = useGetPayoutQuery({ payoutId: payoutId! }, { initialData: { getPayout: location?.state } as GetPayoutQuery });

    const {
        data: transactionData,
        error: transactionErrors,
        isLoading: transactionsLoading,
    } = useGetPayoutTransactionsQuery({ payoutId: payoutId!, listQuery });

    const intl = useIntl();
    const navigate = useNavigate();
    const { formatTime } = useIntl();

    const details = (transaction: Transaction | undefined) => {
        const type = transaction?.amount && transaction?.amount > 0 ? STATUS.SUCCESS : STATUS.REFUND;
        const StatusIcon = type.icon;
        return (
            <InlineElements ratios={[0, 2, 2]} style={{ alignItems: 'center' }}>
                <StatusIcon color={type.color} style={{ marginInline: '4px' }} />
                <SubHeadline as="h4" noMargin style={{ marginInline: '4px' }}>
                    {type.status}
                </SubHeadline>
                <Body noMargin>
                    {transaction?.createdAt &&
                        Date.parse(transaction?.createdAt) !== Date.now() &&
                        formatTime(transaction.createdAt, {
                            dateStyle: 'medium',
                        })}
                    &nbsp;
                    {transaction?.createdAt &&
                        formatTime(transaction.createdAt, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                </Body>
            </InlineElements>
        );
    };

    useEffect(() => {
        setListQuery({ ...listQuery, nextToken: nextPageToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPageToken]);

    const getDetails = (x: Transaction): ItemProps => ({
        key: x.id,
        variant: 'navigation',
        onClick: () => navigate(`/payments/${x.id}`, { state: x }),
        label: details(x as Transaction),
        selected: undefined,
        suffix: <CurrencyFormat amount={x.amount} currency={x.currency} />,
    });

    const { amount, type, arrivalAt, currency } = payoutData?.getPayout as Payout;

    return (
        <ContentPane>
            <DetailsWrapper>
                <>
                    <PageHeader showBack title="Payout details" />
                    {payoutLoading && <Loading />}
                    {!payoutLoading && payoutErrors ? (
                        <FormError errors={payoutErrors} />
                    ) : (
                        <Body style={{ width: '450px', maxWidth: '100%' }}>
                            <Headline style={{ textAlign: 'center' }} size="one" as="h2">
                                <CurrencyFormat amount={amount} currency={currency} />
                            </Headline>
                            <Headline size="four" noMargin as="h3" style={{ textAlign: 'center' }}>
                                To {type.replace('_', ' ')}
                            </Headline>
                            <Body size="two" style={{ textAlign: 'center' }}>
                                {intl.formatDate(arrivalAt, {
                                    dateStyle: 'long',
                                })}
                            </Body>

                            <Headline size="four" as="h3">
                                Summary
                            </Headline>
                            <SummaryGrid>
                                <SummaryCell>Total amount</SummaryCell>
                                <SummaryCell align="end">
                                    <CurrencyFormat amount={amount} currency={currency} />
                                </SummaryCell>
                                <SummaryCell>Fees</SummaryCell>
                                <SummaryCell align="end">
                                    <CurrencyFormat amount={0} currency={currency} />
                                </SummaryCell>
                                <SummaryCell>Payout amount</SummaryCell>
                                <SummaryCell align="end">
                                    <CurrencyFormat amount={amount} currency={currency} />
                                </SummaryCell>
                            </SummaryGrid>

                            <ListView<Transaction>
                                detailsOpen={false}
                                heading={{
                                    title: 'Payments',
                                }}
                                getDetails={getDetails}
                                listItems={transactionData?.getPayoutTransactions.items as Transaction[]}
                                errors={transactionErrors}
                                isLoading={transactionsLoading}
                                bottomListControls={
                                    <ButtonGroup align="right">
                                        {nextPageToken !== undefined ? (
                                            <Button onClick={() => goToPreviousPage()}> Previous </Button>
                                        ) : null}
                                        {transactionData?.getPayoutTransactions?.nextToken ? (
                                            <Button
                                                onClick={() =>
                                                    goToNextpage(transactionData?.getPayoutTransactions.nextToken ?? '')
                                                }
                                            >
                                                Next
                                            </Button>
                                        ) : null}
                                    </ButtonGroup>
                                }
                            />
                        </Body>
                    )}
                </>
            </DetailsWrapper>
        </ContentPane>
    );
}

export default PayoutDetails;
