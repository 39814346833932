import React, { useEffect } from 'react';
import { Button, ButtonGroup, CardHeader } from '@sumup/circuit-ui';
import {
    PutTerminalMutation,
    TerminalConfiguration,
    TerminalInput,
    useGetTerminalTemplateQuery,
    usePutTerminalMutation,
} from 'utils/generated';
import Skeleton from 'react-loading-skeleton';
import SimpleGrid from 'components/layout/SimpleGrid';
import styled from '@emotion/styled';
import FormWithSchema from 'components/FormWithSchema/FormWithSchema';
import FormSelect from 'components/FormWithSchema/fields/FormSelect/FormSelect';
import FormInput from 'components/FormWithSchema/fields/FormInput/FormInput';
import FormError from 'components/FormError/FormError';
import TerminalTemplateSchema from './TerminalConfigModal.schema';
import { LocationTerminalConfigModalProps } from './LocationTerminalTemplateModal.types';

const ModalBody = styled.div`
    margin: 8px;
    padding: 0px;
    display: flex;
    flex-grow: 2;
    gap: 20px;
    flex-direction: column;
    overflow: visible;
`;

function LocationTerminalTemplateModal({
    title,
    submitButtonName,
    locationId,
    terminal,
    closeCallback,
}: LocationTerminalConfigModalProps) {
    const { mutateAsync, isLoading, error: mutationError, isSuccess } = usePutTerminalMutation();
    const { data, isFetching, error } = useGetTerminalTemplateQuery();

    useEffect(() => {
        if (isSuccess) {
            closeCallback();
        }
    }, [isSuccess, closeCallback]);

    return (
        <ModalBody>
            <CardHeader closeButtonLabel="Close" onClose={closeCallback}>
                {title}
            </CardHeader>
            <FormError errors={error} />
            <FormError errors={mutationError} />
            {!data ? (
                <SimpleGrid columnTemplate="1fr">
                    <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />
                    <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />
                </SimpleGrid>
            ) : (
                <FormWithSchema<TerminalInput, PutTerminalMutation>
                    schema={TerminalTemplateSchema}
                    onSubmit={(x) =>
                        mutateAsync({
                            locationId,
                            terminal: {
                                configurationId: x.configurationId,
                                id: terminal?.id || null,
                                name: x.name,
                            },
                        })
                    }
                    initialValues={{
                        id: terminal?.id,
                        name: terminal?.name || '',
                        configurationId: terminal?.configurationId,
                    }}
                >
                    <SimpleGrid columnTemplate="1fr">
                        <FormInput name="name" label="Name *" />
                        <FormSelect<Omit<TerminalInput, 'id'>, Pick<TerminalConfiguration, 'id' | 'name'>>
                            name="configurationId"
                            label="Template *"
                            loading={isLoading}
                            getOptionLabel={(x) => x.name}
                            getOptionValue={(x) => x.id}
                            options={data?.getTerminalConfigurations}
                        />
                    </SimpleGrid>
                    <ButtonGroup align="right">
                        <Button disabled={isFetching || isLoading} variant="primary" type="submit">
                            {submitButtonName}
                        </Button>
                        <Button variant="secondary" onClick={() => closeCallback()} type="button">
                            Cancel
                        </Button>
                    </ButtonGroup>
                </FormWithSchema>
            )}
        </ModalBody>
    );
}

export default LocationTerminalTemplateModal;
