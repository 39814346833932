import React, { useEffect } from 'react';
import PageHeader from 'components/layout/PageHeader';
import { PutLocationMutationVariables, useGetLocationQuery, usePutLocationMutation } from 'utils/generated';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import useErrorHandler from 'hooks/useErrorHandler';
import Loading from 'components/layout/Loading';
import { Button } from '@sumup/circuit-ui';
import { isOapLocation } from 'typings/typePredicates';
import { useUserInfo } from 'contexts/UserInfoContext';
import FormError from 'components/FormError/FormError';
import LocationForm from './LocationForm';

type Params = {
    locationId: string;
};

function LocationDetail() {
    const { locationId } = useParams() as Params;
    const location = useLocation();
    const navigate = useNavigate();
    const { checkValidParam } = useErrorHandler();
    const { data, error, isLoading } = useGetLocationQuery(
        { locationId },
        {
            initialData: isOapLocation(location.state) ? { getLocation: { ...location.state } } : undefined,
        }
    );
    const { mutateAsync, error: savingError, isLoading: isSavingLocation } = usePutLocationMutation();

    const { isStaff } = useUserInfo();

    let name: string | undefined;

    const onSubmit = async (values: PutLocationMutationVariables) => {
        // TODO: Are errors returned on a 200? If so then we need to check and throw here.
        return mutateAsync(values);
    };

    const initialValues: Partial<PutLocationMutationVariables> = {
        id: locationId,
        name: data?.getLocation?.name,
        menuUrl: data?.getLocation?.menuUrl,
        posConnectionId: data?.getLocation?.posConnection?.id as string,
        enabled: data?.getLocation?.serviceChargeConfiguration?.enabled || false,
        percentageLow: data?.getLocation?.serviceChargeConfiguration?.percentageLow as number,
        percentageMedium: data?.getLocation?.serviceChargeConfiguration?.percentageMedium as number,
        percentageHigh: data?.getLocation?.serviceChargeConfiguration?.percentageHigh as number,
    };

    // This will redirect if the location id is bad. Should probably also include a notification.
    useEffect(() => {
        if (error) {
            // eslint-disable-next-line no-useless-escape
            checkValidParam(error, 'Location ID not found');
        }
    }, [error, checkValidParam]);

    return (
        <ContentPane>
            <Content>
                <PageHeader
                    title={name || data?.getLocation?.name}
                    cta={isStaff ? <Button onClick={() => navigate('qr-list')}>Show QR code List</Button> : undefined}
                    showBack
                />
                <FormError errors={savingError || error} />
                {isLoading && <Loading />}
                {!isLoading && (
                    <LocationForm
                        defaultValues={initialValues}
                        onSubmit={onSubmit}
                        isSavingLocation={isSavingLocation}
                    />
                )}
            </Content>
        </ContentPane>
    );
}

export default LocationDetail;
