import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Input } from '@sumup/circuit-ui';
import { Hide, View } from '@sumup/icons';
import { FieldError, Path, useFormContext } from 'react-hook-form';

interface Props<T> {
    label: string;
    path: Path<T>;
    pinInputError: FieldError | undefined;
    validationRegex?: RegExp;
}

const PinInputWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 28px;
    gap: 16px;
    align-items: center;
    margin-bottom: 20px;
`;

function PinInput<T>({ label, path, pinInputError, validationRegex }: Props<T>) {
    const [pinVisible, setPinVisible] = useState(false);

    const { register } = useFormContext();

    return (
        <PinInputWrapper>
            <Input
                autoComplete="off"
                type={pinVisible ? 'text' : 'password'}
                validationHint={pinInputError && pinInputError.message}
                invalid={!!pinInputError}
                {...register(path, {
                    required: { value: true, message: 'Operator pin is required' },
                    pattern: validationRegex && {
                        value: validationRegex,
                        message: 'Pin should be 6 numbers exactly',
                    },
                })}
                label={label}
                showValid={false}
            />
            <Button
                style={{ marginBottom: pinInputError ? '24px' : undefined }}
                variant="tertiary"
                onClick={(e) => {
                    e.preventDefault();
                    setPinVisible(!pinVisible);
                }}
            >
                {pinVisible ? <Hide /> : <View />}
            </Button>
        </PinInputWrapper>
    );
}

export default PinInput;
