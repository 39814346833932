import styled from '@emotion/styled';
import { light } from '@sumup/design-tokens';

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${light.spacings.kilo};
`;

export default LogoContainer;
