import React, { useEffect } from 'react';
import { Button, CardHeader } from '@sumup/circuit-ui';
import QRCode from 'react-qr-code';
import styled from '@emotion/styled';
import { useDeleteQrCodeMutation } from 'utils/generated';
import FormError from 'components/FormError/FormError';
import { QrEditModalProps } from './QrEditModal.types';

const ModalBody = styled.div`
    margin: 8px;
    padding: 0px;
    display: flex;
    flex-grow: 2;
    gap: 20px;
    flex-direction: column;
    overflow: visible;
`;

const QrWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
`;

function QrEditModal({ qrCodeId, title, closeCallback, locationId }: QrEditModalProps) {
    const { mutateAsync, isSuccess, isLoading: deleteQrLoading, error } = useDeleteQrCodeMutation();

    useEffect(() => {
        if (isSuccess) {
            closeCallback();
        }
    }, [isSuccess, closeCallback]);

    const tablePayUrl = process.env.REACT_APP_CONSUMER_FRONT_URL as string;

    return (
        <ModalBody>
            <CardHeader closeButtonLabel="Close" onClose={closeCallback}>
                {title}
            </CardHeader>
            <FormError errors={error} />
            <QrWrapper>
                <QRCode value={`${tablePayUrl}/qr/${qrCodeId}`} width="100%" />
            </QrWrapper>
            <Button
                type="button"
                onClick={() => mutateAsync({ locationId, qrCodeId })}
                variant="primary"
                isLoading={deleteQrLoading}
                loadingLabel="Processing..."
                destructive
            >
                Delete QR code
            </Button>
        </ModalBody>
    );
}

export default QrEditModal;
