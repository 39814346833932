import React from 'react';
import { useGetLocationsQuery, Location } from 'utils/generated';
import { useNavigate } from 'react-router-dom';
import ContentPane from 'components/layout/ContentPane';
import ListView from 'components/layout/ListView';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import { Body } from '@sumup/circuit-ui';

function LocationList() {
    const { data, error, isLoading } = useGetLocationsQuery();
    const navigate = useNavigate();

    const getDetails = (x: Pick<Location, 'id' | 'name'>): ItemProps => ({
        key: x.id,
        variant: 'navigation',
        onClick: () => navigate(x.id, { state: x }),
        label: x.name,
        selected: undefined,
    });

    return (
        <ContentPane>
            <ListView<Pick<Location, 'id' | 'name'>>
                detailsOpen={false}
                heading={{
                    title: 'Locations',
                    cta: { buttonText: 'Add new', callBack: () => navigate('create') },
                }}
                getDetails={getDetails}
                listItems={data?.getMerchant?.locations}
                isListScreen
                errors={error}
                isLoading={isLoading}
                noDataMessage={<Body>There are no locations configured. Click the Add new button create one.</Body>}
            />
        </ContentPane>
    );
}

export default LocationList;
