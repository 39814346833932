import { KdsConnectionInput, KdsSystem } from 'utils/generated';
import * as yup from 'yup';

const kdsSystem = new yup.StringSchema<KdsSystem>().oneOf(Object.values(KdsSystem));

const KdsConnectionSchema: yup.SchemaOf<KdsConnectionInput> = yup
    .object({
        id: yup.string(),
        kdsSystem: kdsSystem.required('KDS System is required'),
        config: yup.object({
            clientId: yup.string().required('Client ID is required'),
            clientSecret: yup.string().required('Client Secret is required'),
        }),
    })
    .required();

export default KdsConnectionSchema;
