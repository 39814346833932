import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import { getIdTokenPayload } from 'helpers/userInfoHelper';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function OnboardingStatusRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function checkTentant() {
            try {
                const token = await getIdTokenPayload();
                const hasTenant = !!token?.['custom:op_tenants'];
                const isOnboarding = location.pathname.includes('onboarding') || location.pathname.includes('sign-up');
                if (token && !hasTenant && !isOnboarding) navigate('/onboarding');
            } catch (err) {
                console.error(err);
            }
        }
        const handleAuthState = (data: { payload: { event: string } }) => {
            switch (data?.payload.event) {
                case 'signIn':
                    checkTentant();
                    break;
                default:
            }
        };
        Hub.listen('auth', handleAuthState); // listen for auth events
        checkTentant();
        return () => Hub.remove('auth', handleAuthState);
    }, [location, navigate]);
    return <Outlet />;
}

export default OnboardingStatusRedirect;
