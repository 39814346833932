import React from 'react';

import { ListItem } from '@sumup/circuit-ui';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantQuery } from 'utils/generated';
import FormError from 'components/FormError/FormError';
import Loading from 'components/layout/Loading';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import PageHeader from 'components/layout/PageHeader';

function LinkQrSelectLocation() {
    const navigate = useNavigate();

    const { data, error, isLoading } = useGetMerchantQuery();

    return (
        <ContentPane>
            <Content>
                <PageHeader title="Select location" />

                <FormError errors={error} />
                {isLoading && <Loading />}
                {!isLoading &&
                    data?.getMerchant?.locations.map((x) => (
                        <ListItem
                            variant="navigation"
                            style={{ marginBottom: '10px' }}
                            onClick={() => navigate(x.id)}
                            label={x.name}
                        />
                    ))}
            </Content>
        </ContentPane>
    );
}

export default LinkQrSelectLocation;
