import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';
import { ClickEvent } from '@sumup/circuit-ui/dist/es/types/events';
import { light } from '@sumup/design-tokens';
import { Delete } from '@sumup/icons';
import { FieldError, Path } from 'react-hook-form';
import ImageUploadInput from 'components/UploadInput/ImageUploadInput';
import Spacer from '../../../components/Spacer';

interface Props<T> {
    path: Path<T>;
    index: number;
    id: string;
    remove: (e: ClickEvent<Element>, index: number) => void;
    screensaverErrors:
        | {
              url?: FieldError | undefined;
          }[]
        | undefined;
}

const ScreensaverImageInputWrapper = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr;
    gap: 16px;
    align-items: center;
`;

function ScreensaverImageUrlInput<T>({ id, path, index, remove, screensaverErrors }: Props<T>) {
    const name = `${path}.${index}.url` as Path<T>;

    return (
        <ScreensaverImageInputWrapper>
            <Button variant="tertiary" onClick={(e) => remove(e, index)}>
                <Delete color={light.colors.r500} />
            </Button>
            <ImageUploadInput
                key={id}
                label={`Screen save image ${index + 1}`}
                name={name}
                hasError={!!screensaverErrors?.[index]}
                validationHint={screensaverErrors && screensaverErrors[index]?.url?.message}
                required={false}
            />
            <Spacer space="kilo" />
        </ScreensaverImageInputWrapper>
    );
}

export default ScreensaverImageUrlInput;
