import React, { useEffect } from 'react';
import Content from 'components/layout/Content';
import ContentPane from 'components/layout/ContentPane';
import Loading from 'components/layout/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { PosSystem, usePutPosConnectionMutation } from 'utils/generated';

function CreatePosConnection() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const receivedToken = params.get('token');

    const { mutateAsync: putPosConnection, isLoading } = usePutPosConnectionMutation();

    useEffect(() => {
        const createPosConnection = async (token: string) => {
            const newPosConnection = await putPosConnection({
                posSystem: PosSystem.Goodtill,
                token,
            });
            if (newPosConnection.putPosConnection.id) navigate('/pos-connections', { replace: true });
        };
        if (receivedToken) createPosConnection(receivedToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedToken]);

    return (
        <ContentPane>
            <Content>{isLoading ? <Loading message="Creating POS connection" /> : <>Something went wrong</>}</Content>
        </ContentPane>
    );
}

export default CreatePosConnection;
