import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ColorInputLoadingWrapperStyles = () => css`
    width: 100%;
    max-height: 72px;
    height: 72px;
    margin-bottom: 16px;
    display: grid;
    grid-template-rows: 20px 48px;
    gap: 4px;
`;

const ColorInputLoadingWrapper = styled('div')(ColorInputLoadingWrapperStyles);

function ColorInputLoading() {
    return (
        <ColorInputLoadingWrapper>
            <Skeleton enableAnimation count={1} borderRadius="16px" height="20px" width="50px" />
            <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="48px" />
        </ColorInputLoadingWrapper>
    );
}

export default ColorInputLoading;
