/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Body,
    ButtonGroup,
    Card,
    CardHeader,
    Headline,
    InlineElements,
    Input,
    Toggle,
} from '@sumup/circuit-ui';
import {
    PosConnection,
    PutLocationMutation,
    PutLocationMutationVariables,
    useGetPosConnectionsQuery,
} from 'utils/generated';
import Select from 'components/ui/components/Select';
import styled from '@emotion/styled';
import Form from 'components/Form';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useParams } from 'react-router-dom';
import { useFeatureFlags } from 'contexts/FeatureFlagContext';
import TerminalListCard from './components/TerminalListCard';

interface Props {
    defaultValues?: Partial<PutLocationMutationVariables>;
    isSavingLocation: boolean;
    onSubmit: (values: PutLocationMutationVariables) => Promise<PutLocationMutation | void>;
}

const StyledSuffix = styled('div')`
    align-self: flex-start;
`;

function LocationForm({ defaultValues, onSubmit, isSavingLocation }: Props) {
    const { locationId } = useParams();
    const { kioskConfigEnabled } = useFeatureFlags();
    const {
        reset,
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors, isDirty },
    } = useForm<PutLocationMutationVariables>({ defaultValues });

    const { data, isLoading } = useGetPosConnectionsQuery();

    const posConnections = data?.getMerchant?.posConnections;

    const beforeSubmit = (x: PutLocationMutationVariables) => {
        const newValues = x;
        // React hook form defaults to empty string if nothing is supplied. Checking and manually setting to null if no string is supplied.
        if (x.menuUrl === '') newValues.menuUrl = null;
        onSubmit(newValues).finally(() => reset(newValues));
    };
    const tipsEnabled = watch('enabled');

    return (
        <Form onSubmit={handleSubmit(beforeSubmit)}>
            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Headline as="h3" size="three" noMargin>
                        Location
                    </Headline>
                </CardHeader>

                <Input
                    validationHint={errors.name && errors.name.message}
                    {...register('name', { required: { value: true, message: 'Name is required' }, minLength: 3 })}
                    label="Name *"
                    invalid={!!errors?.name}
                />
                <Input {...register('menuUrl', { required: false })} label="Menu Url" />
            </Card>

            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Headline as="h3" size="three" noMargin>
                        Tips
                    </Headline>
                </CardHeader>

                <Body size="two">
                    If you are not charging guests anything for service, you can ask them to leave a tip.
                </Body>
                <Controller
                    name="enabled"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Toggle
                            checkedLabel="Tips enabled"
                            uncheckedLabel="Tips disabled"
                            label="Activate tips in the guest app"
                            checked={value}
                            onChange={() => onChange(!value)}
                        />
                    )}
                />
                {tipsEnabled && (
                    <>
                        <Body size="two">
                            Choose the amount of tip your guests are allowed to give. The chosen amount will be
                            displayed in the guest app.
                        </Body>
                        <InlineElements inlineMobile style={{ maxWidth: '480px' }}>
                            <Input
                                type="number"
                                step="0.1"
                                validationHint={errors.percentageLow && errors.percentageLow.message}
                                {...register('percentageLow')}
                                label="Low"
                                hideLabel={false}
                                renderSuffix={({ className }) => <StyledSuffix className={className}>%</StyledSuffix>}
                            />

                            <Input
                                type="number"
                                step="0.1"
                                validationHint={errors.percentageMedium && errors.percentageMedium.message}
                                {...register('percentageMedium')}
                                label="Medium"
                                hideLabel={false}
                                renderSuffix={({ className }) => <StyledSuffix className={className}>%</StyledSuffix>}
                            />

                            <Input
                                type="number"
                                step="0.1"
                                validationHint={errors.percentageHigh && errors.percentageHigh.message}
                                {...register('percentageHigh')}
                                label="High"
                                hideLabel={false}
                                renderSuffix={({ className }) => <StyledSuffix className={className}>%</StyledSuffix>}
                            />
                        </InlineElements>
                    </>
                )}
            </Card>
            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Headline as="h3" size="three" noMargin>
                        POS Connection
                    </Headline>
                </CardHeader>
                {isLoading ? (
                    <>
                        <Skeleton enableAnimation count={1} borderRadius="16px" height="12px" width="50px" />
                        <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />
                    </>
                ) : (
                    <Controller
                        name="posConnectionId"
                        control={control}
                        defaultValue={defaultValues?.posConnectionId ?? undefined}
                        rules={{ required: { value: true, message: 'POS connection is required' } }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Select<Pick<PosConnection, 'id' | 'name' | 'posSystem'>>
                                options={data?.getMerchant?.posConnections}
                                id="posConnectionId"
                                label="POS connection *"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder="POS connection"
                                onChange={(newValue) => onChange(newValue?.id)}
                                loading={isLoading}
                                defaultValue={
                                    posConnections?.find((connection) => connection.id === value) ?? undefined
                                }
                                formError={!!errors?.posConnectionId}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                )}
            </Card>
            {kioskConfigEnabled && locationId && <TerminalListCard locationId={locationId} />}
            <ButtonGroup align="left">
                <Button
                    type="submit"
                    variant="primary"
                    isLoading={isSavingLocation}
                    loadingLabel="Processing..."
                    disabled={!isDirty}
                >
                    Save
                </Button>
            </ButtonGroup>
        </Form>
    );
}

export default LocationForm;
