import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Avatar, ImageInput, Label } from '@sumup/circuit-ui';
import Spacer from 'components/Spacer';
import UploadIndicator, { StyledIndicatorWrapper } from './UploadIndicator';
import ToolTip from '../ui/components/ToolTip';
import { upload } from '../../utils/uploadS3Util';

interface FileUploadInputProps {
    label: string;
    name: string;
    required: boolean;
    key?: string;
    validationHint?: string;
    hasError: boolean;
}

function ImageUploadInput({ label, name, required, key, hasError, validationHint }: FileUploadInputProps) {
    const { register, setError, clearErrors, setValue, watch } = useFormContext();
    const [file, setFile] = useState<File | undefined>();
    const [preSignedUrl, setPreSignedUrl] = useState<string | undefined>();
    const lastUpload = useRef<string | null>();

    const handleError = useCallback(
        (msg?: string | undefined) => {
            if (msg !== undefined) {
                setError(name, { type: 'value', message: msg });
            } else {
                clearErrors(name);
            }
        },
        [clearErrors, name, setError]
    );
    const currentValue = watch(name);

    useEffect(() => {
        if (preSignedUrl && file) {
            upload({ handleError, file, preSignedUrl, mimeType: file.type })
                .then(() => {
                    setValue(name, preSignedUrl.split('?')[0]);
                    setPreSignedUrl(undefined);
                    lastUpload.current = file.name;
                })
                .catch(() => {
                    handleError('Upload failed.');
                    setFile(undefined);
                });
        }
        if (!preSignedUrl && file?.name === lastUpload.current) {
            setFile(undefined);
            handleError(undefined);
        }
    }, [file, handleError, preSignedUrl, setValue, name]);

    useEffect(() => {
        register(name, {
            required: {
                value: required,
                message: `${label} is required`,
            },
        });
    }, [currentValue, label, name, register, required]);

    const renamedFile = (fileToRename?: File) => {
        if (fileToRename) {
            const extension = fileToRename.name.split('.').pop();
            const blob = fileToRename.slice(0, fileToRename.size, fileToRename.type);
            return new File([blob], `${new Date().getTime()}.${extension}`, { type: fileToRename.type });
        }
        return undefined;
    };

    return (
        <div>
            <Label htmlFor={name} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {required ? `${label} *` : label}
                {file ? (
                    <UploadIndicator
                        fileName={file?.name}
                        fileSize={file?.size}
                        mimeType={file?.type}
                        setPreSignedUrl={setPreSignedUrl}
                        setError={handleError}
                    />
                ) : (
                    <StyledIndicatorWrapper>
                        <ToolTip tipText="Files can be uploaded by clicking the image preview below" />
                    </StyledIndicatorWrapper>
                )}
            </Label>
            <Spacer space="byte" />
            <ImageInput
                onChange={(onChangeFile: File) => {
                    setFile(renamedFile(onChangeFile));
                }}
                key={key}
                component={Avatar}
                loadingLabel="Preparing to upload"
                src={currentValue}
                alt={`${label} preview`}
                onInput={(event) => {
                    event.preventDefault();
                    setFile(renamedFile(event?.currentTarget?.files?.[0]));
                }}
                clearButtonLabel="Remove"
                name={name}
                onClear={() => setValue(name, null)}
                label={label}
                invalid={hasError}
                validationHint={validationHint}
            />
        </div>
    );
}

export default ImageUploadInput;
