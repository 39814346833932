import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Spinner } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import PageHeader from 'components/layout/PageHeader';
import CloseableCard from 'components/ui/components/CloseableCard';
import { Location } from 'typings/Setup';
import { StyleProps } from 'typings/theme';
import MenuFile from './MenuFile';
import MenuUrl from './MenuUrl';
import TipsEnable from './TipsEnable';
import TipAmounts from './TipAmounts';

interface Props {
    defaultValues?: Location;
}

const Section = styled.div`
    ${({ theme }: StyleProps) => `
        margin-bottom: ${theme?.spacings.giga};
        width: 100%;
    `}
`;

function SetupScreen({ defaultValues }: Props) {
    const methods = useForm({ defaultValues });
    const { handleSubmit, formState } = methods;
    const { errors, isSubmitting } = formState;

    // TO DO: hook up setup form with api
    const onSubmit = (data: Location) => data;

    return (
        <>
            <PageHeader title="Setup" />
            <form id="locationSetupForm" onSubmit={handleSubmit(onSubmit)}>
                <Section>
                    <CloseableCard title="Menu" initialOpen>
                        <MenuFile />
                        <MenuUrl errors={errors.menuUrl} methods={methods} />
                    </CloseableCard>
                    <CloseableCard title="Tips" initialOpen>
                        <TipsEnable methods={methods} />
                        <TipAmounts methods={methods} />
                    </CloseableCard>
                </Section>
                <Button type="submit" variant="primary">
                    {isSubmitting ? <Spinner /> : 'Save'}
                </Button>
            </form>
        </>
    );
}

export default SetupScreen;

SetupScreen.defaultProps = {
    defaultValues: undefined,
};
