import React, { useEffect, useState } from 'react';
import PageHeader from 'components/layout/PageHeader';
import { Button, ButtonGroup, ListItem } from '@sumup/circuit-ui';
import ErrorBanner from 'components/ErrorBanner';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import usePagination from 'hooks/usePagination';
import { refreshAuth } from 'helpers/userInfoHelper';
import Loading from 'components/layout/Loading';
import { useNavigate } from 'react-router-dom';
import {
    MerchantListQuery,
    useMerchantListGetMerchantQuery,
    useMerchantListGetMerchantsQuery,
    useMerchantListSetActiveMerchantMutation,
} from '../../utils/generated';

function MerchantList() {
    const navigate = useNavigate();
    const { nextPageToken, goToNextpage, goToPreviousPage } = usePagination();
    const [listQuery, setListQuery] = useState<MerchantListQuery>({});

    const {
        data: merchant,
        error: getMerchantError,
        isLoading: getMerchantIsLoading,
    } = useMerchantListGetMerchantQuery({});
    const {
        data: merchants,
        error: getMerchantsError,
        isLoading: getMerchantsIsLoading,
    } = useMerchantListGetMerchantsQuery({ listQuery });
    const {
        mutateAsync,
        error: setActiveMerchantError,
        isLoading: setActiveMerchantIsLoading,
    } = useMerchantListSetActiveMerchantMutation();

    const didSelectMerchant = async (merchantId: string) => {
        try {
            await mutateAsync({ merchantId });
            await refreshAuth();
            navigate('/dashboard');
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setListQuery({ ...listQuery, nextToken: nextPageToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPageToken]);

    if (getMerchantError) return <ErrorBanner message={getMerchantError.toString()} />;
    if (getMerchantsError) return <ErrorBanner message={getMerchantsError.toString()} />;
    if (setActiveMerchantError) return <ErrorBanner message={setActiveMerchantError.toString()} />;

    return (
        <ContentPane>
            <Content>
                {getMerchantIsLoading || getMerchantsIsLoading || setActiveMerchantIsLoading ? (
                    <Loading />
                ) : (
                    <>
                        <PageHeader title="Select Merchant" />
                        {merchant?.getMerchant?.businessName} ({merchant?.getMerchant?.id})
                        {merchants?.getMerchants?.items?.map((x) => (
                            <ListItem
                                key={x.id}
                                variant="navigation"
                                style={{ marginBottom: '10px' }}
                                onClick={() => didSelectMerchant(x.id)}
                                label={x.businessName}
                                suffix={x.id}
                            />
                        ))}
                        <ButtonGroup align="right">
                            {nextPageToken !== undefined ? (
                                <Button onClick={() => goToPreviousPage()}> Previous </Button>
                            ) : null}
                            {merchants?.getMerchants?.nextToken ? (
                                <Button onClick={() => goToNextpage(merchants?.getMerchants?.nextToken)}>Next</Button>
                            ) : null}
                        </ButtonGroup>
                    </>
                )}
            </Content>
        </ContentPane>
    );
}

export default MerchantList;
