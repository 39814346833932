import { Auth } from 'aws-amplify';
import { Chance } from 'chance';

export class DeviceHelper {
    deviceId = localStorage.getItem('device-id');

    app = 'MERCHANT';

    chance = new Chance();

    getDeviceInfo() {
        if (!this.deviceId) {
            this.deviceId = this.chance.guid({ version: 4 });
            localStorage.setItem('device-id', this.deviceId);
        }
        const deviceInfo = {
            'device-id': this.deviceId,
            app: this.app,
        };
        return deviceInfo;
    }
}
export function oapFetcher<TData, TVariables>(query: string, variables?: TVariables) {
    const deviceHelper = new DeviceHelper();

    return async (): Promise<TData> => {
        const authKey = await Auth.currentSession()
            .then((resp) => resp.getIdToken().getJwtToken())
            // This catch will return 'not_a_token' which is needed for the unauthorised routes.
            // As the router will remove the other routes when not suthorised this should be OK.
            .catch(() => 'not_a_token');
        const res = await fetch(process.env.REACT_APP_API_URL as string, {
            method: 'POST',
            body: JSON.stringify({ query, variables }),
            headers: {
                ...deviceHelper.getDeviceInfo(),
                Authorization: authKey,
            },
        });

        const json = await res.json();

        if (json.errors) {
            const { errors } = json;
            throw errors;
        }

        return json.data;
    };
}

export default oapFetcher;
