import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Button, SideNavigation, SidePanelProvider, TopNavigation } from '@sumup/circuit-ui';

import styled from '@emotion/styled';
import { Outlet, useNavigate } from 'react-router-dom';
import { light } from '@sumup/design-tokens';
import useNavLinks from 'router/useNavLinks';
import { SumUpLogo } from '@sumup/icons';
import FormError from 'components/FormError/FormError';
import { useUserInfo } from 'contexts/UserInfoContext';
import LogoContainer from './LogoContainer';
import MobilePageHeader from './MobilePageHeader';
import SimpleGrid from './SimpleGrid';

export interface AppLayoutProps {
    showNavigation?: boolean;
    showNotifications?: boolean;
    showSideNavigation?: boolean;
}

const mainBaseStyles = () => css`
    display: flex;
    flex: 1;
    max-width: 100%;
    height: calc(100vh - 57px);
    overflow: hidden;
`;

const contentBaseStyles = () => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: stretch;
    overflow: auto;
    ${light.mq.untilKilo} {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    ${light.mq.tera} {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;

const Main = styled.main(mainBaseStyles);
const Content = styled.div(contentBaseStyles);

export function Layout({
    showNavigation = true,
    showNotifications = false,
    showSideNavigation = true,
}: AppLayoutProps): JSX.Element {
    const { userInfo, hasCompletedSetup, loadingError: error, isUpdating: isLoading } = useUserInfo();
    const { activePrimaryLink, profileActions, utilityLinks, primaryLinks } = useNavLinks({ hasCompletedSetup });
    const [sideNavigationOpen, setSideNavigationOpen] = useState(false);

    const navigate = useNavigate();

    const renderContent = () => (
        <SidePanelProvider withTopNavigation={showNavigation}>
            <Main>
                {showSideNavigation && (
                    <SideNavigation
                        isLoading={isLoading}
                        isOpen={sideNavigationOpen}
                        onClose={() => setSideNavigationOpen(false)}
                        closeButtonLabel="Close"
                        primaryNavigationLabel="Order and Pay"
                        secondaryNavigationLabel="Order and pay options"
                        primaryLinks={primaryLinks}
                    />
                )}
                <Content>
                    {showNotifications && <div style={{ minWidth: '100%' }}>notifications</div>}
                    <FormError errors={error} />
                    <Outlet />
                </Content>
            </Main>
        </SidePanelProvider>
    );

    if (!showNavigation) {
        return renderContent();
    }

    return (
        <>
            <TopNavigation
                isLoading={isLoading}
                logo={
                    <SimpleGrid style={{ height: '100%', overflow: 'hidden' }} gap="2px">
                        <LogoContainer theme={light}>
                            <SumUpLogo />
                            <MobilePageHeader size="one" as="h2" variant="highlight" noMargin>
                                {activePrimaryLink?.label}
                            </MobilePageHeader>
                        </LogoContainer>
                        {!isLoading && !hasCompletedSetup && (
                            <Button
                                style={{ padding: '0px' }}
                                size="kilo"
                                variant="secondary"
                                onClick={() => navigate('/activate-your-account')}
                            >
                                Activate
                            </Button>
                        )}
                    </SimpleGrid>
                }
                user={userInfo ? { name: userInfo.name, id: userInfo.username } : { name: '', id: '' }}
                profileMenu={{
                    label: 'Test',
                    actions: profileActions,
                }}
                links={utilityLinks}
                hamburger={{
                    onClick: () => setSideNavigationOpen(!sideNavigationOpen),
                    isActive: sideNavigationOpen,
                    activeLabel: 'Close menu',
                    inactiveLabel: 'Open menu',
                }}
            />
            {renderContent()}
        </>
    );
}
