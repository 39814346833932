import React from 'react';
import { QrCode, useGetQrCodesQuery } from 'utils/generated';
import { Headline, InlineElements, useModal } from '@sumup/circuit-ui';
import { Outlet, useParams } from 'react-router-dom';
import ListView from 'components/layout/ListView';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import ContentPane from 'components/layout/ContentPane';
import { useQueryClient } from 'react-query';
import { Edit } from '@sumup/icons';
import QrEditModal from './components/QrEditModal';

function QrList() {
    const { locationId } = useParams();
    const { data, error, isLoading } = useGetQrCodesQuery({ locationId: locationId! });

    const { setModal, removeModal } = useModal();

    const queryClient = useQueryClient();

    const openQrModal = (qrCodeId: string, title: string) => {
        if (locationId)
            setModal({
                children: (
                    <QrEditModal
                        title={title}
                        closeCallback={() => {
                            queryClient.invalidateQueries(['GetQrCodes', { locationId }]);
                            removeModal();
                        }}
                        locationId={locationId}
                        qrCodeId={qrCodeId}
                    />
                ),
                variant: 'immersive',
                closeButtonLabel: 'Close modal',
                id: qrCodeId,
                preventClose: true,
            });
    };

    const details = (qrCode: QrCode | undefined) => {
        return (
            <InlineElements ratios={[0, 2, 2]} style={{ alignItems: 'center', fontWeight: '900', fontStyle: 'bold' }}>
                <Headline size="two" as="h4" noMargin style={{ marginInline: '4px' }}>
                    {qrCode?.id}
                </Headline>
            </InlineElements>
        );
    };

    const getDetails = (x: QrCode): ItemProps => ({
        key: x.id,
        variant: 'action',
        label: x.tableName,
        details: details(x),
        selected: undefined,
        suffix: <Edit onClick={() => openQrModal(x.id, x.tableName)} />,
    });

    return (
        <ContentPane>
            <ListView<QrCode>
                detailsOpen={false}
                heading={{
                    title: 'QR codes',
                    showBack: true,
                }}
                getDetails={getDetails}
                isListScreen
                listItems={
                    data?.getLocation?.qrCodes && data?.getLocation?.qrCodes.length > 0
                        ? data?.getLocation?.qrCodes
                        : undefined
                }
                errors={error}
                isLoading={isLoading}
                noDataMessage="There are no QR codes assigned to this location."
                noDataShowBorder
            />
            <Outlet />
        </ContentPane>
    );
}

export default QrList;
