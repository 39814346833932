import { Body, Button, ButtonGroup, Input } from '@sumup/circuit-ui';
import Form from 'components/Form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PaymentConfiguration, PutStatementDescriptorMutation } from 'utils/generated';

interface Props {
    defaultValues?: Pick<PaymentConfiguration, 'statementDescriptor'>;
    onSubmit: (values: Pick<PaymentConfiguration, 'statementDescriptor'>) => Promise<PutStatementDescriptorMutation>;
    paymentsEnabled?: boolean;
}

function PayoutSettingsEdit({ defaultValues, onSubmit, paymentsEnabled }: Props) {
    const navigate = useNavigate();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<Pick<PaymentConfiguration, 'statementDescriptor'>>({ defaultValues });

    const beforeSubmit = (x: Pick<PaymentConfiguration, 'statementDescriptor'>) => {
        onSubmit(x).finally(() => reset(x));
    };

    return (
        <Form onSubmit={handleSubmit(beforeSubmit)}>
            <Input
                validationHint={errors?.statementDescriptor && errors?.statementDescriptor?.message}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('statementDescriptor')}
                label="Description of your business"
                showValid
            />
            <Body size="two" variant="subtle">
                Please use between 5 and 20 characters.
                <br />
                Ensure your descriptor clearly identifies your business to your customers in their bank statements.
                <br />
                The descriptor might be shortened due to acquirer or bank requirements.
                <br />
                Your new descriptor will be applied only to transactions made after the customisation.
            </Body>
            <ButtonGroup align="left">
                <Button
                    type="submit"
                    variant="primary"
                    isLoading={isSubmitting}
                    loadingLabel="Processing..."
                    disabled={!isDirty && !paymentsEnabled}
                >
                    Save
                </Button>
                <Button onClick={() => navigate('/user-settings/payout-settings/view')}>Cancel</Button>
            </ButtonGroup>
        </Form>
    );
}

export default PayoutSettingsEdit;
