import React from 'react';
import { Headline, ListItem, NotificationBanner, Image, useModal } from '@sumup/circuit-ui';
import ContentPane from 'components/layout/ContentPane';
import Content from 'components/layout/Content';
import ImageCard from 'components/image-card/ImageCard';
import SimpleGrid from 'components/layout/SimpleGrid';
import { useGetMerchantStatusQuery } from 'utils/generated';
import { useNavigate } from 'react-router-dom';
import teaserDetails from './components/Teasers';
import FormError from '../../components/FormError/FormError';

function Dashboard() {
    const { setModal } = useModal();
    const { data, isLoading, error } = useGetMerchantStatusQuery();

    const teasers = teaserDetails(setModal).map((details) => <ImageCard {...details} />);

    const navigate = useNavigate();

    const hasCompletedSetup = data?.getMerchant?.paymentConfiguration?.enabled;

    return (
        <ContentPane>
            <Content>
                <FormError errors={error} />
                {!isLoading && !hasCompletedSetup && (
                    <NotificationBanner
                        style={{ marginBottom: '20px', paddingRight: '24px' }}
                        headline="Activate your account"
                        body="Tell us a little more about your business to activate your account."
                        action={{
                            onClick: () => navigate('/activate-your-account'),
                            children: 'Start',
                            variant: 'primary',
                        }}
                        image={{
                            src: '/ActivateAccount.svg',
                            alt: 'Activate account',
                            width: 144,
                        }}
                    />
                )}
                <Headline size="three" as="h1">
                    Learn more about Magic Pay
                </Headline>

                <SimpleGrid>{teasers && teasers}</SimpleGrid>
                <Headline size="three" as="h1">
                    Quick links
                </Headline>
                <SimpleGrid>
                    <ListItem
                        label="Order QR codes"
                        variant="action"
                        onClick={() => navigate('/order-qr-code')}
                        prefix={<Image src="./Plus.svg" alt="PlusSign" />}
                    />
                    <ListItem
                        label="Get support"
                        variant="navigation"
                        href="https://support.thegoodtill.com/"
                        prefix={<Image style={{ minHeight: '48px' }} src="./SupportQuestionMark.svg" alt="Support" />}
                    />
                </SimpleGrid>
            </Content>
        </ContentPane>
    );
}

export default Dashboard;
