import React from 'react';
import Select, { MultiValue } from 'react-select';
import { Label } from '@sumup/circuit-ui';
import { Alert } from '@sumup/icons';
import { colourStyles } from 'styles/react-select';
import { light } from '@sumup/design-tokens';
import styled from '@emotion/styled';
import { Controller, ControllerProps } from 'react-hook-form';

const SelectWrapper = styled.div();

type Props<T, F> = Omit<ControllerProps<F>, 'render'> & {
    options?: T[];
    getOptionLabel?: (x: T) => string;
    getOptionValue?: (x: T) => string;
    updateSearch?: (searchString: string) => void;
    placeholder?: string;
    label: string;
    id?: string;
    loading?: boolean;
    isClearable?: boolean;
};

function MultiSelect<T, F>({
    name,
    control,
    options,
    getOptionLabel,
    getOptionValue,
    updateSearch,
    placeholder,
    label,
    id,
    loading,
    isClearable,
}: Props<T, F>): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: { value: true, message: `${label.replace('*', '').trim()} is required` },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <SelectWrapper>
                    {label && id && (
                        <Label style={{ width: '100%', marginBottom: '4px' }} htmlFor={id} about={`Label for ${label}`}>
                            {label}
                        </Label>
                    )}
                    <Select
                        styles={colourStyles<T>(invalid)}
                        id={id}
                        isClearable={isClearable}
                        isLoading={loading}
                        hideSelectedOptions
                        onChange={onChange}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        options={options}
                        isSearchable
                        onInputChange={updateSearch}
                        placeholder={placeholder}
                        value={value as MultiValue<T> | undefined}
                        defaultValue={value as MultiValue<T>}
                        isMulti
                    />
                    {invalid && id && error && (
                        <Label
                            style={{ color: light.colors.danger, width: '100%', marginTop: '1px' }}
                            htmlFor={id}
                            about={`Error for ${label}`}
                        >
                            <span>
                                <Alert
                                    style={{
                                        display: 'inline-block',
                                        width: `${light.iconSizes.kilo}`,
                                        height: `${light.iconSizes.kilo}`,
                                        verticalAlign: 'text-top',
                                        marginRight: `${light.spacings.bit}`,
                                        color: `${light.colors.danger}`,
                                    }}
                                />
                                {error.message}
                            </span>
                        </Label>
                    )}
                </SelectWrapper>
            )}
        />
    );
}

MultiSelect.defaultProps = {
    id: undefined,
    options: undefined,
    placeholder: 'Select',
    updateSearch: undefined,
    loading: false,
    isClearable: false,
};

export default MultiSelect;
