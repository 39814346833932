import { useCallback, useEffect, useReducer, useState } from 'react';

enum ActionType {
    NEXT_PAGE,
    PREVIOUS_PAGE,
}

type Actions = { type: ActionType.NEXT_PAGE; token: string } | { type: ActionType.PREVIOUS_PAGE };

const reducer = (state: string[], action: Actions) => {
    switch (action.type) {
        case ActionType.NEXT_PAGE:
            return [...state, action.token];
        case ActionType.PREVIOUS_PAGE:
            return state.filter((_, i) => i !== state.length - 1);
        default:
            return state;
    }
};

function usePagination() {
    const [nextPageToken, setNextPageToken] = useState<string>();
    const [pageTokens, dispatch] = useReducer(reducer, [] as string[]);

    const goToNextpage = useCallback((token: string | null | undefined) => {
        if (token) dispatch({ type: ActionType.NEXT_PAGE, token });
    }, []);

    const goToPreviousPage = useCallback(() => {
        dispatch({ type: ActionType.PREVIOUS_PAGE });
    }, []);

    useEffect(() => {
        const lastIndex = pageTokens.length - 1 ?? 0;
        setNextPageToken(pageTokens[lastIndex]);
    }, [pageTokens]);

    return { nextPageToken, goToNextpage, goToPreviousPage };
}

export default usePagination;
