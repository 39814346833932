import { isNonNullObject } from 'helpers/dataHelper';
import {
    OapError,
    PosSystem,
    Location as OapLocation,
    GoodtillPosConfig,
    PosConnection,
    BankAccountInput,
    ValidationErrorInfo,
} from 'utils/generated';

const hasRequiredKeys = <T>(input: unknown, keysToCheck: (keyof T)[]) => {
    if (isNonNullObject(input)) {
        const keysFromInput = Object.keys(input);
        const hasAllKeys = keysToCheck.every((key) => keysFromInput.includes(String(key)));
        return hasAllKeys;
    }
    return false;
};

export const isOfType = <T>(input: unknown, keysToCheck: (keyof T)[]): input is T => {
    return hasRequiredKeys(input, keysToCheck);
};

export const isPosSystem = (posSystemParam: string | null | undefined): posSystemParam is keyof typeof PosSystem =>
    !!posSystemParam && Object.keys(PosSystem).includes(posSystemParam);

export const isErrorWithMessage = (err: unknown): err is Error => {
    return hasRequiredKeys(err, ['message']);
};

export const isStringMessage = (input: unknown): input is string => {
    return typeof input === 'string';
};

export const isOapError = (error: unknown): error is OapError[] =>
    Array.isArray(error) && hasRequiredKeys(error[0], ['errorType']);

export const isOapLocation = (input: unknown): input is OapLocation => {
    return hasRequiredKeys(input, ['name', 'qrCode', 'merchant']);
};

export const isValidationErrorInfo = (input: unknown): input is ValidationErrorInfo => {
    return hasRequiredKeys<ValidationErrorInfo>(input, ['field', 'message', 'value']);
};

type PosConnectionGoodTill = Omit<PosConnection, 'config'> & {
    config: GoodtillPosConfig;
};

export const isPosConnection = (input: unknown): input is PosConnection => {
    return hasRequiredKeys(input, ['config', 'id']);
};

export const isGoodTillConfig = (input: unknown): input is PosConnectionGoodTill => {
    return isPosConnection(input) && hasRequiredKeys(input.config, ['outletId']);
};

export const isPayoutBankAccountVariables = (input: unknown): input is BankAccountInput => {
    return hasRequiredKeys<BankAccountInput>(input, ['accountNumber', 'name', 'routingNumber']);
};
