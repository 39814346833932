import React, { useEffect, useState } from 'react';
import { Order, Payment, PaymentListQuery, SortOrder, useGetPaymentsQuery, Location, Source } from 'utils/generated';
import { Button, ButtonGroup, InlineElements, Headline } from '@sumup/circuit-ui';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'components/ui/CurrencyFormat';
import { useIntl } from 'react-intl';
import { Checkmark, Refunded } from '@sumup/icons';
import usePagination from 'hooks/usePagination';
import SplitPane from 'components/layout/SplitPane';
import { light } from '@sumup/design-tokens';
import ListView from 'components/layout/ListView';
import { groupTimeLine } from 'helpers/listHelper';
import { ItemProps } from '@sumup/circuit-ui/dist/es/components/ListItemGroup/ListItemGroup';
import styled from '@emotion/styled';
import DateTimeFilter from 'components/ui/components/DateTimeFilter';
import FilterBar from 'components/ui/components/FilterBar';

const STATUS = {
    SUCCESS: { icon: Checkmark, color: 'green', status: 'Successful' },
    REFUND: { icon: Refunded, color: '#F5C625', status: 'Refunded' },
};

type PaymentListItem = Omit<Payment, 'refunds' | 'order' | 'location'> & {
    order?: Pick<Order, 'posTableName'> | undefined | null;
    location?: Pick<Location, 'name'> | undefined | null;
};

const Details = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

function PaymentList() {
    const { nextPageToken, goToNextpage, goToPreviousPage } = usePagination();
    const [listQuery, setListQuery] = useState<PaymentListQuery>({ sortOrder: SortOrder.Descending });
    const { data, error, isLoading } = useGetPaymentsQuery({ listQuery });
    const { formatTime } = useIntl();
    const navigate = useNavigate();
    const { paymentId } = useParams();

    const setStartEndFilter = ({ createdAtStart, createdAtEnd }: Partial<PaymentListQuery>) => {
        if (createdAtStart && createdAtEnd) {
            setListQuery({
                ...listQuery,
                sortOrder: SortOrder.Ascending,
                createdAtEnd,
                createdAtStart,
                nextToken: undefined,
            });
        } else {
            setListQuery({
                sortOrder: SortOrder.Descending,
                createdAtEnd: undefined,
                createdAtStart: undefined,
            });
        }
    };

    const details = (payment: PaymentListItem | undefined) => {
        const type = payment?.amount && payment?.amount > 0 ? STATUS.SUCCESS : STATUS.REFUND;
        const StatusIcon = type.icon;
        return (
            <Details>
                <InlineElements inlineMobile={false} ratios={[0, 1, 2]} style={{ alignItems: 'center' }}>
                    <StatusIcon color={type.color} style={{ marginInline: '2px', maxHeight: '14px' }} />
                    <Headline size="four" as="h4" noMargin style={{ marginInline: '4px' }}>
                        {type.status}
                    </Headline>
                    <>
                        {payment?.createdAt &&
                            Date.parse(payment?.createdAt) !== Date.now() &&
                            formatTime(payment.createdAt, {
                                dateStyle: 'medium',
                            })}
                        &nbsp;
                        {payment?.createdAt &&
                            formatTime(payment.createdAt, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        {payment?.source === Source.Kiosk && ' - Kiosk'}
                    </>
                </InlineElements>
            </Details>
        );
    };

    useEffect(() => {
        setListQuery({ ...listQuery, nextToken: nextPageToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPageToken]);

    const timeline = groupTimeLine<PaymentListItem>('createdAt', data?.getMerchantPayments?.items);

    const filters = (
        <FilterBar>
            <DateTimeFilter submit={setStartEndFilter} />
        </FilterBar>
    );

    const getDetails = (x: PaymentListItem): ItemProps => ({
        key: x.id,
        variant: 'navigation',
        onClick: () => navigate(x.id, { state: x }),
        label: `${x.consumerName ?? 'Consumer'}`,
        details: details(x),
        selected: x.id === paymentId,
        suffixLabel: <CurrencyFormat amount={x.amount} currency={x.currency} />,
        suffixDetails: x?.order?.posTableName?.toUpperCase(),
    });

    return (
        <SplitPane isSplit={!paymentId} theme={light}>
            <ListView<PaymentListItem>
                detailsOpen={!!paymentId}
                heading={{
                    title: 'Payments',
                }}
                getDetails={getDetails}
                isListScreen
                timelineListItems={timeline}
                errors={error}
                isLoading={isLoading}
                noDataMessage="We dont have any payments to show at the moment. When payments are made they will show here."
                noDataShowBorder
                filters={filters}
                bottomListControls={
                    <ButtonGroup align="right">
                        {nextPageToken !== undefined ? (
                            <Button onClick={() => goToPreviousPage()}> Previous </Button>
                        ) : null}
                        {data?.getMerchantPayments?.nextToken ? (
                            <Button onClick={() => goToNextpage(data?.getMerchantPayments.nextToken)}>Next</Button>
                        ) : null}
                    </ButtonGroup>
                }
            />

            <Outlet />
        </SplitPane>
    );
}

export default PaymentList;
