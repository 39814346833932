import { useCallback, useState } from 'react';
import { OapError, ValidationErrorInfo } from 'utils/generated';
import { isOapError, isStringMessage, isValidationErrorInfo } from 'typings/typePredicates';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotificationToast } from '@sumup/circuit-ui';

function useErrorHandler() {
    const [validationErrors, setValidationErrors] = useState<Record<string, string>[]>([]);

    const navigate = useNavigate();
    const location = useLocation();
    const { setToast } = useNotificationToast();

    const addError = useCallback(
        (newError: Record<string, string>) => {
            if (validationErrors) setValidationErrors((prevState) => [...prevState, newError]);
        },
        [validationErrors]
    );

    const clearValidationErrors = useCallback(() => {
        if (validationErrors) setValidationErrors([]);
    }, [validationErrors]);

    const checkValidParam = (errors: OapError[], message: string) => {
        if (Array.isArray(errors)) {
            if (errors.every((error) => error?.errorType === 'NOT_FOUND')) {
                setToast({ body: message, variant: 'alert' });
                const pathMinusLastSegment = location.pathname.split('/').slice(0, -1);
                navigate(pathMinusLastSegment.join('/'));
            }
        }
    };

    // Return only the error info where
    const filteredErrorInfo = (error: OapError): ValidationErrorInfo[] =>
        error.errorInfo?.filter((currentErrorInfo) => isValidationErrorInfo(currentErrorInfo)) as ValidationErrorInfo[];

    // Temporary hack to fix text. Have discussed returning a code and using that to determin text.
    const routingNumberMessageToSortCode = (message: string) => message.replace('Routing number', 'Sort code');

    function getValidationErrors(errors: OapError[]) {
        errors.forEach((error: OapError) => {
            filteredErrorInfo(error).forEach((fieldError) => {
                const message = routingNumberMessageToSortCode(
                    isStringMessage(fieldError?.message) ? String(fieldError?.message) : ''
                );
                const constructedField = fieldError?.field?.map((field) => {
                    if (typeof field === 'number') return '';
                    return field as string;
                });
                if (constructedField) {
                    const field = constructedField.join(' ');
                    addError({ field, message });
                }
            });
        });
    }

    function handleMutationError(caughtErrors: unknown) {
        if (isOapError(caughtErrors)) {
            getValidationErrors(caughtErrors.filter((err) => err.errorType === 'VALIDATION'));
            const remainingErrors = caughtErrors.filter((err) => err.errorType !== 'VALIDATION');
            if (remainingErrors && remainingErrors.length > 0) {
                return Promise.reject(remainingErrors);
            }
        }
        return Promise.reject(new Error(`Error: ${(caughtErrors as Error).message}`));
    }

    return {
        getValidationErrors,
        handleMutationError,
        validationErrors,
        clearValidationErrors,
        checkValidParam,
    };
}

export default useErrorHandler;
