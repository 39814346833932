import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import SimpleGrid from 'components/layout/SimpleGrid';

const PinInputLoadingWrapperStyles = () => css`
    width: 100%;
    max-height: 72px;
    height: 72px;
    margin-bottom: 16px;
    display: grid;
    grid-template-rows: 20px 48px;
    gap: 4px;
`;

const PinInputLoadingWrapper = styled('div')(PinInputLoadingWrapperStyles);

function PinInputLoading() {
    return (
        <PinInputLoadingWrapper>
            <Skeleton enableAnimation count={1} borderRadius="16px" height="20px" width="50px" />
            <SimpleGrid columnTemplate="1fr 56px" isSingleColumnMobile={false}>
                <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="100%" />
                <Skeleton enableAnimation count={1} borderRadius="16px" height="48px" width="48px" />
            </SimpleGrid>
        </PinInputLoadingWrapper>
    );
}

export default PinInputLoading;
