import styled from '@emotion/styled';

const EmbeddableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f3f3f3;
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 0;
    right: 0;
    overflow: auto;
    padding-inline: 8px;
    padding-bottom: 1rem;
    overscroll-behavior: contain;
`;

export default EmbeddableContent;
