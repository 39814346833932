import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types';
import 'react-phone-input-2/lib/material.css';
import Select from './Select';

type Props<T> = Omit<ControllerProps, 'render' | 'control'> & {
    label: string;
    options: T[];
    getOptionLabel: (x: T) => string;
    getDefaultValue: (x: any) => T | undefined;
    getOptionValue: (x: T) => string;
    placeholder?: string;
    isClearable?: boolean;
};

function SelectWithController<T>(props: Props<T>) {
    const { name, label, options, getOptionLabel, getOptionValue, getDefaultValue, placeholder, isClearable } = props;

    const { control } = useFormContext();

    return (
        <Controller
            {...props}
            control={control}
            name={name}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Select<T>
                    options={options}
                    id={name}
                    label={label}
                    defaultValue={getDefaultValue(value)}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    onChange={(x, a) => {
                        if (a.action === 'clear') onChange();
                        if (a.action === 'select-option') onChange(getOptionValue(x as T));
                    }}
                    formError={invalid}
                    errorMessage={error?.message}
                    placeholder={placeholder}
                    isClearable={isClearable}
                />
            )}
        />
    );
}

export default SelectWithController;

SelectWithController.defaultProps = {
    placeholder: undefined,
    isClearable: false,
};
